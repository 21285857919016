<template>
  <div class="text-left">
    <div class="d-flex pt-4">
      <div class="align-self-center">
        <v-select
            :items="Object.values(pestTypesEnum)"
            label="añadir nuevo"
            v-model="selectedPesticideType"
        ></v-select>
      </div>
      <v-btn
          color="primary lighten-2"
          text
          :disabled="!selectedPesticideType"
          @click="addNewPestRecord(selectedPesticideType)"
      >
        añadir nuevo
      </v-btn>

    </div>

    <div
        v-for="(pesticideSection, index) in pesticidesSections"
    >
      <p class="subtitle-2 ma-auto pa-0 pb-4">{{ pesticideSection.pesticideType | capitalize }}</p>
      <PesticideInput
          :pesticideFormData="pesticideSection.autocompleteData"
          v-model="pesticideSection.formField"
          @deleteFormField="deletePesticideSection(index)"
      />

    </div>

  </div>
</template>

<script>
import {parseToPesticideFormData} from "@/views/partners/hr_co/utils";
import {
  pestAndMitesPesticidesData
} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/data/pestAndMitesPesticidesData";
import {pesticidesData} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/data/fungicides";
import pestTypesMixin from "@/views/partners/hr_co/components/pestsAndDiseasesInput/pestTypesMixin";
import PesticideInput from "@/views/partners/hr_co/components/pestsAndDiseasesInput/PesticideInput";
import {pestAndMitesPesticides} from "@/views/partners/hr_co/components/pestsAndDiseasesInput/pestAndMitesPesticides";

export default {
  props: ['value'],
  components: {
    PesticideInput
  },
  data() {
    // todo trim the pesticides
    return {
      selectedPesticideType: null,
      pesticideFormData: parseToPesticideFormData(pesticidesData),
      pesticidesAutocompleteData: parseToPesticideFormData(pestAndMitesPesticidesData), // todo remove
      pesticidesSections: []
    }
  },
  methods: {
    pestAutocompleteSelect(value, index) {
      this.selectedPestsSearch = value.pesticides
      this.selectedPest = value
    },
    addNewPestRecord(pesticideType) {
      this.pesticidesSections.push(this.newPestRecord(pesticideType))
    },
    newPestRecord(pesticideType) {
      // autocompleteData:  this.pesticidesAutocompleteData
      // todo check if pest type add correct autocomplete, formField
      const newPestSection = {
        pesticideType,
        formField: {
          pest: {
            question: '',
            answer: undefined
          },
          pesticide: {
            question: '',
            answer: undefined
          },
          pesticideFrequency: {
            question: '',
            answer: undefined
          }
        },
      }

      if (pesticideType === this.pestTypesEnum.insecticides) {
        newPestSection.autocompleteData = this.insecticidesAutocompleteData
      }

      if (pesticideType === this.pestTypesEnum.fungicides) {
        newPestSection.autocompleteData = this.fungicidesAutocompleteData
      }

      if (pesticideType === this.pestTypesEnum.bactericides) {
        newPestSection.autocompleteData = this.bactericidesAutocompleteData
      }

      if (pesticideType === this.pestTypesEnum.weeds) {
        newPestSection.autocompleteData = this.weedsAutocompleteData
      }

      if (pesticideType === this.pestTypesEnum.bioInputs) {
        newPestSection.autocompleteData = this.bioInputsAutocompleteData
      }

      if (pesticideType === this.pestTypesEnum.compostAndAmendments) {
        newPestSection.autocompleteData = this.compostAndAmendmentsAutocompleteData
      }

      if (pesticideType === this.pestTypesEnum.fertilizers) {
        newPestSection.autocompleteData = this.fertilizersAutocompleteData
      }

      return newPestSection
    },
    deletePesticideSection(index) {
      this.pesticidesSections.splice(index, 1)
    },
    validate() {
      for (let i = 0; i < pestAndMitesPesticides.length; i++) {
        this.$refs[`form${i}`][0].validate();
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!to) {
        } else {
          this.pesticidesSections = to;
        }
      }
    },
    pesticidesSections:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
  },
  mixins: [pestTypesMixin]
}

</script>

<style scoped>

</style>
