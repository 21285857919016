<template>
  <PageHeader :title="'New client'" >

    <Stepper :tabs="tabs" :captions="captions" :complete="complete" :disabled="disabled" :error="error" :invalid="invalid" @position="stepChange($event)" ref="stepper">

      <template v-slot:Basics>
        <Basics v-model="customer" @valid="validity['Basics']=$event" ref="basics" />
      </template>

      <template v-slot:Duplicates>
        <Duplicates v-model="customer" @valid="validity['Duplicates']=$event" ref="duplicates" />
      </template>

      <template v-slot:Fingerprints>
        <Fingerprints v-model="customer" @valid="validity['Fingerprints']=$event" ref="fingerprints" />
      </template>

      <template v-slot:Employment>
        <Employment v-model="customer" @valid="validity['Employment']=$event" ref="employment" />
      </template>

      <template v-slot:NextOfKin>
        <NextOfKin v-model="customer" @valid="validity['NextOfKin']=$event" ref="nextOfKin" />
      </template>

      <template v-slot:Documents>
        <Documents v-model="customer" @valid="validity['Documents']=$event" ref="documents" />
      </template>

      <template v-slot:Otps>
        <Otps v-model="customer" @valid="validity['Otps']=$event" ref="otps" />
      </template>

      <template v-slot:Other>
        <Other v-model="customer" @valid="validity['Other']=$event" ref="other" />
      </template>

    </Stepper>


    <div class="d-flex justify-space-between mt-2" style="width: 100%;">
      <div>
        <v-btn color="primary" small dense :disabled="firstStep" @click="$refs.stepper.previousStep();save();" class="mr-1">Save & Previous</v-btn>
        <v-btn color="primary" small dense  @click="save();$refs.stepper.nextStep();">Save & Next</v-btn>
      </div>
      <div>
        <v-btn color="warning" small dense @click="validate" class="mr-1" >
          {{$t('validate')}}
        </v-btn>
        <v-btn :color="(saveLabel=='Save' ? 'primary' : 'error')" small dense @click="save" class="mr-1" :disabled="!saveable" >
          {{saveLabel}}
        </v-btn>
        <v-btn color="success" small dense @click="saveAndDone" :disabled="!valid" >
          Done
        </v-btn>
      </div>
    </div>

  </PageHeader>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';

import PageHeader from "@/components/PageHeader";
import Stepper from "@/components/Stepper";
import Basics from "@/views/contacts/partners/ultimate_tz/Basics";
import Duplicates from "@/views/contacts/partners/ultimate_tz/Duplicates";
import Fingerprints from "@/views/contacts/partners/ultimate_tz/Fingerprints";
import Employment from "@/views/contacts/partners/ultimate_tz/Employment";
import NextOfKin from "@/views/contacts/partners/ultimate_tz/NextOfKin";
import Documents from "@/views/contacts/partners/ultimate_tz/Documents";
import Otps from "@/views/contacts/partners/ultimate_tz/Otps";
import Other from "@/views/contacts/partners/ultimate_tz/Other";

export default {

  components: {
    PageHeader,
    Stepper,
    Basics,
    Duplicates,
    Fingerprints,
    Employment,
    NextOfKin,
    Documents,
    Otps,
    Other,
  },

  data () {
    return {
      customer: { uuid: uuidv4(), demographic: { identity: {} }, economic: { payload: {} }, nextOfKin: {}, regions: [] },
      customerUpdated: true,

      tabs: ['Basics', 'Duplicates', 'Fingerprints', 'Employment', 'NextOfKin', 'Documents', 'Other', 'Otps'],
      captions: { 'NextOfKin': 'Next Of Kin', 'Otps': 'OTPS' },
      validity: { 'Basics': false, 'Duplicates': true, 'Fingerprints': true, 'Employment': false, 'NextOfKin': false, Documents: false, 'Otps': false, 'Other': false }, // have to do this because of reactivity

      firstStep: true,
      lastStep: false,
      saveLabel: 'Save',
    }
  },

  computed: {
    disabled() { return []; },
    complete() { return Object.entries(this.validity).filter(e => e[1]).map(e => e[0]); },
    invalid() { return this.tabs.filter(e => (this.validity[e]===false) ); },
    error() { return (this.validity['Duplicates'] ? [] : ['Duplicates']); },
    valid() { return this.invalid.length==0; },
    saveable() { return (this.customer.msisdn || '').length==12 },
  },

  watch: {
    customer: {
      deep: true,
      handler(to) {
        this.customerUpdated = true;
      }
    },
  },

  methods: {

    stepChange(to) {
      this.firstStep = (to=='first');
      this.lastStep = (to=='last');
      this.$refs.other.invalidateMap();
    },

    validate() {
      this.$refs.basics.validate();
      this.$refs.duplicates.validate();
      this.$refs.employment.validate();
      this.$refs.nextOfKin.validate();
      this.$refs.documents.validate();
      this.$refs.other.validate();
      this.$refs.otps.validate();
    },

    saveAndDone() {
      this.saveLabel = 'Saved';
      this.customer.customer_at = new Date().toISOString();
      this.customerUpdated = true;
      this.saveCustomer();
      this.$router.push({ name: 'UltimateTzLoanRequest', query: { uuid: this.customer.uuid } });
    },

    save() {
      this.saveLabel = 'Saved';
      let _this = this;
      setTimeout(() => { _this.saveLabel = 'Save'; }, 2000);
      this.saveCustomer();
      this.$refs.duplicates.checkServerForDuplicates();
    },

    saveCustomer() {
      if (this.customerUpdated) {
        this.customerUpdated = false;
        this.customer.name = [this.customer.firstName,this.customer.middleName,this.customer.surname].map( e => (e || '').trim() ).filter( e => !!e ).join(' ');
        this.$store.dispatch('upsertCustomer', this.customer);
      }
    },

  },

  mounted() {
    const uuid = this.$route.query.uuid;
    if (uuid) {
      this.customer = this.$store.getters.customer(uuid);
      this.customer.demographic = this.customer.demographic || { identity: {} }
      this.customer.nextOfKin = this.customer.nextOfKin || {}
      this.customer.regions = this.customer.regions || []
    }
  },

}
</script>

<style scoped>

</style>
