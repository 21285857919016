<template>
  <v-text-field
    v-model.number="amount"
    :prefix="prefix"
    :hide-details="hideDetails"
    :label="label"
    :single-line="hideDetails"
    type="number"
    :rules="required ? allRules: []"
    :disabled="disabled"
  ></v-text-field>
</template>

<script>
  export default {
    props: {
      value: Number,
      required: Boolean,
      rules: Array,
      label: String,
      disabled: Boolean,
    },
    data: () => ({
      amount: 0,
    }),
    computed: {
      allRules() { return !!this.rules ? [this.numberRules, ...this.rules] : [this.numberRules] },
      prefix() {
        // let currency = this.$store.getters.currency;
        // const code =  this.$store.getters.settings.partnerCode; 
        // return code == "nile_ug" ? 'UGX': currency;
        return this.$store.getters.currency;
     },
      currencyAmount() { return (Number.parseFloat(this.amount) || 0) },
      hideDetails() { return (this.label ? false : true); },
    },
    watch: {
      value: {
        immediate: true,
        handler(to) { this.amount = (Number.parseFloat(to) || 0); }
      },
      currencyAmount(to) {
        this.$emit('input', this.currencyAmount);
      }
    },
    methods: {
      numberRules(value) {
        let r = [
          v => !this.required || !!v || 'Required',
          v => (/\d/.test(v)) || 'Numbers only',
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },
  };
</script>

