<template>

  <v-container fluid>

    <v-row v-if="filter=='customers'">
      <v-col>
        <SearchCustomer v-model="target" />
      </v-col>
    </v-row>

    <v-row v-else-if="filter=='fields'">
      <v-col>
        <SearchField v-model="target" />
      </v-col>
    </v-row>

    <v-row v-else-if="filter=='instruments'">
      <v-col>
        <SearchInstrument v-model="target" />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="6" md="4" lg="2" v-for="item in menuItems" :key="item.id">
        <MenuItem :icon="item.icon" :caption="item.caption" :target="item.to" v-model="target" />
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import MenuItem from '@/views/login/components/MenuItem'
import SearchCustomer from '@/views/login/components/SearchCustomer'
import SearchField from '@/views/login/components/SearchField'
import SearchInstrument from '@/views/login/components/SearchInstrument'

export default {

  components: { MenuItem, SearchCustomer, SearchField, SearchInstrument },

  data() {
    return {
      target: undefined,
    }
  },

  computed: {
    partnerCode() { return this.$store.getters.settings.partnerCode; },

    filter() { return ( this.target ? this.target.filter : undefined ) ; },

    menuItems () {
      let menus = null;
      if (this.partnerCode === 'ultimate_tz') {
        menus = [
          {icon: "mdi-account",            caption: "Customer",          to: { filter: 'customers', name: "UltimateTzCustomerShow"     } },
          {icon: "mdi-account-plus",       caption: "Add Customer",      to: { name: "CreateContact"     } },
          {icon: "mdi-domain",             caption: 'Add Employer',      to: { name: 'CreateBusiness' }},
          {icon: "mdi-check-all",          caption: 'Approvals',         to: { name: 'ApprovalsIndex' }},
          {icon: "mdi-piggy-bank-outline", caption: 'Cashbox',           to: { name: 'UltimateTzCashbox' }},
          {icon: "mdi-send",               caption: 'CashboxSend',       to: { name: 'UltimateTzCashboxSend' }},

          {icon: "mdi-test-tube",     caption: 'Tests',               to: { name: 'TestUltimate' }},
          {icon: "mdi-test-tube",     caption: 'Transactions',        to: { filter: 'customers', name: 'TestUltimateTransactions' }},

        ]
      } else if (this.partnerCode === 'nile_ug') {
        menus = [
          // {icon: "mdi-account-plus", caption: "Add Customer",      to: { name: "CreateContact"     } },
          // {icon: "mdi-text-account", caption: "Edit Demographics", to: { filter: 'customers', path: '/customers/demographics' }},
          // {icon: "mdi-account-edit", caption: 'Edit Farmer',       to: { filter: 'customers', path: '/customers/edit'          } },
          // {icon: "mdi-ruler-square", caption: 'Map Field',         to: { filter: 'fields',    path: '/mapField'      } },
          {icon: "mdi-account-question-outline", caption: "Onboarding: In Progress", to: { name: "OnboardingInProgress" } },
          {icon: "mdi-cash", caption: "Loan request", to: { filter: 'customers', name: "NileStepperLoanRequest" } },

        ]
      } else if (this.partnerCode === 'hr_co') {
        menus = [
          {icon: "mdi-account-plus", caption: "Sync Assessments",      to: { name: "SyncAssessments"     } },
        ]


      } else if (this.partnerCode === 'nucafe_ug') {
        menus = [
          {icon: "mdi-account-plus-outline", caption: "Farmer Onboarding",       to: { name: "CreateContact"     } },
          {icon: "mdi-moped-outline", caption: "Coffee Delivery",         to: { name: "CoffeeDelivery"     } },
          {icon: "mdi-account-outline", caption: "Finish Onboarding",       to: { filter: 'customers', name: "NuCafeUgRegisterContact" } },
          {icon: "mdi-account-question-outline", caption: "Onboarding: In Progress", to: { name: "OnboardingInProgress" } },
        ]
      } else {
        menus = [
          {icon: "mdi-account-plus", caption: "Add Customer",      to: { name: "CreateContact"     } },
          {icon: "mdi-text-account", caption: "Edit Demographics", to: { filter: 'customers', path: '/customers/demographics' }},
          {icon: "mdi-account-edit", caption: 'Edit Farmer',       to: { filter: 'customers', path: '/customers/edit'          } },
          {icon: "mdi-ruler-square", caption: 'Map Field',         to: { filter: 'fields',    path: '/mapField'      } },
        ]
      }
      return menus;
    },

  },

  watch: {
    target: {
      deep: true,
      handler(to) {
        this.redirectTo(to);
      },
    },
    '$route' (to, from) {
      if (to && to.query && to.query.f==='h') {
        this.target = undefined;
        this.$router.replace({ name: "Home", query: { f: undefined} })
      }
    }
  },

  methods: {
    redirectTo(to) {
      if (to) {
        if (to.filter) {
          this.$router.replace({ name: "Home", query: { f: 'f'} })
        } else {
          this.$router.push(to);
        }
      }
    }
  },

};
</script>
