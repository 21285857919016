<template>
  <PrintLayout
    :title="$t('hr.cultivationContract.title')"
    @back="toCustomer"
  >
    <v-main class="text-left">

      <FarmerAgentDisplay :customer="customer" />

      <v-simple-table class="text-left table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                {{$t('question')}}
              </th>
              <th class="text-left">
                {{$t('answer')}}
              </th>
            </tr>
          </thead>
          <tbody>
            <template  v-if="!!cultivationContractAssessment.formFields.demographic">
              <tr v-for="(value, propertyName, index) in cultivationContractAssessment.formFields.demographic">
                <template v-if="!!value.question">
                  <td>
                    {{ value.question }}
                  </td>


                  <td>
                    {{ value.answer}}
                  </td>



                </template>
              </tr>
            </template>

            <tr v-for="(value, propertyName, index) in cultivationContractAssessment.formFields">
              <template v-if="!!value && !!value.question">
                <td>
                  {{ value.question }}
                </td>
                <td>
                  <template v-if="value.questionType === assessmentQuestionType.LandSizeInput">
                    <LandSize :land-size="value.answer" />
                  </template>
                  <template v-else-if="value.questionType === assessmentQuestionType.MultiSelect">
                    {{value.answer.join(', ')}}
                  </template>
                  <template v-else-if="value.questionType === assessmentQuestionType.DepotInput">
                    {{value.answer.name}}
                  </template>
                  <template v-else-if="value.questionType === assessmentQuestionType.RegionPicker">
                    {{value.answer.join(', ')}}
                  </template>
                  <template v-else-if="[
                    'contractDate',
                    'targetDateSeedsPlanted',
                    'calculatedTargetDateSeedlingsPlanted',
                    'calculatedTargetDateHarvest',
                    'contractEndDate'
                    ].includes(propertyName)">
                    {{value.answer | time("dd/MM/yyyy") }}
                  </template>

                  <template v-else>
                    {{value.answer}}
                  </template>
                </td>
              </template>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-main>
  </PrintLayout>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {assessmentQuestionType} from "@/views/partners/hr_co/constant";
import LandSize from "@/components/LandSize";
import PrintLayout from "@/components/PrintLayout";
import FarmerAgentDisplay from "@/components/FarmerAgentDisplay";

export default {
  components: {LandSize, PageHeader, PrintLayout, FarmerAgentDisplay},
  data() {
    return {
      assessmentQuestionType: assessmentQuestionType
    }
  },
  computed: {
    customerUuid() { return this.$route.query.customerUuid; },
    customer() {
      return this.$store.getters.customer(this.customerUuid);
    },
    cultivationContractAssessmentUuid() {
      return this.$route.query.assessmentUuid;
    },
    cultivationContractAssessment() {
      return this.$store.getters.assessments.find(assessment =>
          assessment.assessmentUuid === this.cultivationContractAssessmentUuid &&
          assessment.customerUuid === this.customerUuid
      )
    },

  },
  methods: {
      toCustomer() {
        this.$router.push({ name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.cultivationContractAssessment.fieldUuid }});
      },
  }
}
</script>

<style scoped>

</style>
