import { render, staticRenderFns } from "./TechnicalVisitExtraObservationValidations.vue?vue&type=template&id=2f5592ba&scoped=true&"
import script from "./TechnicalVisitExtraObservationValidations.vue?vue&type=script&lang=js&"
export * from "./TechnicalVisitExtraObservationValidations.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f5592ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCard,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle,VSubheader})
