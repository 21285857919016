<template>
  <v-form
    ref="form"
    v-model="valid"
    class="green lighten-5"
    v-bind:style="{ width: '100%' }"
  >
    <v-card-title>COSECHA</v-card-title>
    <v-divider></v-divider>
    <v-container fluid>

      <v-row >
        <!--2-->
        <v-col cols="12" md="3">
          <DateInput
            v-model="formFields.register.dateOfFlowering.answer"
            :label="formFields.register.dateOfFlowering.question"
            required
            :rules="[sharedValidations.requiredValidation]"
          />
        </v-col>

        <!--3-->
        <v-col cols="12" md="3">
          <DateInput
            v-model="formFields.register.estimatedDateOfHarvestStart.answer"
            :label="formFields.register.estimatedDateOfHarvestStart.question"
            :rules="[sharedValidations.requiredValidation]"
          />
        </v-col>

        <v-col cols="12" md="3">
          <v-text-field
            v-model="formFields.register.estimatedFarmLaborForPicking.answer"
            :label="formFields.register.estimatedFarmLaborForPicking.question"
            type="number"
            :rules="[sharedValidations.requiredValidation]"
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="3">
          <DateInput
            v-model="formFields.register.realDateOfHarvestStart.answer"
            :label="formFields.register.realDateOfHarvestStart.question"
            :rules="[sharedValidations.requiredValidation]"
            required
          />
        </v-col>

        <v-col cols="12" md="3">
        <v-radio-group
            :label="formFields.register.phaseComplete.question"
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.register.phaseComplete.answer"
            clearable
            row
        >
          <v-radio label="Si" value="Sí"></v-radio>
          <v-radio label="No" value="no"></v-radio>
        </v-radio-group>

        <v-text-field
            v-if="formFields.register.phaseComplete.answer === 'Sí'"
            label="Razón para finalizar"
            v-model="formFields.register.phaseComplete.extraObservationAnswer"
            :rules="[sharedValidations.requiredValidation]"
        ></v-text-field>

        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DateInput from "@/components/DateInput";
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
export default {
  components: {DateInput},
  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!to) {
        } else {
          this.formFields = to;
        }
      }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
    "isPhaseFormValid": function (newValue) {
      this.$emit('phase-validation', newValue)
    }


  },
  data() {
    return {
      valid: false,
      formFields: {
        register: {
          dateOfFlowering: {
            question: '1 ¿Fecha inicio floraciones?',
            answer: undefined,
          },
          estimatedDateOfHarvestStart: {
            question: '2 ¿Fecha estimada de inicio Cosecha?',
            answer: undefined,
          },
          estimatedFarmLaborForPicking: {
            question: '3 ¿Mano de Obra estimada para Cosecha (Por Hectárea)?',
            answer: undefined,
          },
          realDateOfHarvestStart: {
            question: '4 ¿Fecha real de inicio Cosecha?',
            answer: undefined,
          },
          phaseComplete: {
            question: 'fase completa',
            answer: undefined,
            extraObservationAnswer: undefined
          },
        }
      },
      sharedValidations: new HugoValidations(),
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
  },
  computed: {
    isPhaseFormValid() {
      return this.valid
    }
  }
}
</script>

<style scoped>

</style>
