import {VUE_APP_VERSION} from "@/helpers/constants";


export function parseCustomerUpdatedAt(customers) {
    return customers.reduce((total, customer) => {
        const farmUpdatedAt = !!customer.farm ? customer.farm.updatedAt : null
        const fieldsUpdatedAt = (customer.fields || []).map( field => field.updatedAt)
        const customerUpdatedAt = customer.updatedAt
        const allUpdatedAt = [farmUpdatedAt, ...fieldsUpdatedAt, customerUpdatedAt]
        const filteredUpdatedAt = allUpdatedAt
            .filter( updatedAt => !!updatedAt)
            .sort((a, b) => {
                const aDate = new Date(a)
                const bDate = new Date(b)

                return bDate.getTime() - aDate.getTime()
            })
        total[customer.uuid] = filteredUpdatedAt.length ? filteredUpdatedAt[0]: null;
        return total
    }, {})

}


export function findAllCustomersUpdatedMismatch(serverCustomersUpdated, customersUuidAndUpdatedAt) {
    const customersUpdatedMismatch = []
    Object.keys(serverCustomersUpdated).map(customerUuid => {
        const localCustomerUpdatedAt = new Date(customersUuidAndUpdatedAt[customerUuid])
        const serverCustomerUpdatedAt = new Date(serverCustomersUpdated[customerUuid])
        // const timeDifference = localCustomerUpdatedAt.getTime() - serverCustomerUpdatedAt.getTime()
        if (localCustomerUpdatedAt.getTime() !== serverCustomerUpdatedAt.getTime()) {
            customersUpdatedMismatch.push(customerUuid)
        }
    })
    return customersUpdatedMismatch
}



export function backendPostMutation(apiKey, geolocation, mutationType, data) {

    function backendMutationUrl() {
        if (process.env.NODE_ENV === 'production') {
            return 'https://connect.sasa.solutions/api/app/mutation/';
        } else {
            return 'http://api.sasa.localhost:3000/api/app/mutation/';
        }
    }

    let payload = {
        mutation_type: mutationType,
        mutation: data,
        appVersion: VUE_APP_VERSION
    };
    if (geolocation && geolocation.coords) {
        payload['geolocation'] = {
            latitude:  geolocation.coords.latitude,
            longitude: geolocation.coords.longitude,
        };
    }

    return fetch(backendMutationUrl() + mutationType + '/', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Accept': 'application/json',
            'Authorization': apiKey,
            'Content-Type': 'application/json',
        },
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(payload)
    })


}


