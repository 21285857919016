<template>

  <v-container fluid>

    <ServerSideResult :test="theInstrument" >

      <v-row class="text-center" v-if="theInstrument">

        <v-col cols="12" md="2" sm="3">
          <Balance label="Balance" :balance="parseInt(theInstrument.balance)" :highlight="highlight" @click="balance" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Balance label="Payable" :balance="parseInt(theInstrument.installment)" :highlight="highlight" @click="settle" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Balance label="Settlement" :balance="parseInt(theInstrument.settlement_amount)" :highlight="highlight" @click="settle" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Percentage label="Rate" :value="parseFloat(theInstrument.interest_rate)" :highlight="highlight" />
        </v-col>
        <v-col cols="12" md="2" sm="3">
          <Value >
            {{theInstrument.installment_period}}
            /
            {{theInstrument.installments}}
          </Value>
        </v-col>
        <v-col cols="12" md="2" sm="3" v-if="theInstrument.due_at">
          <Date label="Next Due" :value="theInstrument.due_at" />
        </v-col>

      </v-row>

    </ServerSideResult>
  
  </v-container>

</template>

<script>
import lookups from '@/mixins/lookups';

import Value from "@/views/instruments/components/Value"
import Balance from "@/views/instruments/components/Balance"
import Percentage from "@/views/instruments/components/Percentage"
import Date from "@/views/instruments/components/Date"
import ServerSideResult from "@/components/ServerSideResult";

export default {

  components: { Value, Balance, Percentage, Date, ServerSideResult },

  // note, if you pass an instrumentId, you must also pass a customer.
  props: {
    instrument: Object,
    instrumentId: Number,
    customer: Object,
    highlight: String,
  },

  data() {
    return {
      theInstrument: undefined,
    }
  },

  computed: {
    uuid() { return (this.customer ? this.customer.uuid : this.$route.query.uuid); },
  },

  watch: {
    theInstrument(to) { this.$emit('instrument', to) },
  },

  methods: {
    balance()  { this.$router.push({ name: 'UltimateTzCustomerShow', query: { uuid: this.uuid } }) },
    settle()   { this.$router.push({ name: 'UltimateTzSettlement',   query: { uuid: this.uuid, instrumentId: this.theInstrument.id, instrumentType: this.theInstrument.type } }) },
  },

  mounted() {
    if (this.instrument) {
      this.theInstrument = this.instrument;
    } else {
      this.remoteLookup('instrumentUltimateTzGovernment', {
        uuid: this.uuid,
        id: this.instrumentId,
      }).then( instrument => this.theInstrument = instrument ).catch(e => this.theInstrument = null);
    }
  },

  mixins: [lookups],


}
</script>
