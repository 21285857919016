<template>
  <div class="pa-4">
      <v-divider></v-divider>

    <div class="pa-4">
      <p>{{$t('fieldAgent')}}: {{customer.fieldAgent.name}}</p>
      <p>{{$t('farmer')}}: {{customer.name}}</p>
    </div>
    <v-divider></v-divider>

  </div>
</template>

<script>
export default {
  props: ['customer']
}
</script>

<style scoped>

</style>
