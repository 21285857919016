<template>
  <v-container fluid>

    <v-row>
      <v-col cols="12" v-if="approvals">
        <Approvals :approvals="approvals" />
      </v-col>

      <v-col cols="12" v-else>
        <ServerSideResult :test="approvals">
            No recent approval requests.
        </ServerSideResult>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn width="100%" color="primary" @click="loadApprovals" :disabled="!refreshable">Refresh</v-btn>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
  import ServerSideResult from '@/components/ServerSideResult';
  import Approvals from '@/views/approvals/Approvals';

  export default {

    components: { ServerSideResult, Approvals },

    props: {
      approvals: Array,
    },

    data: () => ({
      refreshable: false,
    }),
    
    methods: {
      loadApprovals() {
        this.refreshable = false;
        this.$emit('refresh')
        let _this = this;
        setTimeout(() => {
          _this.refreshable = true;
        }, 2000);
      }
    },

    mounted() { this.loadApprovals(); },

  }
</script>
