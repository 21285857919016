<template>

  <div>
    <SettlementSalaryAdvance :customer="customer" :instrumentId="instrumentId" v-if="instrumentType=='InstrumentUltimateTzSalaryAdvance'" />
    <SettlementCheckMeBack :customer="customer" :instrumentId="instrumentId" v-if="instrumentType=='InstrumentUltimateTzCheckMeBack'" />
  </div>

</template>

<script>
import SettlementSalaryAdvance from "@/views/partners/ultimate_tz/SettlementSalaryAdvance"
import SettlementCheckMeBack from "@/views/partners/ultimate_tz/SettlementCheckMeBack"

export default {

  components: { SettlementSalaryAdvance, SettlementCheckMeBack },

  computed: {
    uuid() { return this.$route.query.uuid; },
    instrumentId() { return this.$route.query.instrumentId; },
    instrumentType() { return this.$route.query.instrumentType; },
    customer() { return this.$store.getters.customer(this.uuid); },
  },

}
</script>
