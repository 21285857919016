<template>
  
  <v-form ref="topupForm" v-model="valid" >
    <v-container fluid>

      <v-row>
        <v-col cols="12" class="text-h3 text--primary text-left">
          Salary Advance, {{customer.name}}: Topup
        </v-col>
      </v-row>


      <ServerSideResult :test="instrument" v-if="disbursing">
        <v-row>
          <v-col class="text-h5">
            <InstrumentUltimateTzSalaryAdvance :instrument="instrument" highlight="Available Balance" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <CurrencyInput v-model="cash" label="Cash Disbursed" />
          </v-col>
          <v-col cols="6">
            <TextInput v-model="cashReceiptNumber" label="Disbursement Number" required />
          </v-col>
        </v-row>


        <v-row>
          <v-col>
            <v-btn @click="topup" color="primary" :disabled="!mayTopup">Topup Loan</v-btn>
          </v-col>
        </v-row>

      </ServerSideResult>

      <ServerSideResult :test="topupProcess" v-else>
        <v-row>
          <v-col cols="12" class="text-h4 text--primary text-left">
            Thank you. Your topup has been processed.
          </v-col>
        </v-row>
      </ServerSideResult>

    </v-container>
  </v-form>

</template>

<script>
import lookups from '@/mixins/lookups';

import CurrencyInput from "@/components/CurrencyInput";
import TextInput from "@/components/TextInput";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import InstrumentUltimateTzSalaryAdvance from "@/views/instruments/InstrumentUltimateTzSalaryAdvance"


export default {

  components: {  CurrencyInput, TextInput, Currency, ServerSideResult, InstrumentUltimateTzSalaryAdvance },

  props: ['customer', 'instrumentId'],

  data() {
    return {
      valid: false,
      instrument: undefined,
      cash: 0,
      cashReceiptNumber: undefined,
      disbursing: true,
      topupProcess: undefined,
      minPrincipal: 10000,
    }
  },

  computed: {
    topupAmount() { return ((this.instrument.available_balance || 0)/100.0); },
    mayTopup() { return this.valid && (this.cash>=this.minPrincipal) && ( (this.instrument) && (this.cash<=this.topupAmount ) ) },
  },

  watch: {
    instrument(to) {
      if (to && this.cash==0) {
        this.cash = this.topupAmount;
      }
    }
  },

  methods: {
    validate() { this.$refs.topupForm.validate(); },
    topup() {
      this.disbursing = false;
      this.remoteLookup('instrumentUltimateTzSalaryAdvanceTopup', {
        uuid: this.customer.uuid,
        id: this.instrumentId,
        cash: this.cash,
        receiptNumber: this.cashReceiptNumber,
      }).then( e => this.topupProcess = 'done' ).catch(e => this.topupProcess = null);
    },
  },

  mounted() {
    this.remoteLookup('instrumentUltimateTzSalaryAdvance', {
      uuid: this.customer.uuid,
      id: this.instrumentId,
    }).then( instrument => this.instrument = instrument ).catch(e => this.instrument = null);
  },

  mixins: [lookups],

}
</script>
