<template>
    <v-card class="col-4 text-left mb-2" tile>
      <v-subheader>{{$t('extraObservationsValidations')}}</v-subheader>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{$t('Visit Date')}}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon v-if="extraObservationValidations.extraObservationValidationsVisitDate" color="grey lighten-1">mdi-check</v-icon>
          <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{$t('registerFormValid')}}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon v-if="extraObservationValidations.registerFormValid" color="grey lighten-1">mdi-check</v-icon>
          <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{$t('pesticideFormValid')}}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon v-if="extraObservationValidations.pesticideFormValid" color="grey lighten-1">mdi-check</v-icon>
          <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{$t('selectedPhaseFormValid')}}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon v-if="extraObservationValidations.selectedPhaseFormValid" color="grey lighten-1">mdi-check</v-icon>
          <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
        </v-list-item-action>
      </v-list-item>
    </v-card>
</template>

<script>
export default {
  props: ['extraObservationValidations']
}
</script>

<style scoped>

</style>
