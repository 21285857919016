<template>
  <PageHeader
      :title="$t(!assessmentToReview ? 'technicalVisitView' : 'technicalVisitReview')"
      save
      @save="saveForm"
      :valid="isFormValid"
      @validate="validate"
      validate
      @back="goBack"
      back
  >
    <v-main class="pt-0">
      <div class="text-left" v-if="!!assessmentToReview">
        <!-- This is a review of technical visit which was first recorded on {{assessmentToReview.createdAt}}. Please fill the Extra observations sections. -->
        Esta es una revisión de la visita técnica que se registró por primera vez en {{assessmentToReview.createdAt}}. Please fill the Extra observations sections.
      </div>
      <v-card-title class="align-self-start">
        {{$t('register')}}
      </v-card-title>



      <v-form ref="extraObservationForms"
              v-if="!!assessmentToReview">
        <v-col cols="12" md="4"
        >
          <DateInput
              class="w-100"
              persistent-hint
              data-cy="dateInput"
              v-model="extraObservationForms.visitDate.answer"
              :label="extraObservationForms.visitDate.question"
              :min="
                new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
              :max="
                new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
              :rules="[sharedValidations.requiredValidation]"
          />
        </v-col>

      </v-form>


      <v-form ref="form" :disabled="disableForm">
        <v-divider></v-divider>
        <v-container fluid class="p-0">

          <v-row class="green lighten-5">


            <v-col cols="12" md="4">
              <DateInput
                  class="w-100"
                  persistent-hint
                  data-cy="dateInput"
                  v-model="formFields.register.visitDate.answer"
                  :label="formFields.register.visitDate.question"
                  :min="
                new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
                  :max="
                new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
                  :rules="[sharedValidations.requiredValidation]"
              />
            </v-col>

          </v-row>
          <v-row class="green lighten-5">
            <v-col cols="12" sm="4">
              <v-text-field
                  v-model="formFields.register.dayAfterTransplant.answer"
                  :label="formFields.register.dayAfterTransplant.question"
                  type="number"
                  disabled
                  :rules="[sharedValidations.requiredValidation]"
                  required
              ></v-text-field>
              <p>Fecha estimada del trasplante:
                <Time :time="this.cultivationContractAssessment.formFields.calculatedTargetDateSeedlingsPlanted.answer || ''" format="date" />
              </p>

            </v-col>

            <v-col cols="12" md="4">
              <v-radio-group
                  row
                  :label="formFields.register.landProperlyPrepared.question"
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.landProperlyPrepared.answer"
                  clearable
                  :disabled="formFields.register.landProperlyPrepared.disabled"
                  data-cy="landProperlyPrepared"
              >
                <v-radio label="Si" value="Sí"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>
              <v-text-field
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.landProperlyPrepared.extraObservationAnswer"
                  :disabled="!formFields.register.landProperlyPrepared.answer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>
            </v-col>


            <v-col cols="12" md="4">
              <!--                4-->
              <v-radio-group
                  :label="
                formFields.register.irrigationSystemProperlyWorking.question
              "
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="
                formFields.register.irrigationSystemProperlyWorking.answer
              "
                  clearable
                  row
                  :disabled="formFields.register.irrigationSystemProperlyWorking.disabled"
                  data-cy="irrigationSystemProperlyWorking"
              >
                <v-radio label="Si" value="Sí"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>

              <v-text-field
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.irrigationSystemProperlyWorking.extraObservationAnswer"
                  :disabled="!formFields.register.irrigationSystemProperlyWorking.answer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                5-->
              <v-select
                  v-model="formFields.register.canYouAddFertilizer.answer"
                  :items="[
                  'Ventury',
                  'Flujómetro',
                  'Bomba de Inyección',
                  'otros'
              ]"
                  :label="formFields.register.canYouAddFertilizer.question"
                  :rules="[sharedValidations.requiredValidation]"
                  :disabled="formFields.register.canYouAddFertilizer.disabled"
                  persistent-hint
                  hint="seleccione en el menú o escriba uno diferente"
                  single-line
                  data-cy="canYouAddFertilizer"
              ></v-select >
              <v-text-field
                  v-if="formFields.register.canYouAddFertilizer.answer === 'otros'"
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.canYouAddFertilizer.extraObservationAnswer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>

            </v-col>

            <v-col cols="12" md="4">
              <!--                6-->
              <v-radio-group
                  :label="
                formFields.register.phenologyAccordingToAgeOfPlant.question
              "
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="
                formFields.register.phenologyAccordingToAgeOfPlant.answer
              "
                  clearable
                  row
                  data-cy="phenologyAccordingToAgeOfPlant"
                  :disabled="formFields.register.phenologyAccordingToAgeOfPlant.disabled"
              >
                <v-radio label="Sí" value="Sí"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>

              <v-text-field
                  :label="$t('form.label.extraObservation')"
                  v-model="formFields.register.phenologyAccordingToAgeOfPlant.extraObservationAnswer"
                  :disabled="!formFields.register.phenologyAccordingToAgeOfPlant.answer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>

            </v-col>

            <v-col cols="12" md="4">
              <!--                7-->
              <v-text-field
                  persistent-hint
                  :label="formFields.register.waterBalance.question"
                  hint="Especificar MInutos, días y Tandas"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.waterBalance.answer"
                  data-cy="waterBalance"
                  clearable
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row  class="green lighten-5">

            <v-col cols="12" md="4">
              <!--                10-->
              <v-text-field
                  persistent-hint
                  :label="formFields.register.goodCropAdministration.question"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.goodCropAdministration.answer"
                  clearable
                  data-cy="goodCropAdministration"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                11-->
              <v-text-field
                  persistent-hint
                  :label="formFields.register.enoughLabourForPhase.question"
                  hint="De cuantas personas se dispone por cada hectárea"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.enoughLabourForPhase.answer"
                  clearable
                  data-cy="enoughLabourForPhase"
              ></v-text-field>

            </v-col>

            <v-col cols="12" md="4">

              <v-radio-group
                  hint="De cuantas personas se dispone por cada hectárea"
                  persistent-hint
                  :label="
                formFields.register.previousRecommendationMade.question
              "
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="
                formFields.register.previousRecommendationMade.answer
              "
                  row
                  data-cy="previousRecommendationMade"
              >
                <v-radio label="Si" value="Sí"></v-radio>
                <v-radio label="No" value="No"></v-radio>
              </v-radio-group>

              <v-text-field
                  v-if="formFields.register.previousRecommendationMade.answer === 'no'"
                  :label="$t('form.label.extraObservation')"

                  v-model="formFields.register.enoughLabourForPhase.extraObservationAnswer"
                  :rules="[sharedValidations.requiredValidation]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                13-->
              <v-select
                  persistent-hint
                  :items="[
                  '1 pésimo',
                  '2 deficiente',
                  '3 aceptable',
                  '4 bueno',
                  '5 excelente',
                  ]"
                  :label="formFields.register.complyOfTheRecommendation.question"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.complyOfTheRecommendation.answer"
                  clearable
                  data-cy="complyOfTheRecommendation"
              ></v-select>
            </v-col>
          </v-row>

          <v-divider></v-divider>

          <v-row class="green lighten-5">
            <v-col cols="12" md="4">
              <!--                19-->
              <v-text-field
                  :label="formFields.register.otherPendingTasks.question"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.otherPendingTasks.answer"
                  clearable
                  data-cy="otherPendingTasks"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <!--                20-->
              <v-text-field
                  :label="formFields.register.observations.question"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.observations.answer"
                  data-cy="observations"
                  clearable
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row class="green lighten-5">

            <v-divider style="width: 100%"></v-divider>

          </v-row>

            <v-row class="green lighten-5">
              <h3>
                ¿Se requiere Tutorado? :
                Sección de tutoría:
              </h3>
            </v-row>

            <v-row class="green lighten-5">
              <v-radio-group
                  v-if="formFields.register.tutoringRequired"
                  :label="formFields.register.tutoringRequired.question"
                  :rules="[sharedValidations.requiredValidation]"
                  v-model="formFields.register.tutoringRequired.answer"
                  @change="toggleTutoradoForms"
                  clearable
                  row
                  data-cy="tutoringRequired"
                  :disabled="formFields.register.tutoringRequired.disabled"
              >
                <v-radio label="Si" value="Sí"></v-radio>
                <v-radio label="No" value="no"></v-radio>
              </v-radio-group>
            </v-row>
          <template >
<!--            v-if="isTutoringRequired"-->
            <v-row class="green lighten-5" >
              <v-col cols="12" md="4" v-if=" Object.keys(formFields.register.sticksEstablishedPerDesign).length > 0">
                <!--                8-->
                <v-radio-group
                    :label="formFields.register.sticksEstablishedPerDesign.question"
                    :rules="[sharedValidations.requiredValidation]"
                    v-model="formFields.register.sticksEstablishedPerDesign.answer"
                    clearable
                    row
                    :disabled="formFields.register.sticksEstablishedPerDesign.disabled"
                    data-cy="sticksEstablishedPerDesign"
                >
                  <v-radio label="Si" value="Sí"></v-radio>
                  <v-radio label="No" value="No"></v-radio>
                </v-radio-group>

                <v-text-field
                    :label="$t('form.label.extraObservation')"
                    v-model="formFields.register.sticksEstablishedPerDesign.extraObservationAnswer"
                    :disabled="formFields.register.sticksEstablishedPerDesign.disabled"
                    :rules="[sharedValidations.requiredValidation]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4" v-if=" Object.keys(formFields.register.sticksGoodCondition).length > 0">
                <!--                9-->
                <v-radio-group
                    :label="formFields.register.sticksGoodCondition.question"
                    row
                    :rules="[sharedValidations.requiredValidation]"
                    v-model="formFields.register.sticksGoodCondition.answer"
                    clearable
                    :disabled="formFields.register.sticksGoodCondition.disabled"
                    data-cy="sticksGoodCondition"
                >
                  <v-radio label="Si" value="Sí"></v-radio>
                  <v-radio label="No" value="No"></v-radio>
                </v-radio-group>

                <v-text-field
                    :label="$t('form.label.extraObservation')"
                    v-model="formFields.register.sticksGoodCondition.extraObservationAnswer"
                    :disabled="!formFields.register.sticksGoodCondition.answer"
                    :rules="[sharedValidations.requiredValidation]"
                ></v-text-field>
              </v-col>

            </v-row>

          </template>
          <div class="pt-6">

          <ExtraObservationList
                ref="registerExtraObservation"
                v-if="!!assessmentToReview"
                v-model="extraObservationForms.registerForm"
                @onValidChange="(value) => extraObservationValidations.registerFormValid = value"
            >
              <template v-slot:title>
                <div class="subtitle-2 text-left">Registro Extra Observaciones</div>
              </template>

            </ExtraObservationList>


          </div>
          <!--        pesticide section-->

          <v-divider class="my-4"></v-divider>
<!--          todo autofill on review-->
          <div class="mt-4"></div>
          <div class="subtitle-2 text-left">Aplicaciones</div>
          <PesticideListInput

              v-if="!assessmentToReview"
              v-model="formFields.pesticideFormFields"
              ref="pesticideForm"
          />

<!--          todo pesticide view component-->
          <v-card
              v-if="!!assessmentToReview"
              v-for="pesticideField in formFields.pesticideFormFields"
          >

            <v-card-title>{{pesticideField.pesticideType}}</v-card-title>

            <v-card-text>
              <v-row
                  align="center"
              >

                <v-col>
                  <p >
                    Problema: {{pesticideField.formField.pest.answer.pest}}
                  </p>
                </v-col>
                <v-col>
                  <p>
                    Ingrediente Activo: {{pesticideField.formField.pesticide.answer.activeIngredient}}
                  </p>
                </v-col>
                <v-col>
                  <p>
                    Observaciones: {{pesticideField.formField.pesticideFrequency.answer}}
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>



          <ExtraObservationList
          v-if="!!assessmentToReview"
          v-model="extraObservationForms.pesticideForm"
          @onValidChange="(value) => extraObservationValidations.pesticideFormValid = value"
          >
            <template v-slot:title>
              <div class="subtitle-2 text-left">Observaciones adicionales de plaguicidas</div>
            </template>

          </ExtraObservationList>


          <v-divider class="my-4"></v-divider>

<!--          <br />-->
          <h4 class="text-left">
            {{$t('whichHrPhase')}}
          </h4>
          <br />

          <v-row class="green lighten-5">
            <v-col>
              <v-select
                  v-if="allPhases.length"
                  :items="allPhases"
                  label="Seleccionar fase"
                  item-text="name"
                  item-value="phase"
                  :value="selectedPhaseQuery"
                  clearable
                  :rules="[sharedValidations.requiredValidation]"
                  required
                  @change="phaseHandler"
                  data-cy="selectedPhaseQuery"
              ></v-select>
            </v-col>
          </v-row>


        </v-container>




        <div>
          <h2 class="text-left my-5">
            {{ sheetFivePhasesEnum[selectedPhaseQuery] }}
          </h2>

          <div v-if="selectedPhaseQuery === 'phaseOne'">
            <Phase1Container
                v-model="phaseFormFields"
                :field="field"
                :customer="customer"
                :tutoringRequired="isTutoringRequired"
                :disableForm="disableForm"
                v-on:phase-validation="updatePhaseOneValidation"
                ref="phaseOneForm"
            />
          </div>
          <div v-if="selectedPhaseQuery === 'phaseTwo'">
            <Phase2Container
                v-model="phaseFormFields"
                ref="phaseTwoForm"
                v-on:phase-validation="updatePhaseTwoValidation"
            />
          </div>
          <div v-if="selectedPhaseQuery === 'phaseThree'">
            <Phase3Container
                v-model="phaseFormFields"
                ref="form2"/>
          </div>
          <div v-if="selectedPhaseQuery === 'phaseFour'">
            <Phase4Container
                v-model="phaseFormFields"
                v-on:phase-validation="updatePhaseFourValidation"
                ref="phaseFourForm"/>
          </div>
        </div>
        <div class="text-left">
          <SignatureInput
              required
              :type="this.imageUploadTypeEnums.technicalVisitView"
              :typeId="this.field.uuid"
              @saveImage="saveSignatureImage"
          >
            <SingleImage maxHeight="200px"  :image="formFields.signedImage" />
          </SignatureInput>

        </div>
        <v-divider></v-divider>
        <v-container
            class="px-0 text-left"
            fluid
        >
          <v-checkbox
              v-model="isPhaseComplete"
              :label="`completar y cerrar esta fase: ${isPhaseComplete ? 'verdadero' : 'falso'}`"
          ></v-checkbox>
          <p style="min-height: 24px">
            <template v-if="isPhaseComplete">
              no podrá volver a llenar esta fase. pasarás a la siguiente fase
            </template>
          </p>
        </v-container>




        <ExtraObservationList
        v-if="!!assessmentToReview"
        v-model="extraObservationForms.selectedPhaseForm"
        @onValidChange="(value) => extraObservationValidations.selectedPhaseFormValid = value"
        >
          <template v-slot:title>
            <div class="subtitle-2 text-left">Observaciones adicionales de plaguicidas</div>
          </template>

        </ExtraObservationList>



        <v-divider></v-divider>

        <template v-if="!!assessmentToReview">
         <div>
          <TechnicalVisitExtraObservationValidations
              :extraObservationValidations="{...extraObservationValidations, extraObservationValidationsVisitDate}"
          />

          <TechnicalVisitExtraObservationActionButtons
            :isValid="isExtraObservationsFormValid"
            @submit="saveExtraObservationForm"
            @validate="validateExtraObservation"
          />

         </div>


        </template>

        <template v-else>


        <div class="d-flex justify-content-between" style="width: 100%;">

          <v-card class="col-4 text-left mb-2" tile>
            <v-subheader>{{$t('hr.validations')}}</v-subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('register')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action data-cy="register-validation">
                <v-icon v-if="isRegisterFormValid" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('hr.pesticide')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="arePesticideFormsValid" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('hr.phase')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action  data-cy="phase-validation">
                <v-icon v-if="isSelectedPhaseValid1" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('form.label.extraObservation')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="areExtraObservationsValid" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{$t('signature')}}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon v-if="isDocumentSigned" color="grey lighten-1">mdi-check</v-icon>
                <v-icon v-else color="grey lighten-1">mdi-close</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-card>



          <div class="ml-auto align-self-end">
            <div class="text-right hidden-xs-only">
              <v-btn data-type="save-button" color="warning" small dense class="ma-1" @click="this.validate">
                {{$t('validate')}}
              </v-btn>
              <v-btn data-type="save-button" color="warning" small dense class="ma-1" @click="this.saveForm" :disabled="!isFormValid" >
                {{$t('save')}}
              </v-btn>
            </div>

          </div>

        </div>

        </template>



      </v-form>

    </v-main>

  </PageHeader>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
import DateInput from "@/components/DateInput";
import { v4 as uuidv4 } from "uuid";
import TakePhoto from "@/components/TakePhoto";
import SingleImage from "@/components/SingleImage";
import {areFormFieldsFilled, daysBetweenDates} from "@/views/partners/hr_co/utils";
import Time from "@/components/Time";
import SignatureModal from "@/components/SignatureComponent/SignatureModal";
import ImageUploadTypeEnums from "@/mixins/ImageUploadTypeEnums";
import PesticideListInput from "@/views/partners/hr_co/components/pestsAndDiseasesInput/PesticideListInput";
import {assessmentNameEnum, sheetFivePhases} from "@/views/partners/hr_co/constant";
import Phase4Container
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseFour/Phase4Container";
import Phase1Container
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseOne/Phase1Container";
import Phase2Container
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseTwo/Phase2Container";
import Phase3Container
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseThree/Phase3Container";
import SignatureInput from "@/components/SignatureComponent/SignatureInput";
import ExtraObservationList
  from "@/views/partners/hr_co/components/multipleExtraObservationsInput/ExtraObservationList.vue";
import TechnicalVisitExtraObservationValidations
  from "@/views/partners/hr_co/views/newTechnicalVisit/components/TechnicalVisitExtraObservationValidations.vue";
  import TechnicalVisitExtraObservationActionButtons
  from "@/views/partners/hr_co/views/newTechnicalVisit/components/TechnicalVisitExtraObservationActionButtons";

export default {
  components: {
    PesticideListInput,
    PageHeader,
    DateInput,
    Phase1Container,
    Phase2Container,
    Phase3Container,
    Phase4Container,
    TakePhoto,
    SingleImage,
    Time,
    SignatureModal,
    SignatureInput,
    ExtraObservationList,
    TechnicalVisitExtraObservationValidations,
    TechnicalVisitExtraObservationActionButtons,
  },
  name: "TechnicalVisitView",
  data() {
    return {
      selectedPhaseValid: false,
      selectedPhaseRef: undefined,
      phaseFormFields: undefined,
      isPhaseComplete: false,
      sheetFivePhasesEnum: sheetFivePhases,
      allPhases: [],
      disableForm: false,
      formFields: {
        register: {
          visitDate: {
            question: "Fecha Visita",
            answer: undefined,
          },
          dayAfterTransplant: {
            question: "Día después de transplante (DDT)",
            answer: undefined,
          },
          landProperlyPrepared: {
            question:
                "El terreno se encuentra adecuadamente preparado y con su sistema de drenajes bien establecido?",
            answer: undefined,
            extraObservationAnswer: undefined

          },
          irrigationSystemProperlyWorking: {
            question: "¿El Sistema de riego está en buen funcionamiento?",
            answer: undefined,
            extraObservationAnswer: undefined
          },
          canYouAddFertilizer: {
            question:
                "¿Puede adicionar los fertilizantes por el sistema de riego?",
            answer: undefined,
          },
          phenologyAccordingToAgeOfPlant: {
            question: "¿La Fenología del cultivo va acorde a su edad?",
            answer: undefined,
            extraObservationAnswer: undefined
          },
          waterBalance: {
            question: "Suministro de Riego Recomendado",
            answer: undefined
          },
          tutoringRequired: {
            question:
                "¿Se requiere Tutorado?",
                answer: undefined,
                disabled: false,
          },
          sticksEstablishedPerDesign: {
            question:
                "¿Se implementó el tutorado según el diseño establecido?",
            answer: undefined,
            extraObservationAnswer: undefined
          },
          sticksGoodCondition: {
            question: "¿Está el Tutorado en buen estado?",
            answer: undefined,
            extraObservationAnswer: undefined
          },
          goodCropAdministration: {
            question: "¿Se ha realizado buena Administración del cultivo?",
            answer: undefined,
          },
          enoughLabourForPhase: {
            question:
                "¿Hay suficiente mano de obra para esta fase del cultivo?",
            answer: undefined,
          },
          previousRecommendationMade: {
            question:
                "¿Se realizaron las recomendaciones de la visita anterior?",
            answer: undefined,
          },
          complyOfTheRecommendation: {
            question:
                "¿Cómo calificaría el cumplimiento de las recomendaciones?",
            answer: undefined,
          },
          otherPendingTasks: {
            question: "Otras tareas pendientes",
            answer: undefined,
          },
          observations: {
            question: "Observaciones",
            answer: undefined,
          },
        },
        signedImage: undefined,
        pesticideFormFields: []
      },
      sharedValidations: new HugoValidations(),
      selectedPestsSearch: [],
      phaseOneValid: false,
      phaseTwoValid: false,
      phaseFourValid: false,
      extraObservationForms: { // not used in normal mode, alternative use in review mode
        visitDate: {
          question: "Fecha Visita",
          answer: undefined,
        },
        registerForm: null,
        pesticideForm: null,
        selectedPhaseForm: null,

      },
      extraObservationValidations: { // not used in normal mode, alternative use in review mode
        registerFormValid: false,
        pesticideFormValid: false,
        selectedPhaseFormValid: false,
      }
    };
  },
  mounted: function () {
    this.allPhases = this.createAllPhasesSelectItems()

    if (this.assessmentToReview) {
      this.disableForm = true
      // todo use visitAssessmentForm use uuid first to reduce payload size
      this.formFields = this.assessmentToReview.fields
      this.phaseHandler(this.assessmentToReview.selectedPhaseEnum)
      this.phaseFormFields = this.assessmentToReview.phaseForm

    }

    if (this.previousAssessment) {
      if (['yes', 'Si', 'si', 'Sí'].includes(this.previousAssessment.fields.register.landProperlyPrepared.answer)) {
        this.formFields.register.landProperlyPrepared.answer = 'Sí'
        this.formFields.register.landProperlyPrepared.disabled = true
      }

      if (['yes', 'Si', 'si', 'Sí'].includes(this.previousAssessment.fields.register.irrigationSystemProperlyWorking.answer)) {
        this.formFields.register.irrigationSystemProperlyWorking.answer = 'Sí'
        this.formFields.register.irrigationSystemProperlyWorking.disabled = true
      }

      // if (this.previousAssessment.visitAssessmentForm.register.canYouAddFertilizer.answer === 'yes') {
      //   this.formFields.register.canYouAddFertilizer.answer = 'yes'
      //   this.formFields.register.canYouAddFertilizer.disabled = true
      // }

      if (['yes', 'Si', 'si', 'Sí'].includes(this.previousAssessment.fields.register.phenologyAccordingToAgeOfPlant.answer)) {
        this.formFields.register.phenologyAccordingToAgeOfPlant.answer = 'Sí'
        this.formFields.register.phenologyAccordingToAgeOfPlant.disabled = true
      }

      if (['yes', 'Si', 'si', 'Sí'].includes(this.previousAssessment.fields.register.sticksEstablishedPerDesign.answer)) {
        this.formFields.register.sticksEstablishedPerDesign.answer = 'Sí'
        this.formFields.register.sticksEstablishedPerDesign.disabled = true
      }

      if (['yes', 'Si', 'si', 'Sí'].includes(this.previousAssessment.fields.register.sticksGoodCondition.answer)) {
        this.formFields.register.sticksGoodCondition.answer = 'Sí'
        this.formFields.register.sticksGoodCondition.disabled = true
      }


      if (!!this.previousAssessment.fields.register.tutoringRequired && !!this.previousAssessment.fields.register.tutoringRequired.answer) {

        if (this.previousAssessment.fields.register.tutoringRequired.answer.toLowerCase() === 'yes') {
          this.formFields.register.tutoringRequired.answer = 'Sí'
        } else {
          this.formFields.register.tutoringRequired.answer = this.previousAssessment.fields.register.tutoringRequired.answer
        }
        this.formFields.register.tutoringRequired.disabled = true
      }
    }
  },
  methods: {
    toggleTutoradoForms(tutorado) {
      console.log('toggleTutoradoForms')
      if (!tutorado) {
        return
      }
      if (tutorado === 'no') {
        this.formFields.register.sticksEstablishedPerDesign = {}
        this.formFields.register.sticksGoodCondition = {}
        return ''
      } else if (tutorado === 'yes') {
        this.formFields.register.sticksEstablishedPerDesign = {
          question: "¿Se implementó el tutorado según el diseño establecido?",
          answer: undefined,
          extraObservationAnswer: undefined
        }

        this.formFields.register.sticksGoodCondition = {
          question:
              "¿Está el Tutorado en buen estado?",
          answer: undefined,
          extraObservationAnswer: undefined
        }
      }
    },
    updatePhaseOneValidation(newValue) {
      this.phaseOneValid = newValue
    },
    updatePhaseTwoValidation(newValue) {
      this.phaseTwoValid = newValue
    },
    updatePhaseFourValidation(newValue) {
      this.phaseFourValid = newValue
    },
    saveSignatureImage(imageObj) {
      this.$store.dispatch("addImage", imageObj);
      this.formFields.signedImage = imageObj;
    },
    phaseHandler(value) {
      this.phaseFormFields = undefined;
      this.$router.replace({
        path: this.$route.path,
        query: { ...this.$route.query, phase: value },
      });
    },
    saveForm() {
      if (this.formFields.pesticideFormFields && typeof this.formFields.pesticideFormFields === 'object') {
        this.formFields.pesticideFormFields.forEach(pesticideForm => {
          // remove selects metadata
          delete pesticideForm.autocompleteData
          delete pesticideForm.formField.pest.answer.pesticides
        });
      }

      const newAssessment = {
        assessmentUuid: uuidv4(),
        assessmentName: 'technicalVisit',
        customerUuid: this.customerUuid,
        fieldUUid: this.field.uuid,
        createdAt: new Date().toISOString(),
        fields: this.formFields,
        selectedPhaseEnum: this.selectedPhaseQuery,
        selectedPhase: this.sheetFivePhasesEnum[this.selectedPhaseQuery],
        phaseForm: this.phaseFormFields,
        isPhaseComplete: this.isPhaseComplete
      };

      this.$store.commit("upsertAssessment", newAssessment);
      this.goAssessment(newAssessment.assessmentUuid);
    },
    saveExtraObservationForm() {

      // extra observations need to hold the 3 sections

      // this.extraObservationForms
      const newAssessment = {
        assessmentUuid: uuidv4(),
        assessmentName: 'technicalVisit.review',
        technicalVisitType: 'review',
        initialTechnicalVisitUuid: this.assessmentToReview.assessmentUuid,
        customerUuid: this.customerUuid,
        fieldUUid: this.field.uuid,
        createdAt: new Date().toISOString(),
        fields: this.extraObservationForms,
        selectedPhaseEnum: this.selectedPhaseQuery,
        selectedPhase: this.sheetFivePhasesEnum[this.selectedPhaseQuery],
        phaseForm: this.phaseFormFields,
        isPhaseComplete: this.isPhaseComplete
      };

      this.$store.commit("upsertAssessment", newAssessment);
      this.goExtaObservation(newAssessment.assessmentUuid);
    },


    validate() {
      this.$refs.form.validate();
      if (this.$refs.form2) {
        this.$refs.form2.validate();
      }

      if (this.$refs.pesticideForm && this.formFields.pesticideFormFields && this.formFields.pesticideFormFields.length > 0) {
        this.$refs.pesticideForm.validate();
      }
    },
    validateExtraObservation() {
      this.$refs.form.validate();
      this.$refs.form2.validate();
      this.$refs.pesticideForm.validate();
    },
    goBack() {
      this.$router.push({
        name: "Field",
        query: { uuid: this.customerUuid, fieldUuid: this.field.uuid },
      });
    },
    goAssessment(assessmentUuid) {
      this.$router.push({
        name: "FieldAssessment",
        query: { assessmentUuid: assessmentUuid },
      });
    },
    goExtaObservation(assessmentUuid) {
      this.$router.push({
        name: "ExtraObservationAssessmentPrint",
        query: { assessmentUuid: assessmentUuid },
      });
    },
  createAllPhasesSelectItems() {
    const isPhaseOneComplete = this.isPhaseOneComplete
    const isPhaseTwoComplete = this.isPhaseTwoComplete
    const isPhaseFourComplete = this.isPhaseFourComplete
    return [
      {
        name: !isPhaseOneComplete ? sheetFivePhases.phaseOne : `${sheetFivePhases.phaseOne} Completado`,
        phase: 'phaseOne',
        value: sheetFivePhases.phaseOne,
        disabled: isPhaseOneComplete
      },
      {
        name: !isPhaseTwoComplete ? sheetFivePhases.phaseTwo : `${sheetFivePhases.phaseTwo} Completado`,
        phase: 'phaseTwo',
        value: sheetFivePhases.phaseTwo,
        disabled: (!isPhaseOneComplete || this.isPhaseTwoComplete)
      },
      {
        name: sheetFivePhases.phaseThree,
        phase: 'phaseThree',
        value: sheetFivePhases.phaseThree,
        disabled: true
      },
      {
        name: !isPhaseFourComplete ? sheetFivePhases.phaseFour : `${sheetFivePhases.phaseFour} Completado`,
        phase: 'phaseFour',
        value: sheetFivePhases.phaseFour,
        disabled:  (!isPhaseOneComplete || !isPhaseTwoComplete || isPhaseFourComplete)
      }
    ]
  },
  },
  computed: {
    selectedAssessmentUuid() {
      return this.$route.query.assessment;
    },
    assessmentToReview() {
      return !!this.selectedAssessmentUuid && this.assessments.find(a => a.assessmentUuid === this.selectedAssessmentUuid);
    },
    isFormValid() {

      // todo separate dynamic forms validations

      // this.$refs.form2 is dynamic component with computed boolean value isFormGroupValid
      let formFieldsValid
      if (this.selectedPhaseQuery === 'phaseThree') {
        formFieldsValid = this.isRegisterFormValid && this.$refs.form2 && this.$refs.form2.areCardFormsValid

      } else {
        // other phases
        formFieldsValid = this.isRegisterFormValid && this.isSelectedPhaseValid1
      }
      return formFieldsValid && this.areExtraObservationsValid && this.arePesticideFormsValid && this.isDocumentSigned
    },
    isTutoringRequired() {
      return this.formFields.register.tutoringRequired && ['yes', 'Si', 'si', 'Sí'].includes(this.formFields.register.tutoringRequired.answer)
    },
    isDocumentSigned() {
      return !!this.formFields.signedImage
    },

    isExtraObservationsFormValid() {

      return this.extraObservationValidations.registerFormValid &&
          this.extraObservationValidations.pesticideFormValid &&
          this.extraObservationValidations.selectedPhaseFormValid &&
          this.extraObservationValidationsVisitDate
    },
    isRegisterFormValid() {
      return areFormFieldsFilled(Object.values(this.formFields.register))
    },
    isSelectedPhaseValid1() {
      if (this.selectedPhaseQuery === 'phaseOne') {
        return this.isPhaseOneValid
      } else if (this.selectedPhaseQuery === 'phaseTwo'){
        return this.isPhaseTwoValid
      } else if (this.selectedPhaseQuery === 'phaseFour') {
        return this.isPhaseFourValid
      }
    },
    isPhaseOneValid: {
      get() {
        return this.phaseOneValid
      },
      set(newValue) {
        this.phaseOneValid = newValue
      }
    },
    isPhaseTwoValid: {
      //
      get() {
        return this.phaseTwoValid
      },
      set(newValue) {
        this.phaseTwoValid = newValue
      }
    },
    isPhaseFourValid: {
      get() {
        return this.phaseFourValid
      },
      set(newValue) {
        this.phaseFourValid = newValue
      }
    },
    areExtraObservationsValid() {
      const isValid = Object.values(this.formFields.register)
          .filter(formField =>
              'extraObservationAnswer' in formField && !formField.extraObservationAnswer
          )

      return isValid.length === 0;
    },
    arePesticideFormsValid() {
      const allInvalid = this.formFields.pesticideFormFields.filter(pesticideFormFields => {
        return !pesticideFormFields.formField.valid
      })
      return allInvalid.length === 0;
    },
    customerUuid() {
      return this.$route.query.uuid;
    },

    fieldUuid() {
      return this.$route.query.fieldUuid;
    },

    customer() {
      return this.$store.getters.customer(this.customerUuid);
    },
    selectedPhaseQuery() {
      return this.$route.query.phase;
    },
    assessments() {
      return this.$store.getters.customerAssessments(this.customerUuid).filter(assessment => assessment.fieldUUid === this.fieldUuid );
    },
    previousAssessment() {
      const sortedAssessments = this.assessments.filter(a => a.assessmentName === 'technicalVisit').sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      return this.assessments.length ? sortedAssessments[0] : null;
    },
    field() {
      return this.customer.fields.find(
          (e) => e.uuid === this.$route.query.fieldUuid
      );
    },
    cultivationContractAssessment() {
      return this.$store.getters.assessments.find(assessment =>
          assessment.assessmentName === assessmentNameEnum.cultivationContract &&
          assessment.customerUuid === this.customerUuid &&
          assessment.fieldUuid === this.field.uuid
      )
    },
    isPhaseOneComplete() {
      const phase1Assessment = this.assessments
          .find(assessment => assessment.selectedPhase === this.sheetFivePhasesEnum.phaseOne && assessment.isPhaseComplete)
      return !!phase1Assessment && phase1Assessment.isPhaseComplete
    },
    isPhaseTwoComplete() {
      const phase1Assessment = this.assessments.find(assessment => assessment.selectedPhase === this.sheetFivePhasesEnum.phaseTwo && assessment.isPhaseComplete)
      return !!phase1Assessment && phase1Assessment.isPhaseComplete
    },
    isPhaseThreeComplete() {
      const phase1Assessment = this.assessments.find(assessment => assessment.selectedPhase === this.sheetFivePhasesEnum.phaseThree && assessment.isPhaseComplete)
      return !!phase1Assessment && phase1Assessment.isPhaseComplete
    },
    isPhaseFourComplete() {
      const phaseFourAssessment = this.assessments.find(assessment => assessment.selectedPhase === this.sheetFivePhasesEnum.phaseFour && assessment.isPhaseComplete)
      return !!phaseFourAssessment && phaseFourAssessment.isPhaseComplete
    },
    extraObservationValidationsVisitDate() {
      return !!this.extraObservationForms.visitDate.answer
    },
  },
  watch: {
    'formFields.register.visitDate.answer': function(newValue) {
      if (!newValue) {
        return ''
      }
      const transplantDate = this.cultivationContractAssessment.formFields.calculatedTargetDateSeedlingsPlanted.answer
      const diffTransplantDays = daysBetweenDates(new Date(transplantDate), new Date(newValue))
      this.formFields.register.dayAfterTransplant.answer = diffTransplantDays
    },
    'formFields.register.tutoringRequired.answer': function(newValue) {
      // todo update form field when tutoring required changes
      // tutoring options on register
      // if phase is phase 1
      if (!newValue) {
        return
      }
      if (newValue === 'no') {
        this.formFields.register.sticksEstablishedPerDesign = {}
        this.formFields.register.sticksGoodCondition = {}
        return ''
      } else if (['yes', 'Si', 'si', 'Sí'].includes(newValue)) {
        this.formFields.register.sticksEstablishedPerDesign = {
          question: "¿Se implementó el tutorado según el diseño establecido?",
          answer: undefined,
          extraObservationAnswer: undefined
        }

        this.formFields.register.sticksGoodCondition = {
          question:
              "¿Está el Tutorado en buen estado?",
          answer: undefined,
          extraObservationAnswer: undefined
        }
      }
    },
  },
  mixins: [ImageUploadTypeEnums]
};
</script>

<style scoped >

</style>

<i18n>
{
  "en": {
    "technicalVisitView": "New Evaluation & Spraying Records",
    "technicalVisitReview": "New Evaluation & Spraying Records",
    "register": "Register",
    "whichHrPhase": "In which H.R PHASE is the crop"
  },
  "es": {
    "technicalVisitView": "Visita técnica Vista",
    "technicalVisitReview": "Visita técnica Revisión",
    "register": "Registro",
    "whichHrPhase": "¿En cuál FASE está el Cultivo?"
  },
  "sw": {
    "technicalVisitView": "New Evaluation & Spraying Records",
    "technicalVisitReview": "Tathmini Mpya na Kumbukumbu za Kunyunyizia",
    "register": "Sajili",
    "whichHrPhase": "Uko Sehemu gani ya H.R"
  }
}
</i18n>

