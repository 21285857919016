<template>
  <v-form ref="testForm" v-model="validForm">
    <v-container fluid>


      <v-row>
        <v-col cols="5">
          <FingerprintInput v-model="form.fingerprint" required label="Fingerprint (Required)" />
        </v-col>
        <v-col cols="2">
          [{{form.fingerprint}}]
        </v-col>
        <v-col cols="5">
          <FingerprintInput v-model="form.fingerprint" label="Not required" />
        </v-col>
      </v-row>



      <v-row>
        <v-col class="text-right">
          <v-btn color="warning" small dense class="mr-1" @click="this.validate">
            {{$t('validate')}}
          </v-btn>
          <v-btn color="warning" small dense @click="this.saveForm" :disabled="!validForm">
            {{$t('save')}}
          </v-btn>
        </v-col>
      </v-row>

    </v-container>

  </v-form>
</template>

<script>
import FingerprintInput from "@/components/FingerprintInput";

export default {

  components: {
    FingerprintInput,
  },

  data() {
    return {
      validForm: false,
      form: {},
    }
  },

  methods: {
    validate(event) {
      this.validating = true;
      this.$refs.testForm.validate();
    },
    saveForm(event) {
      console.log('saveForm', event)
    }
  }

}
</script>
