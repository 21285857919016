<template>
  <v-form v-model="validForm">
    <v-container fluid>

      <v-row>
        <v-col cols="12" sm="4">
          <TextInput v-model="customer.partnerIdentifier" :label="$t('Farmer Registration Number')" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <NameInput v-model="customer.first_name" :label="$t('First Name')" required />
        </v-col>
        <v-col cols="12" sm="4">
          <NameInput v-model="customer.surname" :label="$t('Surname')" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="4">
          <MsisdnInput v-model="customer.msisdn" required />
        </v-col>
        <v-col cols="12" sm="4">
          <DateInput
            v-model="customer.demographic.birthdate"
            :label="$t('Birthdate')"
            min="1910-01-01"
            :max="new Date().toISOString().substr(0, 10)"
            :year="true"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <GenderInput v-model="customer.demographic.gender" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <IdentityInput v-model="customer.demographic.identity" :selectable="false" defaultType="national_id" required />
        </v-col>
        <v-col cols="12" md="3">
          <PhotoInput v-model="customer.idScan" type="id_scan" :typeId="customer.uuid" required width="100%" />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <SelectInput v-model="customer.coop" :items="coops" :label="$t('Cooperative')" required />
        </v-col>
        <v-col cols="12" sm="4">
          <NameInput v-model="customer.workAddress" :label="$t('Farm Location')" required />
        </v-col>
        <v-col cols="4">
          <NameInput v-model="customer.village" :label="$t('Village')" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <RegionPicker v-model="customer.regions" />
        </v-col>
      </v-row>








      <v-row>
        <v-col class="text-left">
          <v-btn @click="save('submitAndEdit')" color="primary" :disabled="!validForm" class="mr-2">
            <span class="hidden-sm-and-up">
              {{$t('Save')}}
            </span>
            <span class="hidden-xs-only">
              {{$t('SaveAndViewDetail')}}
            </span>
          </v-btn>
        </v-col>
        <v-col class="text-right">
          <v-btn @click="save('submitAndNew')" color="primary" :disabled="!validForm">
            <span class="hidden-sm-and-up">
              {{$t('Save')}}+
            </span>
            <span class="hidden-xs-only">
              {{$t('Save')}}
            </span>
          </v-btn>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>


<script>
  import PhotoInput from '@/components/PhotoInput';
  import RegionPicker from '@/components/RegionPicker';
  import MsisdnInput from '@/components/MsisdnInput';
  import TextInput from '@/components/TextInput';
  import NameInput from '@/components/NameInput';
  import DateInput from '@/components/DateInput';
  import GenderInput from '@/components/GenderInput';
  import IdentityInput from '@/components/IdentityInput';
  import SelectInput from '@/components/SelectInput';

  export default {

    components: {
      PhotoInput,
      RegionPicker,
      MsisdnInput,
      TextInput,
      NameInput,
      DateInput,
      GenderInput,
      IdentityInput,
      SelectInput,
    },

    props: ['customer'],

    data: () => ({
      validForm: false,
      villages: ['Chekereni', 'Moshi Town'],
      village: undefined,
    }),


    computed: {
      coops() { return [ { text: 'Upendo', value: 4 }, { text: 'Four Sisters', value: 5 } ]; },
    },

    methods: {
      save(action) {
        let field = this.newField('Maize');
        this.updateCustomerField(field);
        console.log(this.customer);
        this.$emit(action);
      },
    },

  }
</script>

