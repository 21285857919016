<template>
  <div>
    <Phase1Section1Form
        v-model="formFields.register"
        :field="field"
        :customer="customer"
        :tutoringRequired="tutoringRequired"
        :disableForm="disableForm"
        v-on:phase-validation="updatePhaseOneValidation"
        ref="form1"
    />
<!--    disableForm-->

    <Phase1Section2Form
        v-model="formFields.irrigation"
        :customer="customer"
        :field="field"
        :distanceBetweenRows="formFields.register.distanceBetweenRows.answer"
        :fieldArea="field.fieldSize.landSize"
        :disableForm="disableForm"
        ref="form2" />

    <v-divider></v-divider>

    <Phase1Section3Form
        v-model="formFields.plasticMulch"
        :field="field"
        :customer="customer"
        :disableForm="disableForm"
        ref="form3"
    />

  </div>

</template>


<script>
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
import Phase1Section1Form from "./Phase1Section1Form";
import Phase1Section2Form from "./Phase1Section2Form";
import Phase1Section3Form from "./Phase1Section3Form";

export default {
  components: { Phase1Section1Form, Phase1Section2Form, Phase1Section3Form},
  props: ['value', 'field', 'customer', 'tutoringRequired', 'disableForm'],
  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!to) {
        } else {
          this.formFields = to;
        }
      }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
    'formFields.register.distanceBetweenPlants.answer': function(newValue) {
      if (!newValue) {
        return ''
      }
      this.formFields.plasticMulch.distanceBetweenHoles.answer = newValue
    },
    'isPhaseFormValid': function (newValue) {
      this.$emit('phase-validation', newValue);
    }
  },
  data() {
    return {
      formFields: {
        register: {
          landPreparation: {
            question: '¿Tipo de preparación terreno?',
            answer: undefined,
          },
          drainageTrenches: {
            question: '¿Zanjas para Drenaje?',
            answer: undefined
          },
          distanceBetweenRows: {
            question: '¿Distancia entre surcos (Mts)?',
            answer: undefined
          },
          plantingBedsHeight: {
            question: '¿Altura Camas?',
            answer: undefined
          },
          distanceBetweenPlants: {
            question: '¿Distancia entre plantas (Mts) ?',
            answer: undefined
          },
          rows: {
            question: '¿Hileras?',
            answer: undefined
          },
          totalPlantsForOverseeding: {
            question: '¿Total plantas para resiembra?',
            answer: undefined
          },
          totalPlants: {
            question: '¿Total Plantas por hectárea?',
            answer: undefined
          },
          // sticks: {
          //   question: '¿Tutorado?',
          //   answer: undefined
          // },
          totalContractPlants: {
            question: 'Total Plantas del Contrato',
            answer: undefined
          },
          fiberCaliber: {
            tutoringRequired: true,
            question: '¿Calibre de Fibra?',
            answer: undefined
          },
          fiberMooring: {
            tutoringRequired: true,
            question: '¿Amarre de Fibra (cm)?',
            answer: undefined
          },
          totalFiberHectare: {
            tutoringRequired: true,
            question: 'Total Fibra por Hectárea',
            answer: undefined
          },
          totalContractFiber: {
            question: 'Total Fibra del Contrato',
            answer: undefined
          },
          typeOfRoll: {
            tutoringRequired: true,
            question: '¿Tipo de Rollo?',
            answer: undefined
          },
          totalFiberRolls: {
            question: 'Total Rollos de Fibra',
            answer: undefined
          },
          totalSticks: {
            question: '¿Total tutores?',
            answer: undefined
          },
          linesOfFibers: { // todo remove
            tutoringRequired: true,
            question: '¿Líneas de fibra?',
            answer: undefined
          },
          dateOfTransplant: {
            question: '¿Fecha Estimada Transplante?',
            answer: undefined
          },
          stickDistanceForPlanting: {
            question: '¿Distancia Tutorado (Mts)?',
            answer: undefined
          },
          attachedImages: []
        },
        irrigation: {

          hasIrrigation: {
            question: 'Riego',
            answer: undefined,
          },
          typeOfMulch: {
            question: '¿Sistema de riego por goteo?',
            answer: undefined,
          },
          drainageTrenches: {
            question: '¿Distancia entre goteros (Mts) ?',
            answer: undefined,
          },
          dripperFlow: {
            question: '¿Flujo por gotero?',
            answer: undefined,
          },
          typeOfFilterInTheSystem: {
            question: '¿Qué tipo de Filtro se usará?',
            answer: undefined,
          },
          dateForInstallingIrrigationSystem: {
            question: '¿Fecha definida para instalación sistema riego?',
            answer: undefined,
          },
          irrigationSystemWasTested: {
            question: '¿Hizo prueba del sistema de riego?',
            answer: undefined,
          },
          dateWhen: {
            question: '¿Cuando?',
            answer: undefined,
          },
          totalTapePerHectare: {
            question: 'Total Cinta por Hectárea.(Mts)',
            answer: undefined,
          },
          totalTapePerContract: {
            question: 'Total Cinta del Contrato.(Mts)',
            answer: undefined,
          },
          totalDroppers: {
            question: 'Total Goteros.',
            answer: undefined,
          },
          totalLandFlows: {
            question: 'Flujo Total del Terreno/Hora.',
            answer: undefined,
          },
          typeOfFertilizerInjection: {
            question: '¿Que tipo de Inyección Fertilizante?',
            answer: undefined,
          },

          attachedImages: []
        },
        plasticMulch: {
          hasPlasticMulch: {
            question: 'Emplasticado',
            answer: undefined,
          },
          plasticCaliber: {
            question: '¿Calibre del Plástico?',
            answer: undefined,
          },
          plasticColor: {
            question: '¿Color del Plástico?',
            answer: undefined,
          },
          measuresOfTheMulch: {
            question: '¿Ancho del Mulch (Mts) ?',
            answer: undefined,
          },
          necessaryChemical: {
            question: '¿Es necesario realizar control de arvenses antes de realizar la labor de cobertura?',
            answer: undefined,
          },
          distanceBetweenHoles: {
            question: '¿Diseño - Distancias perforaciones?',
            answer: undefined,
          },
          attachedImages: []
        },
      },
      sharedValidations: new HugoValidations(),
      phaseOneValid: false
    }
  },
  methods: {
    validate() {
      this.$refs.form1.validate();

      this.$refs.form2.validate();
      this.$refs.form3.validate();
    },
    updatePhaseOneValidation(newValue) {
      this.phaseOneValid = newValue
    },
  },
  computed: {
    isPhaseFormValid: function() {
      return this.phaseOneValid && this.isPhaseSectionTwoValid && this.isPhaseSectionThreeValid
    },

    isPhaseSectionTwoValid: function() {
      return this.$refs.form2.isPhaseOneSectionValid
    },

    isPhaseSectionThreeValid: function() {
      return this.$refs.form3.isPhaseOneSectionValid
    },

  }
}

</script>

<style scoped>

</style>
