<template>
  <v-form v-model="valid">

    <v-row>
      <v-col cols="12">
        <FingerprintInput v-model="fingerprint" required label="Fingerprint (Required)" />
      </v-col>
    </v-row>

  </v-form>

</template>

<script>

import FingerprintInput from "@/components/FingerprintInput";

export default {

  components: { FingerprintInput },

  data() {
    return {
      valid: false,
      fingerprint: undefined,
    }
  },

  watch: {
    fingerprint(to) {
      if (to) {
        this.$emit('input', to); }
      }
  },

}
</script>
