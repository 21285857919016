<template>
  <v-stepper-content step="1" class="ml-0">
    <v-form ref="basicInfoForm" v-model="valid">
      <v-container fluid>
        <v-row v-if="!!customer">
          <v-col cols="12" md="4">
            <NameInput v-model="customer.firstName" required label="First Name" />
          </v-col>
          <v-col cols="12" md="4">
            <NameInput v-model="customer.surname" required label="Last Name" />
          </v-col>
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.nationality" :items="nationalities" label="Nationality" readonly />
          </v-col>

          <v-col cols="12">
            <IdentityInput v-model="customer.demographic.identity" required :country="customer.nationality" defaultType="national_id" :selectable="false" />
          </v-col>
          <v-col cols="12" md="4">
            <DateInput v-model="customer.demographic.birthdate" :singleYear="yearOfBirth" :disabled="!yearOfBirth" dropLists />
          </v-col>

          <v-col cols="12" md="4">
            <SelectInput v-model="customer.economic.dependants" :items="dependantsOptions" label="Dependants" required />
          </v-col>
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.economic.payload.schoolGoingDependants" :items="schoolDependants" label="School Going Dependents" required/>
          </v-col>
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.demographic.level_of_education" :items="educationLevels" label="Education" />
          </v-col>

          <v-col cols="12" md="4">
            <v-checkbox v-model="customer.atHome" label="Are we near where you live?" />
          </v-col>

          <v-col cols="12" md="4">
            <SelectInput v-model="customer.coffeeRegion" :items="coffeeRegions" label="Coffee Region" required />
          </v-col>
          <v-col cols="12">
            <RegionInput v-model="customer.demographic.geographicsArray" :requiredLevels="4" />
          </v-col>
          <v-col cols="12" md="4">
            <DepotInput v-model="customer.depot" label="Co-op" required />
          </v-col>

          <v-col cols="12" md="4">
            <SelectInput v-model="customer.alternativeIncomeSource" :items=incomeSources label="Alternative Sources" multiple/>
          </v-col>
          <v-col cols="12" md="4">
            <TextInput v-model="customer.alternativeIncomeAmount" :required="hasOtherIncomeSources" :disabled="!hasOtherIncomeSources" label="Monthly Alternative Income" type="number" suffix="UGX" />
          </v-col>
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.assetHeld" :items=assetsOptions label="Assets Held" multiple/>
          </v-col>
          <v-col cols="12" md="3">
            <PhotoInput v-model="customer.selfie" type="selfie" :typeId="customer.uuid" label="Photo of Farmer" dense width="100%" />
          </v-col>
          <v-col cols="12" md="3">
            <PhotoInput v-model="customer.ninFront" type="id_scan" :typeId="customer.uuid" required label="NIN: Front" width="100%" />
          </v-col>
          <v-col cols="12" md="3">
            <PhotoInput v-model="customer.ninBack" type="id_scan" :typeId="customer.uuid" required label="NIN: Back" width="100%" />
          </v-col>
          <v-col cols="12" md="4">
            <v-checkbox v-model="customer.hasFcsCard" label="Farmer has an FCS card?" hide-details />
          </v-col>
          <v-col cols="12" md="4">
            <TextInput v-model="customer.fcsCard" label="FCS Card Number" type="number" width="100%" :disabled="!customer.hasFcsCard" />
          </v-col>
        </v-row>

        <v-row class="mx-4 my-4">
          <v-col cols="12">
            <v-btn color="primary" style="width: 100%;" @click="validateAndContinue" :disabled="!valid"> Save & Continue </v-btn>
          </v-col>
        </v-row>

      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import PhotoInput from "@/components/PhotoInput";
import IdentityInput from "@/components/IdentityInput";
import NameInput from "@/components/NameInput";
import TextInput from "@/components/TextInput";
import SelectInput from '@/components/SelectInput';
import DateInput from '@/components/DateInput';
import RegionInput from '@/components/RegionInput';
import DepotInput from '@/components/DepotInput';

export default {

  components: { PhotoInput, NameInput, TextInput, IdentityInput, SelectInput, DateInput, RegionInput, DepotInput },

  props: {
    value: Object,
  },

  data() {
    return {
      customer: undefined,
      valid: false,
      nationalities: [
        { text: 'Burundian', value: 'BI' },
        { text: 'DRC',       value: 'CD' },
        { text: 'Ethopia',   value: 'ET' },
        { text: 'Ugandan',   value: 'UG' },
        { text: 'Kenyan',    value: 'KE' },
        { text: 'Rwanda',    value: 'RW' },
      ],
      dependantsOptions: [ 0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,'20+' ],
      educationLevels: ["Zero Education", "Primary School", "Ordinary Level of Education", "Advanced Level of Education", "Tertiary", "University"],
      regions: ['Central', 'Northern', 'Eastern', 'Western'],
      coffeeRegions: ['Elgon', 'Busoga', 'Central', 'South West', 'Mid-North', 'Rwenzori', 'West Nile'],
      incomeSources: [
          "Business",
          "Other Crops",
          "Produce Shop",
          "Shop",
          "Medical Personnel",
          "Milling",
          "Animal Husbandry",
          "Police Personnel",
          "Rental",
          "Teaching",
          "Tailoring",
          "Other",
          "None",
        ],
      assetsOptions: [
          "Land",
          "House",
          "Car/Motorcycle",
          "Other crops/Trees",
          "Cows",
          "Goats",
          "Sheep",
          "Poultry",
          "Donkeys",
          "Pigs",
          "Other",
      ],
    }
  },

  computed: {
    hasOtherIncomeSources() { return ((this.customer.alternativeIncomeSource || []).length>0); },

    schoolDependants() { 
      let r = this.dependantsOptions;
      if (this.customer && this.customer.economic.dependants && this.customer.economic.dependants!=='20+') {
        r = this.dependantsOptions.filter( e => ( e<=this.customer.economic.dependants ) );
      }
      return r;
    },

    yearOfBirth() {
      let r = undefined;
      if (this.customer.demographic.identity) {
        r = this.customer.demographic.identity.yearOfBirth
      }
      return r;
    },

    coffeeDistricts() { 
      let r = []
      if (this.customer.region) {
        r = this.districtOptions.western
        if (this.customer.region == 'Central') {
          r = this.districtOptions.central;
        } else if (this.customer.region == 'Northern') {
          r = this.districtOptions.northern;
        } else if (this.customer.region == 'Eastern') {
          r = this.districtOptions.eastern;
        } else if (this.customer.region == 'Western') {
          r = this.districtOptions.western;
        }
      }
      return r;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { 
        this.customer = to;
       }
    },
    customer(to) {
      this.$emit('input', to);
    },
    valid(to) { this.$emit('valid', to); },
  },

  methods:{
    validate() {
      this.$refs.basicInfoForm.validate();
    },
    validateAndContinue(){
      this.validate();
      this.$emit('nextStep');
    }
  },
}
</script>
