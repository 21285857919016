import { render, staticRenderFns } from "./ShowFirstVisit.vue?vue&type=template&id=3d48d050&scoped=true&"
import script from "./ShowFirstVisit.vue?vue&type=script&lang=js&"
export * from "./ShowFirstVisit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d48d050",
  null
  
)

/* custom blocks */
import block0 from "./ShowFirstVisit.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMain } from 'vuetify/lib/components/VMain';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VMain,VSimpleTable})
