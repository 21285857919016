<template>

  <Value :label="label" :elevation="elevation" :color="color" :rounded="rounded" :highlight="highlight" :width="width" @click="$emit('click')">
    <Currency :value="balance" cents />
  </Value>

</template>

<script>
import Value from "@/views/instruments/components/Value"
import Currency from "@/components/Currency"

export default {

  components: { Value, Currency },

  props: {
    balance: Number,
    label: String,
    highlight: [String, Boolean],
    cents: {
      type: Boolean,
      default: true,
    },
    width: String,
    color: String,
    elevation: {
      type: Number,
      default: 2,
    },
    rounded: {
      type: Boolean,
      default: true,
    }
  },

}
</script>
