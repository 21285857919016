<template>

  <v-row>
    <v-col cols="12">
      <RefreshableListing :approvals="approvals" @refresh="loadApprovals" />
    </v-col>
  </v-row>

</template>

<script>
import lookups from '@/mixins/lookups';
import RefreshableListing from '@/views/approvals/RefreshableListing';

export default {

  components: { RefreshableListing },

  props: ['customer'],

  data() {
    return {
      approvals: undefined,
    }
  },

  computed: {
    count() { return (this.approvals ? this.approvals.length : -1); }
  },

  watch: {
    count(to) {
      if (to===0) {
        this.$emit('done');
      }
    },
  },
    
  methods: {
    loadApprovals() {
      this.remoteLookup('ultimate_TzLoanApprovals', { uuid: this.customer.uuid }).then( approvals => this.approvals = approvals.approvals ).catch(e => this.approvals = null);
    }
  },

  mixins: [lookups],

}
</script>
