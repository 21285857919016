<template>
  <v-form v-model="formField.valid" ref="form2">
    <v-card
        outlined
        tile
    >
      <v-container fluid class="p-0">
        <v-row class="green lighten-5">
          <v-col cols="12" md="4">
            <v-autocomplete
                label="Problema"
                class="pa-2"
                :items="pesticideFormData"
                :item-text="'pest'"
                @change="pestAutocompleteSelect"
                v-model="formField.pest.answer"
                return-object
                clearable
                :rules="[sharedValidations.requiredValidation]"
            >
              <template v-slot:item="{item}">
                {{item.pest}}: {{item.pesticides.length}}
              </template>
            </v-autocomplete>

          </v-col>

          <v-col cols="12" md="4">

            <v-autocomplete
                label="Ingrediente Activo"
                class="pa-2"
                :items="selectedPestsSearch"
                :item-text="'activeIngredient'"
                return-object
                :disabled="!formField.pest.answer"
                v-model="formField.pesticide.answer"
                :rules="[sharedValidations.requiredValidation]"
            >
              <template v-slot:item="{item}">
                {{item.activeIngredient}}: {{item.dose}}
              </template>
            </v-autocomplete>

          </v-col>

          <v-col cols="12" md="4" class="d-flex">
            <p>
              Dosis:
              {{formField.pesticide.answer ? formField.pesticide.answer.dose: ''}}
            </p>

            <v-text-field
                label="Observaciones"
                class="pa-2"
                v-model="formField.pesticideFrequency.answer"
                :disabled="!formField.pesticide.answer"
                :rules="[sharedValidations.requiredValidation]"
            ></v-text-field>

            <v-btn
                color="primary lighten-2"
                text
                @click="$emit('deleteFormField')"
            >
              eliminar
            </v-btn>

          </v-col>

        </v-row>
      </v-container>

    </v-card>
  </v-form>
</template>

<script>
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
export default {
  props: ['value', 'pesticideFormData'],
  data() {
    return {
      sharedValidations: new HugoValidations(),
      selectedPestsSearch: [],
      formField: {
        pest: {
          question: '',
          answer: undefined
        },
        pesticide: {
          question: '',
          answer: undefined
        },
        pesticideFrequency: {
          question: '',
          answer: undefined
        }
      }
    }
  },
  methods: {
    pestAutocompleteSelect(value, index) {
      this.selectedPestsSearch = value.pesticides
      this.selectedPest = value
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.formField = to;
      }
    },
    formField:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
  },
}
</script>

<style scoped>

</style>
