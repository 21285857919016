<template>

    <div v-if="parsedAssessments.length > 0">
        <v-subheader>{{$t('submittedForm')}}</v-subheader>
        <v-card>

          <v-simple-table class="text-left">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{$t('phase')}}
                </th>
                <th class="text-left">
                  {{$t('dateSubmitted')}}
                </th>
                <th class="text-left">
                </th>
              </tr>
            </thead>
            <tbody>
            <template
                v-for="item in parsedAssessments">
              <tr >
                <td>{{ item.selectedPhase }}   {{item.isPhaseComplete ? 'Completado' : ''}}</td>
                <td>
<!--                  <Time v-if="item.createdAt" :time="item.createdAt" />-->

                  <Time v-if="item.technicalVisitType !== 'review'" :time="item.fields.register.visitDate.answer" format="d MMM yyyy" />
                </td>


                <td v-if="item.technicalVisitType === 'review'"  >
                  esta es una reseña

                </td>



                <td class="pa-0" v-else>
                  <v-btn
                      color="primary"
                      small
                      dense
                      :to="{name: 'TechnicalVisitView', query: { uuid: customerUuid,fieldUuid: field.uuid, assessment:  item.assessmentUuid} }"
                      class="px-0 my-2"
                  >

                    <div class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                      Hacer una revisión
                    </div>
                  </v-btn>
                  <v-btn
                      color="primary"
                      small
                      dense
                      :to="{name: 'FieldAssessment', query: { assessmentUuid: item.assessmentUuid} }"
                      class="px-0 my-2"
                  >

                    <div class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                      {{$t('viewMoreDetails')}}
                    </div>
                  </v-btn>
                  <div v-if="reviewAssessments">
                  </div>

                </td>
              </tr>



              <template v-if="!!reviewAssessments[item.assessmentUuid]">
                <tr v-for="review in reviewAssessments[item.assessmentUuid]">
                  <td  >
                    Esta es una reseña

                  </td>



                  <td>
                    <Time v-if="review.fields.visitDate" :time="review.fields.visitDate.answer" format="d MMM yyyy" />

                  </td>


                  <td  >
                    <v-btn
                        color="primary"
                        small
                        dense
                        :to="{name: 'ExtraObservationAssessmentPrint', query: { assessmentUuid: review.assessmentUuid} }"
                        class="px-0 my-2"
                    >

                      <div class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                        {{$t('viewMoreDetails')}}
                      </div>
                    </v-btn>
                  </td>


                </tr>
              </template>


            </template>

            </tbody>
          </template>
        </v-simple-table>
        </v-card>
      </div>

  </template>

  <script>

    import Time from "@/components/Time.vue";

    export default {
      props: ['assessments'],
      components: {
        Time
      },

      data() {
        return {

        }

      },
      computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      field() { return this.customer.fields.filter( f => f.uuid==this.fieldUuid )[0]; },
      parsedAssessments() {
        return this.assessments.filter(assessment => {
          return assessment.assessmentName === 'technicalVisit'
        }).sort((a, b) => {
          const aDate = new Date(a.fields.register.visitDate.answer)
          const bDate = new Date(b.fields.register.visitDate.answer)
          return aDate  - bDate
        })
        },
        reviewAssessments() {
        // todo make object key as original
          let tempReviews = this.assessments.filter(assessment => {
            return assessment.technicalVisitType === 'review'
          })
          const reviewObjects = {}
          tempReviews.forEach(r => {

            if (reviewObjects[r.initialTechnicalVisitUuid]) {
              reviewObjects[r.initialTechnicalVisitUuid].push(r)
            } else {
              reviewObjects[r.initialTechnicalVisitUuid] = [r]
            }
          })
          return reviewObjects
        }
      },
    }

  </script>

<i18n>
    {
      "en": {
        "notes": "Notes",
        "viewMoreDetails": "view more details",
        "submittedForm": "Submitted Form"
      },
      "es": {
        "notes": "Notas",
        "viewMoreDetails": "ver más detalles",
        "submittedForm": "Formulario Enviado"
      },
      "sw": {
        "notes": "Maelezo",
        "viewMoreDetails": "view more details",
        "submittedForm": "Fomu Iliyowasilishwa"
      }
    }
    </i18n>
