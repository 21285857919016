<template>
  <v-stepper-content step="2" class="ml-0">
    <v-form ref="form4" v-model="valid" class="text-left" >
      <v-container fluid>
        <v-row v-if="!!customer">
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.acreageOwned" :items="acreageOptions" label="Acreage Owned"  suffix="acres"/>
          </v-col>
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.acreageRented" :items="acreageOptions" label="Acreage Rented"  suffix="acres"/>
          </v-col>
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.cultivatedCoffee" :items="cultivatedOptions" label="Cultivated With Coffee" type="number" suffix="acres" :rules="cultivationRules"/>
          </v-col>
          <v-col cols="12" md="4">
            <TextInput v-model="customer.coffeePlots" required label="Coffee Plots" type="number" suffix="plots" :rules="maxPlots" />
          </v-col>
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.coffeeType" :items="['Arabica', 'Robusta']" label="Coffee Type" multiple/>
          </v-col>
          <v-col cols="12" md="4">
            <TextInput v-model="customer.arabicaTrees" required label="Arabica Trees" type="number" suffix="trees" :rules="maxTrees" />
          </v-col>
          <v-col cols="12" md="4">
            <TextInput v-model="customer.robustaTrees" required label="Robusta Trees" type="number" suffix="trees" :rules="maxTrees" />
          </v-col>
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.agricultureExperience" :items="years" label="Years Farming" />
          </v-col>
          <v-col cols="12" md="4">
            <SelectInput v-model="customer.yearsAsSupplier" :items="years" label="Years supplying Co op" />
          </v-col>
        </v-row>
      
        <div class="text-left text-caption" :class="{ 'red--text': !valid }">Previous harvest delivered to co-op</div>
        <v-simple-table v-if="seasons.length>0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Year
                </th>
                <th class="text-left">
                  Season
                </th>
                <th class="text-left">
                  Harvest (80kg bags)
                </th>
                <th class="text-left">
                  Land (acres)
                </th>
                <th class="text-left">
                  Coffee Type
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in seasons" >
                <td>
                  <v-icon @click="removeSeason(index)" dense>mdi-delete</v-icon>
                  {{item.year}}
                </td>
                <td>
                  {{item.season}}
                </td>
                <td>
                  <TextInput
                    type="number"
                    :min="0"
                    :rules="[rules.required]"
                    v-model="item.harvest"
                  />
                </td>
                <td>
                  <TextInput
                    type="number"
                    :min="0"
                    :rules="[rules.required]"
                    v-model="item.land"
                  />
                </td>
                <td>
                  <SelectInput v-model="item.coffeeType" :items="coffeeTypes"/>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="text-left d-flex">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
              >
                Add year
                <v-icon dense>mdi-chevron-down</v-icon>

              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in seasonYears"
                :key="index"
              >
                <v-list-item-title
                    @click="addSeason(item)"
                    role="button"
                >
                  {{ item }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-row class="my-4">
          <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndContinue" :disabled="!valid">Save & Continue</v-btn>
          <v-btn color="gray darken-3" class="mb-2" @click="$emit('previousStep');">Back</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import {summarizeSeasonHarvestTableResults, generatePastFourYears} from "@/views/partners/nile_ug/components/utils";
import SelectInput from '@/components/SelectInput';
import TextInput from "@/components/TextInput";

export default {

  components: { TextInput, SelectInput },

  props: {
    value: Object,
  },

  data() {
    return {
      valid: false,
      customer: undefined,

      seasons: [],
      allYears: generatePastFourYears(),
      selectedYears: null,
      coffeeTypes: ['Red Cherries', 'Kiboko', 'FAQ/Kase', 'Parchment'],
      acreageOptions: [0,0.5,1,1.5,2,2.5,3,3.5,4,4.5,5,6,7,8,9,10,'11-15','16-20','21-30','31-40'],
      years: ['0','1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'],
      cultivationRules: [v => v <= (this.cultivated) || 'Acreage cultivated should be less than owned + rented acreage'],
      maxPlots: [ v => ( ((v || '')) && (parseInt(v)>=0) && (parseInt(v) <= 20) ) || 'Between 0 and 20' ],
      maxTrees: [ v => ( ((v || '')) && (parseInt(v)>=0) && (parseInt(v) <= 50000) ) || 'Between 0 and 10000' ],

      rules: {
        required: (value) => (value===0 || !!value) || "Required",
      }

    };
  },

  computed: {
    seasonYears () { return this.allYears },
    tableResults () { return summarizeSeasonHarvestTableResults(this.seasons) },
    tableResultsValid () {
      // let isValid = true
      // if (!this.tableResults) {
      //   return false
      // }

      // Object.values(this.tableResults).forEach(tableRow => {
      //   if (tableRow.length !== 2) {
      //     isValid = false
      //   }
      // })
      // return isValid
      return true
    },
    areAllRulesValid() {
      return this.valid && this.tableResultsValid
    },
    cultivated() { return ( (this.customer.acreageOwned || 0) + (this.customer.acreageRented || 0) ) },
    cultivatedOptions() {
      if (typeof this.cultivated === 'string' || this.cultivated instanceof String) {
        return [0,0.5,1,2,3,4,5,6,7,8,9,10].concat(this.acreageOptions.filter( e => e <= this.cultivated))
      }
      return this.acreageOptions.filter( e => e <= this.cultivated);
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.customer = to; }
    },
    customer(to) {
      this.$emit('input', to);
    },
    valid(to) { this.$emit('valid', to); },
    seasons(to) { 
      this.customer.seasons = to
     },
  },


  methods: {
    addSeason(selectedYear) {
      const selectedYearResults = this.seasons.filter(formField => formField.year === selectedYear)

      if (selectedYearResults.length >= 2) { return }

      if (!selectedYearResults.filter(formField =>  formField.season === 'B').length) {
        this.seasons.push({
          year: selectedYear,
          season: 'Fly',
          harvest: '0',
          land: '0'
        })
      }

      if (!selectedYearResults.filter(formField =>  formField.season === 'A').length) {
        this.seasons.push({
          year: selectedYear,
          season: 'Main',
          harvest: '0',
          land: '0'
        })
      }
    },
    removeSeason(itemPosition) {
      const tempFields = Object.assign(this.seasons, [])
      this.seasons = tempFields.filter((item, index) => index !== itemPosition)
    },
    validate() {
      this.$refs.form4.validate();
    },
    validateAndContinue(){
      this.validate();
      this.$emit('nextStep');
    }
  },

}
</script>