<template>
  <v-card>
    <v-card-title>
      {{$t('fields')}}
      <v-spacer />
      <v-btn color="primary" small dense @click="toNewField">{{$t('new')}}</v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="fieldRow in fieldRows" :key="fieldRow.field.id" :title="fieldRow.field.name" >
              <td class="text-left">
                {{ crops[fieldRow.field.cropId] }}
                <span v-if="fieldRow.field.partner_identifier" class="text-left hidden-md-and-up">({{ fieldRow.field.partner_identifier }})</span>
              </td>
              <td class="text-left"><code>{{ fieldRow.fieldCode }}</code></td>
              <td class="text-left"><code v-if="fieldRow.field.partner_identifier">{{ fieldRow.field.partner_identifier }}</code></td>
              <td class="text-left">{{ fieldLandSize(fieldRow.field) }} <small>{{ fieldLandSizeUnit(fieldRow.field) }}</small></td>
              <td class="text-right">
                <v-btn color="primary" text :to="{name: 'Field', query: { uuid: customer.uuid, fieldUuid: fieldRow.field.uuid } }" class="ml-1">
                  <v-icon dense>mdi-flower-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

  </v-card>
</template>


<script>
  import fields from '@/mixins/fields';

  import Row from '@/components/Row';
  import Time from '@/components/Time';
  import AssessButton from '@/views/fields/components/AssessButton';
  import {createFieldRows} from "@/views/customers/components/utils";

  export default {

    components: { Row, Time, AssessButton },

    props: ['customer'],

    computed: {
      fields() { return (this.customer.fields || []).filter(field => field.deleted===undefined ) ; },
      crops() { return this.$store.getters.cropLookups; },
      fieldRows() {
        const customerCultivationContracts = this.$store.getters.assessments.filter(assessment =>
            assessment.assessmentName === 'cultivationContract' &&
            assessment.customerUuid === this.customer.uuid
        )

        return createFieldRows(this.fields, customerCultivationContracts)
      }
    },

    methods: {
      toNewField() { this.$router.push({name: 'FieldEdit', query: { uuid: this.$route.query.uuid, fieldUuid: 'new' }}); },
    },

    mixins: [fields],

  }
</script>


<i18n>
{
  "en": {
    "fields": "Fields"
  },
  "es": {
    "fields": "Cultivos"
  },
  "sw": {
    "fields": "Mashamba"
  }
}
</i18n>
