<template>
      <v-container fluid  class="green lighten-5">
        <v-row class="text-center">
          <v-card-title>Plantulas</v-card-title>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <DateInput
                v-model="data.register.receptionDateOfSeedlings.answer"
                :label="$t('1 ¿Fecha recepción plántulas?')"
                :rules="[rules.required]"
                required
                :hint="$t('¿Fecha recepción plántulas?')"/>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="data.register.seedlingsReceived.answer"
              type="number"
              :label="$t('2 ¿Cuantas plántulas recibió?')"
              min="0"
              :rules="[rules.required, ...rules.numberRules]"
              :hint="$t('¿Cuantas plántulas recibió?')"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-combobox
              v-model="data.register.fromWhichNursery.answer"
              :items="['VIVEROS LUZ','VIVERO CAUCA']"
              :label="$t('3 ¿Vivero de procedencia?')"
              single-line
              :rules="[rules.required]"
            ></v-combobox>
          </v-col>

          <v-col cols="12" sm="6">
            <v-combobox
              v-model="data.register.typeOfTrays.answer"
              :label="$t('4 ¿Tipo de Bandejas - Cantidad Alveolos?')"
              single-line
              :items="[50,72,128,162,200]"
              :rules="[rules.required]"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row >
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="data.register.phytoSanitaryStatusOfSeedlings.answer"
              type="text"
              :label="$t('5 ¿Estado fitosanitario de las plántulas al momento de recepción?')"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="data.register.howManyDaysYouHardenTheSeedlings.answer"
              type="text"
              :label="$t('6 ¿Cuanto tiempo aclimató sus plántulas?')"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-combobox
                v-model="data.register.neccessaryToCarryOutPreviousDisinfectionBeforeTranspalnting.answer"
                :label="$t('7 ¿Es necesario realizar desinfección previa antes del trasplante.')"
                single-line
                :rules="[rules.required]"
            ></v-combobox>
          </v-col>

          <v-col cols="12" sm="6">
            <v-radio-group
              v-model="data.register.areYouReadyForPlanting.answer"
              row
              mandatory
              :label="$t('8 ¿Está listo para transplantar?')"
              :rules="[rules.required]"
            >
              <v-radio :label="$t('Yes')" value="Sí"></v-radio>
              <v-radio :label="$t('No')" value="No"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
           <TakePhoto :text="'Foto Plántulas'">
              <v-btn color="primary" small dense class="ml-1"
                ><v-icon dense class="mr-1">mdi-camera-outline</v-icon>
                <span class="hidden-sm-and-down">{{
                  $t("Foto Plántulas")
                }}</span></v-btn
              >
            </TakePhoto>
          </v-col>
        </v-row>

      </v-container>
</template>

<script>
import DateInput from "@/components/DateInput";
import TakePhoto from "@/components/TakePhoto"
export default {
    components: {
      DateInput,
      TakePhoto
    },
    props :{
        data: Object,
    },
  data() {
    return {
      rules: {
      required: (value) => !!value || "Requerido",
      numberRules: [
        (v) => Number.isInteger(Number(v)) || "Numbers only",
        (v) => Number(v) >= 0 || "A positive number please",
        (v) => !/\D/.test(v) || "Numbers please",
      ],
      },
    }
  },
  computed: {
    isPhaseSectionValid() {
      return this.areFormFieldsFilled(Object.values(this.data))
    }
  },
}
</script>


<style>

</style>

<i18n>
{
  "en": {
    "Register":"Register",
    "Field Contract Register": "Field Contract Register",
    "2 Reception date of seedlings": "Reception date of seedlings",
    "3 How Many seedlings did you receive?":"How Many seedlings did you receive?",
    "4 From which Nursery?":"From which Nursery?",
    "5 Type of Trays?": "Type of Trays?",
    "6 Phytosanitary status of the seedlings?":"Phytosanitary status of the seedlings?",
    "7 How Many days you harden the seedlings?":"How Many days you harden the seedlings?",
    "8 Are you ready for planting?":"Are you ready for planting?",
    "Photos":"Photos",
    "Learning Material":"Learning Material"
  },
  "es": {
    "Register":"Registro",
    "Field Contract Register": "CONTRATO CULTIVO",
    "2 Reception date of seedlings": "¿Fecha recepción plántulas?",
    "3 How Many seedlings did you receive?":"How Many seedlings did you receive?",
    "4 From which Nursery?":"F¿Vivero de procedencia?",
    "5 Type of Trays?": "¿Tipo de Bandejas - Cantidad Alveolos?",
    "6 Phytosanitary status of the seedlings?":"¿Estado fitosanitario de las plántulas al momento de recepción?",
    "7 How Many days you harden the seedlings?":"¿Cuanto tiempo aclimató sus plántulas?",
    "8 Are you ready for planting?":"¿Está listo para transplantar?",
    "Photos":"Foto Plántulas",
    "Learning Material":"Instructivos Transplante",
    "Yes": "Si",
    "No": "No",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar"

  },
  "sw": {
    "Register":"Register",
    "Field Contract Register": "MKATABA WA MAZAO",
    "Reception date of seedlings": "Tarehe ya kuzaliwa",
    "How Many seedlings did you receive?":"How Many seedlings did you receive?",
    "From which Nursery?":"From which Nursery?",
    "Type of Trays?": "Type of Trays?",
    "Phytosanitary status of the seedlings?":"Phytosanitary status of the seedlings?",
    "How Many days you harden the seedlings?":"How Many days you harden the seedlings?",
    "Are you ready for planting?":"Are you ready for planting?",
    "Photos":"Photos",
    "Yes": "Ndio",
    "No": "La",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa"
  }
}
</i18n>
