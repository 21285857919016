<template>
  <div>
    <Stepper :tabs="tabs" :captions="captions" :complete="complete" @position="stepChange($event)" ref="stepper" vertical>

      <template v-slot:BasicInfo>
        <BasicInfo v-model="customer" ref="basicInfoStep" @valid="validity['BasicInfo']=$event" @nextStep="nextStep" />
      </template>

      <template v-slot:Harvest>
        <Harvest v-model="customer" ref="harvestStep" @valid="validity['Harvest']=$event" @nextStep="nextStep" @previousStep="previousStep" />
      </template>

      <template v-slot:OTP>
        <OTP v-model="customer" ref="otpStep" @valid="validity['OTP']=$event" @nextStep="nextStep" @previousStep="previousStep" />
      </template>

    </Stepper>

    <v-row class="mx-4 my-4">
      <v-btn color="primary" class="mr-3 mb-2 d-xs-block" :disabled="!valid" @click="saveAndDone">Submit</v-btn>
      <v-btn color="gray darken-3" class="mb-2" @click="concludeLater">Conclude Later</v-btn>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="2000"
      color="green"
      elevation="24"
    >
      You have successfully onboarded the farmer
    </v-snackbar>
  </div>
</template>

<script>
import Stepper from "@/components/Stepper";
import BasicInfo from "@/views/partners/nucafe_ug/onboarding/BasicInfo";
import Harvest from "@/views/partners/nucafe_ug/onboarding/Harvest";
import OTP from "@/views/partners/nucafe_ug/onboarding/OTP";

export default {

  components: {
    Stepper,
    BasicInfo,
    Harvest,
    OTP
  },

  data () {
    return {
      customer: undefined,
      customerUpdated: true,
      snackbar: false,
      tabs: ['BasicInfo', 'Harvest', 'OTP'],
      captions: { 'BasicInfo': 'Basic Details', 'Harvest': 'Crop Details', 'OTP': 'OTP Acknowledgement' },
      validity: { 'BasicInfo': false, 'Harvest': false, 'OTP': false }, // have to do this because of reactivity
      firstStep: true,
      lastStep: false,
    }
  },

  computed: {
    // disabled() { return []; },
    complete() { return Object.entries(this.validity).filter(e => e[1]).map(e => e[0]); },
    invalid() { return this.tabs.filter(e => (this.validity[e]===false) ); },
    // error() { return (this.validity['Duplicates'] ? [] : ['Duplicates']); },
    valid() { return this.invalid.length==0; },
  },

  watch: {
    step() { this.save(); },
    customer: {
      deep: true,
      handler(to) {
        this.customerUpdated = true;
      }
    },
  },

  methods: {

    stepChange(to) {
      this.firstStep = (to=='first');
      this.lastStep = (to=='last');
    },

    nextStep() {
      this.save();
      this.$refs.stepper.nextStep();
    },

    previousStep() {
      this.save();
      this.$refs.stepper.previousStep();
    },

    validate() {
      this.$refs.basicInfoStep.validate();
      this.$refs.harvestStep.validate();
      this.$refs.otpStep.validate();
    },

    concludeLater() {
      this.save();
      this.$router.push({ name: 'OnboardingInProgress' });
    },

    saveAndDone() {
      this.customer.customer_at = new Date();
      this.save();
      this.snackbar = true;
    },

    save() {
      if (this.customerUpdated) {
        this.customerUpdated = false;
        this.$store.dispatch('upsertCustomer', this.customer);
      }
    },

  },

  mounted() {
    const uuid = this.$route.query.uuid;
    this.customer = this.$store.getters.customer(uuid);
    this.customer.demographic = this.customer.demographic || {};
    this.customer.economic = this.customer.economic || {};
    this.customer.economic.payload = this.customer.economic.payload || {};
    this.customer.nationality = this.customer.nationality || 'UG'

    let _this = this;
    setTimeout(function () { _this.validate(); }, 200)
  },

}
</script>

<style scoped>

</style>
