<template>
  <v-input :rules="[requiredRule]" v-model="output" >
    <SelectInput v-model="yyyy"  :items="years"  label="Year"  class="pb-0" :disabled="disabled"                    v-if="dateOrder=='yymmdd'" />
    <SelectInput v-model="month" :items="months" label="Month" class="pb-0" :disabled="disabled"                    v-if="dateOrder=='yymmdd'" />
    <SelectInput v-model="day"   :items="days"   label="Date"  class="pb-0" :disabled="disabled || days.length==0"  v-if="dateOrder=='yymmdd'" />
  </v-input>
</template>

<script>
  import parseDate from '@/mixins/parseDate';
  import SelectInput from '@/components/SelectInput';

  export default {

    components: { SelectInput },

    props: {
      value: [String, Date],
      required: Boolean,
      rules: Array,
      label: String,
      min: String,
      max: String,
      year: Boolean,
      disabled: Boolean,
      hint: String,
      singleYear: Number,
    },

    data: () => ({
      date: undefined,
      yyyy: undefined,
      month: undefined,
      day: undefined,

      result: undefined,

      months: [
        { value:  1, text: 'Jan' },
        { value:  2, text: 'Feb' },
        { value:  3, text: 'Mar' },
        { value:  4, text: 'Apr' },
        { value:  5, text: 'May' },
        { value:  6, text: 'Jun' },
        { value:  7, text: 'Jul' },
        { value:  8, text: 'Aug' },
        { value:  9, text: 'Sep' },
        { value: 10, text: 'Oct' },
        { value: 11, text: 'Nov' },
        { value: 12, text: 'Dec' },
      ],
    }),

    computed: {
      output() {
        let r = undefined;
        if (this.yyyy && this.month && this.day) {
          if (this.days.includes(this.day)) {
            r = `${this.yyyy}-${String(this.month).padStart(2,'0')}-${String(this.day).padStart(2,'0')}`;
          }
        }
        return r;
      },
      allRules() { return (!!this.rules ? [this.requiredRule, ...this.rules] : [this.requiredRule]); },
      countryCode() { return this.$store.getters.settings.countryCode; },
      maxDate() { return this.getDate(this.max) },
      minDate() { return this.getDate(this.min) },
      years() {
        let y = [...Array(120).keys()].map( e => (new Date).getFullYear()-e+10 );
        return y.filter( e => e>(this.minDate || 1900) && e<(this.maxDate || 2040) && e==(this.singleYear || e) );
      },
      days() {
        let r = [];
        if ([9, 4, 6, 11].includes(this.month)) {
          r = Array.from({length: 30}, (_, i) => i + 1)
        } else if ([2].includes(this.month)) {
          r = Array.from({length: 29}, (_, i) => i + 1)
        } else if ([1, 3, 5, 7, 8, 10, 12].includes(this.month)) {
          r = Array.from({length: 31}, (_, i) => i + 1)
        }
        return r;
      },
      dateOrder() {
        let r = 'ddmmyy';
        if (this.countryCode=='UG') { r = 'yymmdd' };
        return r;
      },
    },

    watch: {
      value: {
        immediate: true,
        handler(to) { this.date = this.getDate(to); },
      },
      date(to) {
        if (to) {
          this.yyyy = to.year;
          this.month = to.month;
          this.day = to.day;
        }
      },
      output(to) {
        this.$emit('input', to);
      },
      years(to) {
        if ((to || []).length==1) {
          this.yyyy = to[0];
        }
      }
    },

    methods: {
      requiredRule(value) {
        let r = [
          v => !this.required || !!((v || '').trim()) || this.$t('required'),
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
      getDate(t) { return this.parseDateTime(t); },
    },
    mixins: [parseDate],
  };
</script>


<i18n>
{
  "en": {
    "year": "Year",
    "month": "Month",
    "day": "Day"
  },
  "es": {
    "year": "Año",
    "month": "Mes",
    "day": "Día"
  },
  "sw": {
    "year": "Mwaka",
    "month": "Mwezi",
    "day": "Siku"
  }
}
</i18n>

