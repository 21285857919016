<template>
  <v-container fluid class="pa-0">
    
    <v-row no-gutters v-if="customer">
      <v-col cols="12">
        <CustomerDetail :customer="customer" />
      </v-col>
    </v-row>

    <v-row no-gutters v-else>
      <v-col cols="12">
        <v-skeleton-loader type="list-item-avatar-three-line, article" />
      </v-col>
    </v-row>

  </v-container>
</template>


<script>

  import CustomerDetail from '@/views/customers/components/CustomerDetail';

  export default {

    components: {
      CustomerDetail,
    },

    props: ['uuid'],

    data: () => ({
    }),

    computed: {
      customer() { return this.$store.getters.customer(this.uuid); },
    },

    watch: { 
      uuid: {
        immediate: true,
        handler(to) {
          this.$store.dispatch('addCustomersToCache', [to]);
        },
      },
    }

    // methods: {
    //   submit(farmer) {
    //     this.$store.dispatch('upsertFarmer', farmer);
    //   }
    // }

  }
</script>
