<template>
  
  <v-container fluid>

    <v-row>
      <v-col cols="12" class="text-h3 text--primary text-left">
        <span v-if="status=='capture'">                Cashbox: Move Cash                                   </span>
        <span v-if="status=='authorise'">              Move <Currency v-model="amount" /> to {{who}}        </span>
        <span v-if="status=='authorised'">             Moving....                                           </span>
        <span v-if="status=='done'">                   <Currency v-model="amount" /> moved to {{who}}       </span>
      </v-col>
    </v-row>

    <div v-if="status=='capture'">         <Capture    v-model="parameters"      @submit="status='authorise'"  />   </div>
    <div v-if="status=='authorise'">       <Authorise                            @input="status='authorised'"  />   </div>
    <div v-if="status=='authorised'">      <Move       :parameters="parameters"  @done="status='done'"         />   </div>
    <div v-if="status=='done'" class="text-h4">
      Thank you. The transaction has been recorded.
    </div>

  </v-container>

</template>

<script>
import Capture from '@/views/partners/ultimate_tz/cashbox/Capture'
import Authorise from '@/views/partners/ultimate_tz/cashbox/Authorise'
import Move from '@/views/partners/ultimate_tz/cashbox/Move'

import Currency from "@/components/Currency";

export default {

  components: { Capture, Authorise, Move, Currency },

  data() {
    return {
      parameters: undefined,
      status: 'capture',
    }
  },

  computed: {
    amount() { return (this.parameters ? this.parameters.cash : 0 ); },
    who() {
      let r = 'Safe Box';
      if (this.parameters && this.parameters.target=='consultant') { r =  this.parameters.recipient.name; }
      return r;
    },
  },

  watch: {
    parameters(to) {
      if (to && this.status=='initialised') {
        this.status = 'authorise';
      }
    },
  },

}
</script>
