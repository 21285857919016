<template>
  <v-form :disabled="false" class="text-left" v-model="valid">
    <div class=" pb-4 d-md-flex justify-space-between">
      <slot name="title">
        <div class="subtitle-2 text-left">Observaciones adicionales</div>

      </slot>

      <v-btn
          color="primary lighten-2"
          text
          @click="addNewInputGroup()"
      >Nuevo campo de formulario</v-btn>

    </div>
    <div style="width: 100%; height: 270px;">
      <v-row class="green lighten-5">
        <v-col cols="12" md="4" v-for="(extraObservation, index) in allExtraObservationInputs" :key="index">
          <div style="width: 100%">
            <div class="d-md-flex">
              <v-text-field
                  :label="$t('Título')"
                  v-model="extraObservation.title"
                  required
                  :rules="[sharedValidations.requiredValidation]"
                  clearable
              ></v-text-field>
                <v-btn
                @click="deleteFormItem(index)" >
                  Borrar
                  <v-icon small>mdi-close</v-icon>
              </v-btn>
            </div>

            <v-textarea
                :label="$t('Descripción')"
                v-model="extraObservation.description"
                :rules="[sharedValidations.requiredValidation]"
            >
              v-model="extraObservation.description"
            </v-textarea>

          </div>

        </v-col>
      </v-row>
    </div>
  </v-form>

</template>

<script>

import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";

export default {
  props: ['value'],
  data() {
    return {
      sharedValidations: new HugoValidations(),
      valid: false,
      allExtraObservationInputs: [
        {
          title: '',
          description: ''
        }
      ]
    }
  },
  watch: {
    value: {
      immediate: true,
      deep:true,
      handler(to) {
        if (!to) {
        } else {
          this.allExtraObservationInputs = to;
        }
      }
    },
    allExtraObservationInputs:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },

    valid:{
      handler(to) {
        this.$emit('onValidChange', to);
      },
    },
  },
  methods: {
    addNewInputGroup() {
      this.allExtraObservationInputs.push({
        title: '',
        description: ''
      })
    },
    deleteFormItem(index) {
      this.allExtraObservationInputs.splice(index, 1)
    },
  },
  computed: {

    formValid: function() {
      return this.valid
    }
  }

}
</script>

<style scoped>

</style>
