<template>
  <PageHeader title="Approvals" icon="mdi-check-all">
    <RefreshableListing :approvals="approvals" @refresh="loadApprovals" />
  </PageHeader>
</template>


<script>
  import lookups from '@/mixins/lookups';

  import PageHeader from "@/components/PageHeader";
  import ServerSideResult from '@/components/ServerSideResult';

  import RefreshableListing from '@/views/approvals/RefreshableListing';

  export default {

    components: { PageHeader, ServerSideResult, RefreshableListing },

    data: () => ({
      approvals: undefined,
    }),
    
    methods: {
      loadApprovals() {
        this.remoteLookup('approvals', {}).then( approvals => this.approvals = approvals.approvals ).catch(e => this.approvals = null);
      }
    },

    mixins: [lookups],

  }
</script>
