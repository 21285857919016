<template>

  <div>
    <TopupSalaryAdvance :customer="customer" :instrumentId="instrumentId" v-if="instrumentType=='InstrumentUltimateTzSalaryAdvance'" />
    <TopupCheckMeBack :customer="customer" :instrumentId="instrumentId" v-if="instrumentType=='InstrumentUltimateTzCheckMeBack'" />
  </div>

</template>

<script>
import TopupSalaryAdvance from "@/views/partners/ultimate_tz/TopupSalaryAdvance"
import TopupCheckMeBack from "@/views/partners/ultimate_tz/TopupCheckMeBack"

export default {

  components: { TopupSalaryAdvance, TopupCheckMeBack },

  computed: {
    uuid() { return this.$route.query.uuid; },
    instrumentId() { return this.$route.query.instrumentId; },
    instrumentType() { return this.$route.query.instrumentType; },
    customer() { return this.$store.getters.customer(this.uuid); },
  },

}
</script>
