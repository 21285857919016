<template>
  <v-card>
    <SingleImage v-if="firstVisitImage" :image="firstVisitImage" :height="250" />
    <v-card-title>
      {{$t('title')}}
      <v-spacer />
      <v-btn color="primary" small dense @click="editFirstVisit"  v-if="firstVisit" >{{$t('edit')}}</v-btn>
    </v-card-title>

<!--    <v-btn >-->
<!--      upsert assessments-->
<!--    </v-btn>-->
    <v-card-text>
      <v-simple-table v-if="anyFields" >
        <template v-slot:default>
          <tbody>
            <Row :title="$t('farmName')"     :text="farmName"   v-if="farmName"  data-cy="farmName"/>
            <Row :title="$t('contactType')" :status="customer.hrCoContactType"   v-if="customer.hrCoContactType" />
            <Row :title="$t('fieldAgent')"   :text="fieldAgent" v-if="fieldAgent" />
            <Row :title="$t('depot')"        :text="depotName"  v-if="depotName" />
            <Row :title="$t('landSize')" v-if="customer.landSize" >
              <LandSize :landSize="customer.landSize" />
            </Row>
            <Row :title="$t('totalRating')" :text="firstVisitTotalScore"  v-if="firstVisit" />


            <Row :title="$t('firstVisit.title')">
              <div v-if="!!firstVisit">
                <v-btn
                    small
                    dense
                    :to="{name: 'ViewFirstVisit', query: { firstVisitUuid: firstVisit.assessmentUuid }}"
                    class="text-left my-2" style="height: auto"
                >
                  <span class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                    {{$t('firstVisit.viewFirstVisit')}}
                  </span>
                </v-btn>
              </div>

              <v-btn
                  v-else
                  color="success"
                  small
                  dense
                  :to="{name: 'HRFirstVisit', query: { uuid: customer.uuid }}"
                  class="text-left"
                  style="height: auto"
              >
                <span class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                  {{$t('firstVisit.startFirstVisit')}}
                </span>
              </v-btn>
            </Row>
            <Row :title="$t('hr.cultivationContractDate')" v-if="!!cultivationContractAssessment" >
              <Time :time="cultivationContractAssessment.formFields.contractDate.answer" format="date" />
            </Row>

            <Row :title="$t('cultivationContract.title')" v-if="false">
              <div v-if="!!cultivationContractAssessment">
                <v-btn
                    small
                    dense
                    :to="{name: 'ShowFieldContract', query: {
                      assessmentUuid: cultivationContractAssessment.assessmentUuid,
                      customerUuid: customer.uuid
                       }}"
                    class="text-left my-2" style="height: auto"
                >
                  <span class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                    {{$t('cultivationContract.viewCultivationContract')}}
                  </span>
                </v-btn>
                <v-btn
                    small
                    dense
                    @click="deleteCultivationContractAssessment(cultivationContractAssessment.assessmentUuid)"
                    class="text-left my-2" style="height: auto"
                >
                  <span class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                    {{$t('delete assessment')}}
                  </span>
                </v-btn>
              </div>

              <v-btn
                  color="success"
                  v-else
                  small
                  dense
                  :to="{name: 'FieldContractRegister', query: { uuid: customer.uuid }}"
                  class="text-left my-2"
                  style="height: auto"
              >
                <div class="text-center text-wrap" style="width: 120px; padding: 6px 0">
                  {{$t('cultivationContract.startCultivationContract')}}
                </div>
              </v-btn>

            </Row>
          </tbody>
        </template>
      </v-simple-table>
      <div v-else>
        {{$t('noDetails')}}
      </div>
    </v-card-text>

  </v-card>
</template>


<script>

  import Row from '@/components/Row';
  import LandSize from '@/components/LandSize';
  import SingleImage from "@/components/SingleImage";
  import Time from "@/components/Time";
  import ImageUploadTypeEnums from "@/mixins/ImageUploadTypeEnums";
  import firstVisitImageMixin from "@/mixins/firstVisitImageMixin";

  export default {

    components: { Row, LandSize, SingleImage, Time },

    props: ['customer', 'firstVisit', 'cultivationContractAssessment'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      farmName()   { return (this.customer.farm ? this.customer.farm.name : null) },
      fieldAgent() { return (this.customer.fieldAgent ? this.customer.fieldAgent.name : null) },
      depotName() { return (this.customer.depot ? this.customer.depot.name : null) },

      anyFields() { return (this.fieldAgent || this.depotName || this.customer.landSize || this.customer.primaryCrop); },
      firstVisitImage() {
        if (!this.firstVisit) {
            return null
        }
        const storedImage = this.firstVisit.farmImage

        if (storedImage) {
          return this.$store.getters.images.reverse().find(
              (e) => e.uuid === storedImage.uuid
          );

        } else {
          return this.$store.getters.images.reverse().find(
              (e) => e.type_id === this.customer.farm.uuid && e.type === this.imageUploadTypeEnums.firstVisit
          );

        }
      },
      firstVisitTotalScore() {
        // todo reusable function
        return (this.firstVisit.ratingFormFields.ratingScore.totalAnswersWeight * 5 / this.firstVisit.ratingFormFields.ratingScore.totalWeight).toFixed(1)
      },
      assessments() {
        return this.$store.getters.assessments
      }
    },
    methods: {
      edit() {
        this.$router.push({name: 'EditFarmer', query: { uuid: this.customerUuid }});
      },
      editFirstVisit() {
        this.$router.push({name: 'HRFirstVisit', query: { uuid: this.customerUuid, firstVisitUuid: this.firstVisit.assessmentUuid }});
      },
      deleteCultivationContractAssessment(cultivationContractAssessmentUuid) {
        const foundItem = this.assessments.filter(assessment => assessment.assessmentUuid !== cultivationContractAssessmentUuid)
        this.$store.commit('setAssessments', foundItem);
        },
    },
    mixins: [ImageUploadTypeEnums]
  }
</script>

<i18n>
{
  "en": {
    "title": "Agriculture",
    "farmName": "Farm Name",
    "contactType": "Contact Type",
    "primaryCrop": "Primary Crop",
    "noDetails": "No details yet.",
    "firstVisit": {
      "title": "First Visit",
      "viewFirstVisit": "View First Visit",
      "startFirstVisit": "start First Visit"
    },
    "cultivationContract": {
      "title": "Cultivation Contract",
      "viewCultivationContract": "View cultivation contract",
      "startCultivationContract": "Start cultivation contract"
    }
  },
  "es": {
    "title": "Finca",
    "farmName": "Finca",
    "contactType": "Tipo de Contacto",
    "primaryCrop": "Variedad",
    "noDetails": "Aún no hay detalles.",
    "firstVisit": {
      "title": "Primera Visita",
      "viewFirstVisit": "ver primera visita",
      "startFirstVisit": "iniciar la primera visita"
    },
    "cultivationContract": {
      "title": "Contrato Cultivo",
      "viewCultivationContract": "Ver contrato de cultivo",
      "startCultivationContract": "Iniciar contrato de cultivo"
    }
  },
  "sw": {
    "title": "Kilimo",
    "farmName": "Jina la Shamba",
    "contactType": "Aina ya Mawasiliano",
    "primaryCrop": "Mazao ya Msingi",
    "noDetails": "Hakuna maelezo bado.",
    "firstVisit": {
      "title": "ziara ya kwanza",
      "viewFirstVisit": "angalia ziara ya kwanza",
      "startFirstVisit": "Anza ziara ya kwanza"
    },
    "cultivationContract": {
      "title": "Mkataba wa Kilimo",
      "viewCultivationContract": "angalia Mkataba wa Kilimo",
      "startCultivationContract": "Anza Mkataba wa Kilimo"
    }
  }
}
</i18n>

