<template>
  <v-sheet color="white" elevation="2" height="200px" width="100%">
    <v-checkbox v-model="hash" :rules="[requiredRule]" label="Scan fingerprint" v-bind="$attrs" v-on="$listeners" class="pa-4" />
  </v-sheet>
</template>


<script>
  export default {

    props: {
      value: Boolean,
      required: Boolean,
    },

    data: () => ({
      hash: false,
    }),

    watch: {
      value: {
        immediate: true,
        handler(to) { this.hash = to; }
      },
      hash(to) { this.$emit('input', to); },
    },

    methods: {
      requiredRule(v) {
        return !this.required || !(v===undefined) || 'regqqq';
      },
    }


  }
</script>
