<template>
    <PrintLayout
        :title="selectedAssessment.selectedPhase"
        @back="goBack"
    >
      <v-main class="text-left">

        <FarmerAgentDisplay :customer="customer" />

        <div
            class="px-8 pb-8"
            v-if="selectedAssessment.fields.visitDate">
          <p>{{selectedAssessment.fields.visitDate.question}} : {{selectedAssessment.fields.visitDate.answer| time("dd/MM/yyyy")}}</p>
        </div>

        <h3>{{$t('Pesticide Form')}}</h3>

        <v-simple-table
            class="text-left table"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{$t('title')}}
                </th>
                <th class="text-left">
                  {{$t('description')}}
                </th>
              </tr>
            </thead>
            <tbody>
            <template
            >

              <tr
                  v-for="(value, index) in selectedAssessment.fields.pesticideForm"
                  :key="index"
              >
                <td>{{ value.title }}</td>
                <td >
                  {{ value.description }}
                </td>

              </tr>
            </template>

            </tbody>
          </template>
        </v-simple-table>


        <h3> {{$t('Register Form')}} </h3>

        <v-simple-table
            class="text-left table"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{$t('title')}}
                </th>
                <th class="text-left">
                  {{$t('description')}}
                </th>
              </tr>
            </thead>
            <tbody>
            <template
            >

              <tr
                  v-for="(value, index) in selectedAssessment.fields.registerForm"
                  :key="index"
              >
                <td>{{ value.title }}</td>
                <td >
                  {{ value.description }}
                </td>

              </tr>
            </template>

            </tbody>
          </template>
        </v-simple-table>

        <h3> {{$t('Selected Phase')}} </h3>

        <v-simple-table
            class="text-left table"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{$t('title')}}
                </th>
                <th class="text-left">
                  {{$t('description')}}
                </th>
              </tr>
            </thead>
            <tbody>
            <template
            >

              <tr
                  v-for="(value, index) in selectedAssessment.fields.selectedPhaseForm"
                  :key="index"
              >
                <td>{{ value.title }}</td>
                <td >
                  {{ value.description }}
                </td>

              </tr>
            </template>

            </tbody>
          </template>
        </v-simple-table>

      </v-main>
    </PrintLayout>

  </template>

  <script>
  import PageHeader from '@/components/PageHeader';
  import SingleImage from "@/components/SingleImage";
  import PrintLayout from "@/components/PrintLayout";
  import FarmerAgentDisplay from "@/components/FarmerAgentDisplay";

  export default {
    components: { PageHeader, SingleImage, PrintLayout, FarmerAgentDisplay },
    computed: {
      assessmentUuid() {
        return this.$route.query.assessmentUuid;
      },
      selectedAssessment() {
        return this.$store.getters.assessments.find(assessment => assessment.assessmentUuid === this.assessmentUuid);
      },
      initialAssessment() {
        return this.$store.getters.assessments.find(assessment => assessment.assessmentUuid === this.selectedAssessment.initialTechnicalVisitUuid);
      },
      customer() {
        return this.$store.getters.customer(this.selectedAssessment.customerUuid);
      },
    },
    methods: {
      goBack() {
        this.$router.push({
          name: "Field",
          query: { uuid: this.selectedAssessment.customerUuid, fieldUuid: this.selectedAssessment.fieldUUid },
        });
      },
    }
  }
  </script>

  <style scoped>

  </style>


  <i18n>
  {
    "en": {
      "Register Form": "Register Form",
      "Selected Phase": "Selected Phase",
      "Pesticide Form": "Pesticide Form"
    },
    "es": {
      "Register Form": "Formulario de registro",
      "Selected Phase": "Fase seleccionada",
      "Pesticide Form": "Forma de pesticida"
    },
    "sw": {
      "Register Form": "Register Form",
      "Selected Phase": "Selected Phase",
      "Pesticide Form": "Pesticide Form"
    }
  }
  </i18n>
