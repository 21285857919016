import { v4 as uuidv4 } from 'uuid';

export default {
  methods: {

    newField(crop) {
      let cropId = undefined;
      if (crop) { cropId = this.lookupCropId(crop); }
      // cropId required
      let field = {
        uuid: uuidv4(),
        fieldSize: {},
        primaryCropId: cropId,
        cropId: cropId,
        status: 'new',
        numberOfPlants: 0,
      }
      field.name = this.deriveFieldName(field);
      return field;
    },

    updateCustomerField(field) {
      field.uuid = field.uuid || uuidv4();
      field.name = this.deriveFieldName(field);
      this.customer.fields = this.customer.fields || [];
      const index = this.customer.fields.findIndex((e) => {
        if (e.uuid === field.uuid) { return true; }
      });
      if (index===-1) {
        this.customer.fields.push(field);
      } else {
        this.customer.fields[index] = field;
      }
    },

    fieldLandSize(field) {
      let r = null;
      if (field && field.fieldSize) { r = field.fieldSize.landSize; }
      return r;
    },

    fieldLandSizeUnit(field) {
      let r = null;
      if (field && field.fieldSize) { r = field.fieldSize.unit; }
      return r;
    },

    deriveFieldName(field) {
      let t = [];
      if (field.cropId) { t.push(this.$store.getters.cropLookups[field.cropId]); }
      if (field.partner_identifier) {
        t.push('(' + field.partner_identifier + ')');
      } else {
        if (field.fieldSize && field.fieldSize.landSize && parseFloat(field.fieldSize.landSize)>0) {
          let size = '(' + field.fieldSize.landSize + ' ' + field.fieldSize.unit + ')'
          t.push(size);
        }
      }
      if (t.length===0) { t.push('Field ' + ((this.customer.fields || []).length + 1)); }
      return t.join(' ');
    },

    lookupCropId(cropName) {
      let crop = this.$store.getters.crops.filter(e => e.text===cropName)[0];
      return (crop ? crop['value'] : undefined);
    },

  }

};
