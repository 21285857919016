<template>
  
  <v-form ref="settlementForm" v-model="valid" >
    <v-container fluid>

      <v-row>
        <v-col cols="12" class="text-h3 text--primary text-left">
          Check Me Back, {{customer.name}}: Settlement
        </v-col>
      </v-row>


      <ServerSideResult :test="instrument" v-if="disbursing">
        <v-row>
          <v-col class="text-h5">
            <InstrumentUltimateTzCheckMeBack :instrument="instrument" :highlight="highlight" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <CurrencyInput v-model="cash" label="Cash Received" />
          </v-col>
          <v-col cols="6">
            <TextInput v-model="cashReceiptNumber" label="Receipt Number" required />
          </v-col>
        </v-row>


        <v-row>
          <v-col>
            <v-btn @click="settlement" color="primary" :disabled="!maySettle">Settle Loan</v-btn>
          </v-col>
        </v-row>

      </ServerSideResult>

      <ServerSideResult :test="settlementProcess" v-else>
        <v-row>
          <v-col cols="12" class="text-h4 text--primary text-left">
            Thank you. Your settlement has been processed.
          </v-col>
        </v-row>
      </ServerSideResult>

    </v-container>
  </v-form>

</template>

<script>
import lookups from '@/mixins/lookups';

import CurrencyInput from "@/components/CurrencyInput";
import TextInput from "@/components/TextInput";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import InstrumentUltimateTzCheckMeBack from "@/views/instruments/InstrumentUltimateTzCheckMeBack"


export default {

  components: {  CurrencyInput, TextInput, Currency, ServerSideResult, InstrumentUltimateTzCheckMeBack },

  props: ['customer', 'instrumentId'],

  data() {
    return {
      valid: false,
      instrument: undefined,
      cash: 0,
      cashReceiptNumber: undefined,
      disbursing: true,
      settlementProcess: undefined,
    }
  },

  computed: {
    installmentAmount() { return ((this.instrument.installment || 0)/100.0); },
    settlementAmount() { return ((this.instrument.settlement_amount || 0)/100.0); },
    highlight() { return ( ((this.cash ||0)>this.installmentAmount) ? 'Settlement' : 'Payable' ) },
    maySettle() {
      let r = (this.valid && (this.cash>=0) && this.instrument);
      if (r) {
        r = (this.cash<=this.installmentAmount) || this.cash==this.settlementAmount;
      }
      return r;
    },
  },

  watch: {
    instrument(to) {
      if (to && this.cash==0) {
        this.cash = this.installmentAmount;
      }
    }
  },

  methods: {
    validate() { this.$refs.settlementForm.validate(); },
    settlement() {
      this.disbursing = false;
      this.remoteLookup('instrumentUltimateTzCheckMeBackSettlement', {
        uuid: this.customer.uuid,
        id: this.instrumentId,
        cash: this.cash,
        receiptNumber: this.cashReceiptNumber,
      }).then( e => this.settlementProcess = 'done' ).catch(e => this.settlementProcess = null);
    },
  },

  mounted() {
    this.remoteLookup('instrumentUltimateTzCheckMeBack', {
      uuid: this.customer.uuid,
      id: this.instrumentId,
    }).then( instrument => this.instrument = instrument ).catch(e => this.instrument = null);
  },

  mixins: [lookups],

}
</script>
