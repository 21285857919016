<template>
    <div>

    <v-banner v-if="showBanner">
        <v-avatar
          slot="icon"
          size="40"
        >
          <v-progress-circular
          v-if="!customersInitialised"
          indeterminate
          color="primary"
        ></v-progress-circular>

        <v-icon
        v-else
        dark
        right
        large
        color="primary"
      >
        mdi-checkbox-marked-circle
      </v-icon>

        </v-avatar>

downloading user data : {{ customersInitialised }}
        <template v-slot:actions>
          <v-btn
            text
            color="deep-purple accent-4"
          >
            Action
          </v-btn>
          <v-btn
            text
            color="deep-purple accent-4"
            @click="timeoutCloseBanner"
          >
            Action 1
          </v-btn>
        </template>
      </v-banner>

    </div>
</template>

<script>
// component not used
export default {

  data() {

    return {
      showBanner: true
      };

    },

    mounted() {

    },
    computed: {
      customersInitialised () {
        return this.$store.getters.customersInitialised
      },
    },
    watch: {
      'customersInitialised': function (value) {
        console.log('watch.customersInitialised', value)
      }
    },

    methods: {
      timeoutCloseBanner() {
        setTimeout(()=> {
          this.showBanner = false

        }, 5000)

      }

    },
};
</script>

<style lang="scss" scoped>

</style>
