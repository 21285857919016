<template>
  <v-form v-model="valid" class="purple lighten-5" ref="form3"  v-bind:disabled="disableForm">
    <v-card-title>
      {{$t('plasticMulch')}}
    </v-card-title>
    <v-divider></v-divider>
    <v-container fluid>

      <v-col cols="12" sm="4">
        <v-radio-group
            :label="formFields.hasPlasticMulch.question"
            row
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.hasPlasticMulch.answer"
            clearable
            required
        >
          <v-radio label="Si" value="Si"></v-radio>
          <v-radio label="No" value="No"></v-radio>
        </v-radio-group>
      </v-col>

      <template v-if="['yes', 'Si', 'si', 'Sí'].includes(formFields.hasPlasticMulch.answer)">
      <v-row>


        <v-col cols="12" sm="4">
          <v-radio-group
              :label="formFields.necessaryChemical.question"
              row
              v-model="formFields.necessaryChemical.answer"
              clearable
              :rules="[sharedValidations.requiredValidation]"
              data-cy="necessaryChemical"
          >
            <v-radio label="Si" value="Si"></v-radio>
            <v-radio label="No" value="No"></v-radio>
          </v-radio-group>
        </v-col>
<!--3-->
        <v-col cols="12" md="4">
          <v-combobox
            :items="[0.6, 0.7, 0.8, 0.9, 1.1, 1.2]"
            :label="formFields.plasticCaliber.question"
            single-line
            v-model="formFields.plasticCaliber.answer"
            clearable
            :rules="[sharedValidations.requiredValidation]"
            data-cy="plasticCaliber"
          ></v-combobox>
        </v-col>
<!--4-->
        <v-col cols="12" md="4">
          <v-text-field
            :label="formFields.plasticColor.question"
            required
            v-model="formFields.plasticColor.answer"
            clearable
            :rules="[sharedValidations.requiredValidation]"
            data-cy="plasticColor"
          ></v-text-field>
        </v-col>
<!--5-->
        <v-col cols="12" md="4">
          <v-combobox
            :items="[0.9, 1.0, 1.2, 1.4 ]"
            :label="formFields.measuresOfTheMulch.question"
            single-line
            v-model="formFields.measuresOfTheMulch.answer"
            clearable
            :rules="[sharedValidations.requiredValidation]"
            data-cy="measuresOfTheMulch"
          ></v-combobox>
        </v-col>
<!--6-->

<!--7-->
        <v-col cols="12" md="4">
          <v-text-field
            :label="formFields.distanceBetweenHoles.question"
            disabled
            v-model="formFields.distanceBetweenHoles.answer"
            :rules="[sharedValidations.requiredValidation]"
            persistent-hint
            hint="igual que Distancia entre surcos"
            clearable
            data-cy="distanceBetweenHoles"
          ></v-text-field>
        </v-col>

      </v-row>

      <v-row class="green lighten-5">
        <TakePhoto :text="'Upload Images'" @click="saveImage">
          <v-btn elevation="2" color="primary"> Fotos del Terreno </v-btn>
        </TakePhoto>
        <div class="form-images-wrapper d-md-flex">
          <div v-for="item in formFields.attachedImages" class="mx-4 my-4">
            <SingleImage :image="item" maxHeight="200px" />
          </div>
        </div>
      </v-row>

      </template>

    </v-container>
  </v-form>
</template>

<script>
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
import TakePhoto from "@/components/TakePhoto";
import SingleImage from "@/components/SingleImage";
import ImageUploadTypeEnums from "@/mixins/ImageUploadTypeEnums";
import {filterFormFieldsWithQuestions} from "@/views/partners/hr_co/utils";

export default {
  name: "Phase2Section3",
  props: ['value', 'customer', 'field', 'disableForm'],
  components: {
    TakePhoto,
    SingleImage
  },
  watch: {
    value: {
      immediate: true,
      handler(to) { this.formFields = to; }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },

  },
  data() {
    return {
      valid: false,
      formFields: {},
      sharedValidations: new HugoValidations(),
    };
  },
  methods: {
    validate() {
      this.$refs.form3.validate();
    },
    saveImage(uuid) {
      const savedImageObj = {
        type: this.imageUploadTypeEnums.technicalVisitView,
        type_id: this.field.uuid,
        uuid: uuid,
      };
      this.$store.dispatch("addImage", savedImageObj);
      this.formFields.attachedImages.push(savedImageObj);
    },

  },
  computed: {
    isPhaseOneSectionValid() {
      return this.valid
    },
  },
  mixins: [ImageUploadTypeEnums]
};
</script>

<style scoped>
</style>
<i18n>
{
  "en": {
    "plasticMulch": "Plastic Mulch"
  },
  "es": {
    "plasticMulch": "Emplasticado"
  },
  "sw": {
    "plasticMulch": "Plastiki Mulch"
  }
}
</i18n>
