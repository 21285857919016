<template>
  
  <ServerSideResult :test="history">
    <v-data-table
      :headers="headers"
      :items="history"
      :items-per-page="25"
      class="elevation-1"
    >
    
      <template v-slot:item.disbursed_at="{ item }">
        <Time v-if="item.disbursed_at" :time="item.disbursed_at" />
      </template>
    
      <template v-slot:item.type="{ item }">
        {{instrumentTypeToName(item.type)}}
      </template>
    
      <template v-slot:item.amount="{ item }">
        <Currency v-if="item.amount" :value="parseInt(item.amount)" />
      </template>
    
      <template v-slot:item.balance="{ item }">
        <Currency v-if="item.balance" :value="parseInt(item.balance)" cents />
      </template>
    
      <template v-slot:item.status="{ item }">
        <v-icon small color="success" v-if="item.status=='closed'">mdi-check-bold</v-icon>
      </template>

    </v-data-table>

  </ServerSideResult>

</template>

<script>
import lookups from '@/mixins/lookups';
import instrumentNames from '@/mixins/instrumentNames';

import Time from "@/components/Time";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";


export default {

  components: {  Time, Currency, ServerSideResult },

  props: {
    customer: Object,
  },

  data() {
    return {
      history: undefined,
      headers: [
        {
          text: 'Disbursed',
          align: 'start',
          sortable: true,
          value: 'disbursed_at',
        },
        {
          text: 'Product',
          align: 'start',
          sortable: true,
          value: 'type',
        },
        {
          text: 'Amount',
          align: 'end',
          sortable: true,
          value: 'amount',
        },
        {
          text: 'Balance',
          align: 'end',
          sortable: true,
          value: 'balance',
        },
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status',
        },
      ],
    }
  },

  computed: {
    uuid() { return (this.customer ? this.customer.uuid : this.$route.query.uuid); },
  },

  watch: {
  },

  methods: {
  },

  mounted() {
    this.remoteLookup('instrumentHistory', { uuid: this.uuid }).then( history => this.history = history.history ).catch(e => this.history = null);
  },

  mixins: [lookups, instrumentNames],

}
</script>
