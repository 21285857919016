<template>

  <div>
    <RolloverSalaryAdvance :customer="customer" :instrumentId="instrumentId" v-if="instrumentType=='InstrumentUltimateTzSalaryAdvance'" />
    <RolloverCheckMeBack :customer="customer" :instrumentId="instrumentId" v-if="instrumentType=='InstrumentUltimateTzCheckMeBack'" />
  </div>

</template>

<script>
import RolloverSalaryAdvance from "@/views/partners/ultimate_tz/RolloverSalaryAdvance"
import RolloverCheckMeBack from "@/views/partners/ultimate_tz/RolloverCheckMeBack"

export default {

  components: { RolloverSalaryAdvance, RolloverCheckMeBack },

  computed: {
    uuid() { return this.$route.query.uuid; },
    instrumentId() { return this.$route.query.instrumentId; },
    instrumentType() { return this.$route.query.instrumentType; },
    customer() { return this.$store.getters.customer(this.uuid); },
  },

}
</script>
