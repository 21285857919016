<template>
<v-form v-model="validForm" ref="fieldContractRegisterForm">
   <PageHeader
       :title="$t('Field Contract Register')"
       :subtitle="customer.name"
       icon="mdi-clipboard-account"
       @back="toField"
       back
       @save="submit"
       :valid="allFormFieldsValid"
       save >

      <v-container fluid>
        <v-row class="red lighten-5">
           <v-col cols="12">
            <v-text-field
                type="text"
                :label="formFields.farmAddress.question"
                v-model="formFields.farmAddress.answer"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="red lighten-5">
          <RegionPicker v-model="formFields.regions.answer" />
          <v-col cols="12" sm="6">
            <v-text-field
                type="text"
                :label="formFields.firstName.question"
                v-model="formFields.firstName.answer"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                type="text"
                :label="formFields.surname.question"
                v-model="formFields.surname.answer"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <IdentityInput v-model="formFields.identity.answer" />
          </v-col>
          <v-col cols="12" sm="3">
            <MsisdnInput v-model="formFields.msisdn.answer" data-cy="msisdn" />
          </v-col>
          <v-col cols="12" sm="3">
            <EmailInput
              :rules="[sharedValidations.requiredValidationSpanish]"
              v-model="formFields.email.answer"
              data-cy="email-input"
            />
          </v-col>
        </v-row>

        <v-row class="blue lighten-5">

          <v-col cols="12" sm="6" lg="3">
            <DateInput
              v-model="formFields.contractDate.answer"
              :label="formFields.contractDate.question"
              :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
              :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
            />
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field
                type="text"
                v-model="formFields.numberOfContract.answer"
                :label="formFields.numberOfContract.question"
                required></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field
                v-model="formFields.fieldCode.answer"
                :label="formFields.fieldCode.question"
                :rules="[fieldCodeRules]" />
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-combobox
                :items="[
                    'Particular',
                    'Empresa',
                    'Asociación'
                    ]"
                class="text-left mt-3"
                v-model="formFields.contractType.answer"
                :label="formFields.contractType.question"
                required>

            </v-combobox>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field
                v-model="formFields.legalRepresentative.answer"
                :label="formFields.legalRepresentative.question"
                required />
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field
                v-model="formFields.ballot.answer"
                :label="formFields.ballot.question"
                required />
          </v-col>

        </v-row>


        <v-row class="green lighten-5">

          <v-col cols="12" sm="6" lg="3"
          v-if="formFields.fieldSize"
          >
            <LandSizeInput
                v-model="formFields.fieldSize.answer"
                :label="formFields.fieldSize.question"
                :disabled="(field && field.fieldSize.landSize > 0)"
                :required="true"
            />
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field
                v-model="formFields.numberOfPlants.answer"
                :label="formFields.numberOfPlants.question"
                type="number"
                :rules="numberRules"
                min=0 ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field
                v-model="formFields.variety.answer"
                :label="formFields.variety.question"
                required />
          </v-col>

          <v-col cols="12" sm="6" lg="3">

            <v-combobox
                :items="[
                    'Hugo Restrepo',
                    'Híbrido'
                    ]"
                class="text-left mt-3"
                v-model="formFields.seedOrigin.answer"
                :label="formFields.seedOrigin.question"
                :rules="seedRules">

            </v-combobox>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-select
                :items="harvestSpecifications"
                class="text-left"
                multiple
                chips
                :rules="harvestRules"
                v-model="formFields.harvestSpecification.answer"
                :label="formFields.harvestSpecification.question"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" lg="6" class="text-left">
            <label>{{formFields.harvestImplements.question}}</label>
            <v-radio-group
                row
                v-model="formFields.harvestImplements.answer"
                mandatory>
              <v-radio label="Si" value="Si"></v-radio>
              <v-radio label="Non" value="Non"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-select
                :items="[
                    'Canastilla',
                    'Balde',
                    'Saco Prolipopileno'
                ]"
                class="text-left"
                :rules="[sharedValidations.requiredValidationSpanish]"
                v-model="formFields.harvestImplementation.answer"
                :label="formFields.harvestImplementation.question"
            >
            </v-select>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-combobox
                :items="allDepots"
                class="text-left mt-3"
                v-model="formFields.depot.answer"
                :label="formFields.depot.question"
                item-value="id"
                item-text="name"
                >

            </v-combobox>
          </v-col>

        </v-row>

        <v-row class="grey lighten-5">
          <v-col cols="12" sm="4" lg="4">
           <DateInput
                v-model="formFields.targetDateSeedsPlanted.answer"
                :label="formFields.targetDateSeedsPlanted.question"
                :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
                :max="new Date(Date.now() +   360*24*60*60*1000).toISOString().substr(0, 10)"
              />
          </v-col>

<!--          add input-->
          <v-col cols="12" sm="4" lg="4">
           <DateInput
                :value="formFields.calculatedTargetDateSeedlingsPlanted.answer"
                :label="formFields.calculatedTargetDateSeedlingsPlanted.question"
                :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
                :max="new Date(Date.now() +   360*24*60*60*1000).toISOString().substr(0, 10)"
                :disabled="true"
                :hint="$t('targetDateSeedlingsPlantedHint')"
              />
          </v-col>
          <v-col cols="12" sm="4" lg="4">
           <DateInput
                v-model="formFields.calculatedTargetDateHarvest.answer"
                :label="formFields.calculatedTargetDateHarvest.question"
                :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
                :max="new Date(Date.now() +   360*24*60*60*1000).toISOString().substr(0, 10)"
                :disabled="true"
                :hint="$t('calculatedTargetDateHarvestHint')"
              />
          </v-col>

        </v-row>

        <v-row class="orange lighten-5">
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
                v-model="formFields.maximumEstimatedProduction.answer"
                :label="formFields.maximumEstimatedProduction.question"
                type="number"
                :rules="numberRules"
                min=0
                suffix="kg" >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
                :value="formFields.minimumEstimatedProduction.answer"
                :label="formFields.minimumEstimatedProduction.question"
                type="number"
                :rules="numberRules"
                min=0
                suffix="kg"
                disabled
                :hint="$t('calculateMinimumEstimatedProduction')"
                persistent-hint
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row class="pink lighten-5">
          <v-col cols="12" sm="6" md="3">
            <DateInput
                :value="formFields.contractEndDate.answer"
                :label="formFields.contractEndDate.question"
                disabled
                :hint="$t('calculateDateContractEnding')"
                persistent-hint
            />
          </v-col>

          <v-col cols="12" sm="2" md="1">
            <v-switch
                v-model="cop"
                flat
                :label="currency"
            ></v-switch>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-text-field
                v-model="formFields.contractPrice.answer"
                :label="formFields.contractPrice.question"
              :rules="[priceRules]"
              min=0
              type='number'
              :prefix="currency"
              suffix="/kg"
              @input='setCurrency()'
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
                v-model="formFields.valueContract.answer"
                :label="formFields.valueContract.question"
              :rules="[priceRules]"
              min=0
              type='number'
              :prefix="currency"
              @input='setCurrency()'
              disabled
              :hint="$t('calculateTotalValueContract')"
              persistent-hint
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
                v-model="formFields.contractedKilos.answer"
                :label="formFields.contractedKilos.question"
              min=0
              type='number'
              :prefix="currency"
              @input='setCurrency()'
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
                v-model="formFields.stipulatedCurrency.answer"
                :label="formFields.stipulatedCurrency.question"
            />
          </v-col>

        </v-row>

        <v-row class="blue lighten-5">
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="formFields.goodProductionBonus.answer"
              :label="formFields.goodProductionBonus.question"
              :rules="[priceRules]"
              min=0
              type='number'
              :prefix="currency"
              suffix="/kg"
              @input='setCurrency()'
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-combobox
                :items="[
                    'Propietario',
                    'Arrendatario',
                    ]"
                class="text-left mt-3"
                v-model="formFields.sellerQuality.answer"
                :label="formFields.sellerQuality.question"
                required>

            </v-combobox>
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
                v-model="formFields.clauseNumber37.answer"
                :label="formFields.clauseNumber37.question"
              min=0
              type='number'
            />
          </v-col>


          <v-col cols="12" sm="4">
            <v-text-field
                v-model="formFields.clauseNumber38.answer"
                :label="formFields.clauseNumber38.question"
              min=0
              type='number'
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
                v-model="formFields.clauseParagraph.answer"
                :label="formFields.clauseParagraph.question"
              min=0
              type='number'
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
                v-model="formFields.penalClause.answer"
                :label="formFields.penalClause.question"
              min=0
              type='number'
            />
          </v-col>

        </v-row>

      </v-container>
    </PageHeader>
  </v-form>

</template>

<script>
 import PageHeader from '@/components/PageHeader';
 import FormContainer from '@/components/FormContainer';
 import GenderInput from "@/components/GenderInput"
 import DateInput from '@/components/DateInput';
 import NameInput from '@/components/NameInput';
 import RegionPicker from '@/components/RegionPicker';
 import LandSizeInput from '@/components/LandSizeInput';
 import DepotInput from '@/components/DepotInput';
 import IdentityInput from '@/components/IdentityInput';
 import MsisdnInput from '@/components/MsisdnInput';
 import EmailInput from '@/components/EmailInput';
 import Row from '@/components/Row';
 import {
   addMonthsToDate, areFormFieldsFilled, calcAllDays,
   calcBusinessDays,
   createNewAssessment,
   HR_FIELD_CODE_REGEX
 } from "@/views/partners/hr_co/utils";
 import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
 import fields from "@/mixins/fields";
 import {ESTIMATED_TARGET_DATE_HARVERST, ESTIMATED_TRANSPLANT_DAYS} from "@/views/partners/hr_co/constant";
 import {assessmentNameEnum, assessmentQuestionType} from "@/views/partners/hr_co/constant";

export default {

    components: { PageHeader, FormContainer, DateInput,NameInput,RegionPicker,LandSizeInput,GenderInput,Row,DepotInput,IdentityInput,MsisdnInput,EmailInput },

    data: () => ({
      formFields: {
        farmAddress: {
          question: 'Dirección Residencia',
          answer: null,
        },
        regions: {
          question: 'Ciudad/Región/Vereda',
          answer: null,
          questionType: 'RegionPicker'
        },
        contractDate: {
          question: 'Fecha Contrato H.R',
          answer: null,
        },
        numberOfContract: {
          question: 'Número de Contrato Asignado',
          answer: null,
        },
        fieldCode: {
          question: 'Código Cultivo H.R asignado',
          answer: null,
        },
        // priorFieldCode: {
        //   question: 'Códigos Cultivos H.R anteriores',
        //   answer: null,
        // },
        fieldSize: {
          question: 'Area Lote (Hectáreas)',
          answer: null,
          questionType: assessmentQuestionType.LandSizeInput
        },
        numberOfPlants: {
          question: 'Total PLantas',
          answer: null,
        },
        seedOrigin: {
          question: 'Origen Semilla',
          answer: null,
        },
        harvestSpecification: {
          question: 'Especificaciones de cosecha',
          answer: null,
          questionType: 'MultiSelect'
        },
        harvestImplements: {
          question: 'Usa Canastillas en la Finca para otros cultivos?',
          answer: null,
        },
        harvestImplementation: {
          question: 'Implementos de Cosecha?',
          answer: 'null',
        },
        depot: {
          question: 'Estación de molienda',
          answer: null,
          questionType: 'DepotInput'
        },
        targetDateSeedsPlanted: {
          question: 'Fecha límite de siembra en vivero',
          answer: null,
        },
        calculatedTargetDateSeedlingsPlanted: {
          question: 'Fecha estimada de Transplante: DDT',
          answer: null,
        },
        calculatedTargetDateHarvest: {
          question: 'Fecha de Inicio Producción',
          answer: null,
        },
        maximumEstimatedProduction: {
          question: 'Producción máxima estimada Totales',
          answer: null,
        },
        minimumEstimatedProduction: {
          question: 'Producción mínima en Kilos',
          answer: null,
        },
        contractEndDate: {
          question: 'Fecha Terminación Contrato',
          answer: null,
        },
        contractPrice: {
          question: 'Cantidad por KIlo a pagar (US$ o Pesos)',
          answer: null,
        },
        valueContract: {
          question: 'Valor total del contrato',
          answer: null,
        },
        goodProductionBonus: {
          question: 'Pago Extra Buena Producción',
          answer: null,
        },
        // transportPayment: {
        //   question: 'Auxilio de Transporte KIlo. (US$ o Pesos)',
        //   answer: null,
        // },
        // handLabourPayment: {
        //   question: 'Auxilio mano de obra por cosecha kilo (US$ o Pesos)',
        //   answer: null,
        // },
        contractType: {
          question: 'Tipo Contratante',
          answer: null,
        },
        legalRepresentative: {
          question: 'Representante Legal',
          answer: null
        },
        ballot: {
          question: 'Cédula',
          answer: null
        },
        variety: {
          question: 'Variedad',
          answer: null
        },
        contractedKilos: {
          question: 'Kilos contratados',
          answer: null
        },
        stipulatedCurrency: {
          question: 'Moneda Estipulada',
          answer: null
        },
        sellerQuality: {
          question: 'Calidad del vendedor frente al inmueble',
          answer: null
        },
        clauseNumber37: {
          question: 'Cláusula Tercera Númeral 3.7',
          answer: null
        },
        clauseNumber38: {
          question: 'Cláusula Tercera Númeral 3.8',
          answer: null
        },
        clauseParagraph: {
          question: 'Cláusula Sexta Parágrafo 2',
          answer: null
        },
        penalClause: {
          question: 'Cláusula Séptima Penal',
          answer: null,
        },
        firstName: {
          question: 'Primer nombre o Razón Social',
          answer: null
        },
        surname: {
          question: 'Apellido',
          answer: null
        },
        identity: {
          question: 'Documento de Identidad',
          answer: null
        },
        msisdn: {
          question: 'Número de Teléfono',
          answer: null
        },
        email: {
          question: 'Dirección de correo electrónico',
          answer: null
        },
      },
      validForm: true,
      cop: false,
      harvestSpecifications: ['Verde', 'Roja', 'Amarilla'],
      numberRules: [
        v => (Number(v) >= 0) || 'A positive number please',
      ],
      cropRules: [ v => !!v || "Seleccione un cultivo" ],
      harvestRules: [ v => (!!v && v.length>0) || 'Especifique el tipo de cosecha' ],
      seedRules: [ v => !!v || 'Confirme la fuente de la semilla' ],
      sharedValidations: new HugoValidations()
    }),

    mounted() {
      this.formFields.fieldSize.answer = this.field.fieldSize
      this.formFields.firstName.answer = this.customer.firstName
      this.formFields.surname.answer = this.customer.surname
      this.formFields.identity.answer = this.customer.demographic.identity
      this.formFields.msisdn.answer = this.customer.msisdn
      this.formFields.email.answer = this.customer.email
    },

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      allDepots() { return this.$store.getters.settings.depots; },
      firstVisitUuid() {
        return this.$route.query.firstVisitUuid;
      },
      firstVisitToEdit() {
        return this.$store.getters.assessments.find(assessment =>
            assessment.assessmentUuid === this.firstVisitUuid &&
            assessment.customerUuid === this.customerUuid
        )
      },

      field() {
        return this.customer.fields.find(
            (e) => e.uuid === this.$route.query.fieldUuid
        );
      },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      demographic() { return this.customer.demographic || {} },
      currency() { return ( this.cop ? 'COP' : 'US$' ); },
      formFieldsValid() {
        return areFormFieldsFilled(Object.values(this.formFields))
      },
      regionFormFieldsValid() {
        return this.formFields.regions.answer ? this.formFields.regions.answer.filter(region => !region).length === 0 : false
      },
      fieldSizeValid() {
        return parseFloat(this.formFields.fieldSize.answer.landSize) > 0
      },
      allFormFieldsValid() {
        return this.formFieldsValid && this.regionFormFieldsValid && this.fieldSizeValid
      }
    },
    methods: {
      toField() {
        this.$router.push({ name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.fieldUuid }});
      },
      toSubmittedField(assessmentUuid) {
        this.$router.push({ name: 'ShowFieldContract', query: { customerUuid: this.customerUuid, assessmentUuid: assessmentUuid }});
      },
      setCurrency() { this.contractCurrency = (this.currency==='COP' ? 'COP' : 'USD'); },
      priceRules(value) {
        let r = (parseFloat(value) >= 0);
        if (!r) { r = 'Ingrese una cantidad positiva';}
        return r;
      },
      fieldCodeRules(value) {
        return HR_FIELD_CODE_REGEX.test(value) || 'Código de campo no válido';
      },
      priorFeldCodeRules(value){
        return (value || '').length==0 || HR_FIELD_CODE_REGEX.test(value) || 'Código de campo no válido';
      },
      updateFieldSize(size) {
        this.field.fieldSize.landSize = size.landSize;
        this.field.fieldSize.unit = size.unit;
        return this.field
      },
      submit() {
        const newAssessment = createNewAssessment(assessmentNameEnum.cultivationContract, this.customerUuid, this.formFields)
        newAssessment.currency = this.currency
        newAssessment.fieldUuid = this.fieldUuid

        if (!this.field.fieldSize.landSize || parseFloat(this.field.fieldSize.landSize) === 0 ) {
          this.updateCustomerField(this.updateFieldSize(this.formFields.fieldSize.answer))
          this.$store.dispatch('upsertCustomer', this.customer);
        }

        this.$store.commit('upsertAssessment', newAssessment);
        this.toSubmittedField(newAssessment.assessmentUuid)
      },
    },
    watch: {
      'formFields.targetDateSeedsPlanted.answer': function(newValue) {
        this.formFields.calculatedTargetDateSeedlingsPlanted.answer = newValue ? calcAllDays(new Date(newValue), ESTIMATED_TRANSPLANT_DAYS) : ''
      },
      'formFields.calculatedTargetDateSeedlingsPlanted.answer': function(newValue) {
        this.formFields.calculatedTargetDateHarvest.answer = newValue ? calcAllDays(new Date(newValue), ESTIMATED_TARGET_DATE_HARVERST) : ''
      },
      'formFields.maximumEstimatedProduction.answer': function(newValue) {
        this.formFields.minimumEstimatedProduction.answer = newValue ? (newValue * 0.2).toFixed(1) : '';
        this.formFields.valueContract.answer = this.formFields.contractPrice.answer ? this.formFields.contractPrice.answer * newValue : '';
      },
      'formFields.contractPrice.answer': function(newValue) {
        this.formFields.valueContract.answer = newValue ? newValue * this.formFields.maximumEstimatedProduction.answer : '';
      },
      'formFields.contractDate.answer': function(newValue) {
        this.formFields.contractEndDate.answer = newValue ? addMonthsToDate(new Date(newValue), 14) : '';
      },
      customer: {
        immediate: true,
        handler(to) {
          if (to) {
            this.farmAddress = ((((to.demographic || {}).keyLocations || {}).work || {}).lookupParameters || {}).address;
            this.regions = (to.demographic || {}).geographicsArray;
          }
        },
      },
    },
    mixins: [fields],

}
</script>

<i18n>
{
  "en": {
    "Field Contract Register": "Field Contract Register",
    "Date of Birth": "Date of Birth",
    "Dependents": "Dependents",
    "Sex": "Sex",
    "Level of education": "Level of education",
    "Home Address": "Home Address",
    "H.R Contract Date": "H.R Contract Date",
    "Number of Contract": "Number of Contract",
    "H.R Field Code": "H.R Field Code",
    "H.R Previous Fields Code": "H.R Previous Fields Code",
    "Field Area": "Field Area",
    "Total Number of Plants": "Total Number of Plants",
    "Seeds origin": "Seeds origin",
    "Hybrids": "Hybrids",
    "Harvest specifications": "Harvest specifications",
    "Harvest Implements": "Harvest Implements",
    "Yes": "Yes",
    "No": "No",
    "Grinding Station": "Grinding Station",
    "Target Date for seeds planting": "Target Date for seeds planting",
    "Target Date for seedlings planting: DDT": "Target Date for seedlings planting: DDT",
    "Date of starting harvest": "Date of starting harvest",
    "Maximum estimated production": "Maximum estimated production",
    "Date of Contract ending": "Date of Contract ending",
    "Pesos/U$ per Kilo": "Pesos/U$ per Kilo",
    "Total Value of Contract": "Total Value of Contract",
    "Extra Payment Good Production": "Extra Payment Good Production",
    "Extra Payment for Transport": "Extra Payment for Transport",
    "Extra Payment for Hand Labor": "Extra Payment for Hand Labor",
    "SaveAndEdit": "Save & Edit",
    "SaveAndAdd": "Save & Add",
    "Clear": "Clear",
    "targetDateSeedlingsPlantedHint": "35 calendar days after Target Date for seeds planting",
    "calculateMinimumEstimatedProduction": "20% of Maximum estimated production",
    "calculateDateContractEnding": "14 months after H.R Contract Date",
    "calculateTotalValueContract": "Formula: U$ per kilo x Maximum kilos of contract",
    "calculatedTargetDateHarvestHint": "120 calendar days after Target Date for seedlings planting",
    "Contractor Type": "Contractor Type",
    "Legal Representative": "Legal Representative",
    "Ballot": "Ballot",
    "Variety": "Variety",
    "Contracted Kilos": "Contracted Kilos",
    "Stipulated Currency": "Stipulated Currency",
    "Quality of the seller in front of the property": "Quality of the seller in front of the property",
    "Third Clause Number 3.7": "Third Clause Number 3.7",
    "Third Clause Number 3.8": "Third Clause Number 3.8",
    "Clause Six Paragraph 2": "Clause Six Paragraph 2",
    "Seventh Penal Clause": "Seventh Penal Clause",
    "First Name": "First Name",
    "Surname": "Surname",
    "NIN Number": "NIN Number",
    "Telephone Number": "Telephone Number"
  },
  "es": {
    "Field Contract Register": "Contrato Cultivo",
    "Date of Birth": "Fecha de Nacimiento",
    "Dependents": "Personas a Cargo en Familia",
    "Sex": "Sexo",
    "Level of education": "Nivel de Escolaridad",
    "Home Address": "Dirección Residencia",
    "H.R Contract Date": "Fecha Contrato H.R",
    "Number of Contract": "Número de Contrato Asignado",
    "H.R Field Code": "Código Cultivo H.R asignado",
    "H.R Previous Fields Code": "Códigos Cultivos H.R anteriores",
    "Field Area": "Area Lote (Hectáreas)",
    "Total Number of Plants": "Total PLantas",
    "Seeds origin": "Origen Semilla",
    "Hybrids": "Híbridos",
    "Harvest specifications": "Especificaciones de cosecha",
    "Harvest Implements": "Usa Canastillas en la Finca para otros cultivos?",
    "Yes": "Si",
    "No": "No",
    "Grinding Station": "Estación de Molienda asignada",
    "Target Date for seeds planting": "Fecha límite de siembra en vivero",
    "Target Date for seedlings planting: DDT": "Fecha estimada de Transplante: DDT",
    "Date of starting harvest": "Fecha de Inicio Producción",
    "Maximum estimated production": "Producción máxima estimada Totales",
    "Minimum estimated Production": "Producción mínima en Kilos",
    "Date of Contract ending": "Fecha Terminación Contrato",
    "Pesos/U$ per Kilo": "Pesos por KIlo a pagar (U$ o Pesos)",
    "Total Value of Contract": "Valor total del contrato",
    "Extra Payment Good Production": "Extra Payment Good Production",
    "Extra Payment for Transport": "Auxilio de Transporte KIlo. (U$ o Pesos)",
    "Extra Payment for Hand Labor": "Auxilio mano de obra por cosecha kilo (U$ o Pesos)",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar",
    "targetDateSeedlingsPlantedHint": "35 días calendario después de la fecha límite para la siembra de semillas",
    "calculatedTargetDateHarvestHint": "120 días calendario después de la fecha objetivo para la siembra de plántulas",
    "calculateMinimumEstimatedProduction": "20% de la producción máxima estimada",
    "calculateDateContractEnding": "14 meses después de la fecha del contrato de H.R",
    "calculateTotalValueContract": "Fórmula: 'U$ por kilo' x 'Máximo kilos de contrato'",
    "Contractor Type": "Tipo Contratante",
    "Legal Representative": "Representante Legal",
    "Ballot": "Cédula",
    "Variety": "Variedad",
    "Contracted kilos": "Kilos contratados",
    "Stipulated Currency": "Moneda Estipulada",
    "Quality of the seller in front of the property": "Calidad del vendedor frente al inmueble",
    "Third Clause Number 3.7": "Cláusula Tercera Númeral 3.7",
    "Third Clause Number 3.8": "Cláusula Tercera Númeral 3.8",
    "Clause Six Paragraph 2": "Cláusula Sexta Parágrafo 2",
    "Seventh Penal Clause": "Cláusula Séptima Penal",
    "First Name": "Primer nombre o Razón Social",
    "Surname": "Apellido",
    "NIN Number": "Numero de Identidad",
    "Telephone Number": "Número de Teléfono"
  },
  "sw": {
    "Field Contract Register": "Mkataba Wa Mazao",
    "Date of Birth": "Tarehe ya kuzaliwa",
    "Dependents": "Wategemezi",
    "Sex": "jinsia",
    "Level of education": "Miaka ya Masomo",
    "Home Address": "Anwani ya Makazi",
    "H.R Contract Date": "Tarehe ya Mkataba H.R",
    "Number of Contract": "Nambari ya Mkataba Uliokabidhiwa",
    "H.R Field Code": "Msimbo wa Sehemu H.R amepewa",
    "H.R Previous Fields Code": "Msimbo wa Sehemu Zilizopita za H.R",
    "Field Area": "Eneo la Mengi (Hekta)",
    "Total Number of Plants": "Jumla ya Mimea",
    "Seeds origin": "Asili ya Mbegu",
    "Hybrids": "Mseto",
    "Harvest specifications": "Maelezo ya Mavuno",
    "Harvest Implements": "Je, unatumia Vikapu shambani kwa mazao mengine?",
    "Yes": "Ndio",
    "No": "La",
    "Grinding Station": "Kituo Kilichokabidhiwa cha Kusaga",
    "Target Date for seeds planting": "Tarehe inayolengwa ya kupanda mbegu",
    "Target Date for seedlings planting: DDT": "Tarehe ya Kupandikiza Iliyokadiriwa: DDT",
    "Date of starting harvest": "Tarehe ya Kuanza Uzalishaji",
    "Maximum estimated production": "Uzalishaji Unaokadiriwa wa Juu - Jumla ya Kilo",
    "Minimum estimated Production": "Kiwango cha chini cha uzalishaji katika Kilo",
    "Date of Contract ending": "Tarehe ya Kusitishwa kwa Mkataba",
    "Pesos/U$ per Kilo": "Pesa kwa kila kilo ya kulipa (U$ au Pesos)",
    "Total Value of Contract": "Jumla ya thamani ya mkataba",
    "Extra Payment Good Production": "Malipo ya Ziada Uzalishaji Mzuri kwa Kilo (U$ au Pesos)",
    "Extra Payment for Transport": "Malipo ya Ziada ya Usafiri kwa Kilo (U$ au Pesos)",
    "Extra Payment for Hand Labor": "Malipo ya Ziada ya Kazi ya Mikono kwa kilo (U $ au Pesos))",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa",
    "targetDateSeedlingsPlantedHint": "Siku 35 za kalenda baada ya tarehe ya lengo la kupanda mbegu",
    "calculatedTargetDateHarvestHint": "Siku 120 za kalenda baada ya tarehe ya lengo la kupanda miche",
    "calculateMinimumEstimatedProduction": "20% ya uzalishaji wa kiwango cha juu",
    "calculateDateContractEnding": "Miezi 14 baada ya tarehe ya mkataba wa H.R",
    "calculateTotalValueContract": "Mfumo: $ kwa kilo x kiwango cha juu cha mkataba",
    "Contractor Type": "Aina ya Mkataba",
    "Legal Representative": "Mwakilishi wa kisheria",
    "Ballot": "Kura",
    "Variety": "Aina",
    "Contracted kilos": "Kilo zilizopunguzwa",
    "Stipulated Currency": "Sarafu Iliyoainishwa",
    "Quality of the seller in front of the property": "Ubora wa muuzaji mbele ya mali",
    "Third Clause Number 3.7": "Kifungu cha Tatu Namba 3.7",
    "Third Clause Number 3.8": "Kifungu cha Tatu Namba 3.8",
    "Clause Six Paragraph 2": "Kifungu cha Sita Kifungu cha 2",
    "Seventh Penal Clause": "Kifungu cha Saba cha Adhabu",
    "First Name": "Jina la kwanza",
    "Surname": "Jina la jina",
    "NIN Number": "Namba ya Kitambulisho",
    "Telephone Number": "Namba ya Simu"
  }
}
</i18n>
