<template>
  <div>
    <v-form v-model="validSubmission" ref="seedlingsAndTransplant">
      <Phase2Section1Register
          ref="form1"
          :data="formFields" />
      <Phase2Section2Sowing
          ref="form2"
          :data="formFields"/>
    </v-form>
  </div>
</template>

<script>


import Phase2Section1Register
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseTwo/Phase2Section1Register";
import Phase2Section2Sowing
  from "@/views/partners/hr_co/views/newTechnicalVisit/technicalVisitPhases/phaseTwo/Phase2Section2Sowing";
import {filterFormFieldsWithQuestions} from "@/views/partners/hr_co/utils";

export default {
  components: {Phase2Section1Register, Phase2Section2Sowing},
  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!to) {
        } else {
          this.formFields = to;
        }
      }
    },
    formFields:{
      deep: true,
      handler(to) {
        this.$emit('input', to);
      },
    },
    "isPhaseFormValid": function (newValue) {
      this.$emit('phase-validation', newValue)
    }
  },
  data() {
    return {
      validSubmission: false,
      selectedFormField: null,
      formFields: {
        register:{
          receptionDateOfSeedlings:{
            question: '1 ¿Fecha recepción plántulas?',
            answer: undefined
          },
          seedlingsReceived:{
            question: '2 ¿Cuantas plántulas recibió?',
            answer: undefined
          },
          fromWhichNursery:{
            question: '3 ¿Vivero de procedencia?',
            answer: undefined
          },
          typeOfTrays:{
            question: '4 ¿Tipo de Bandejas - Cantidad Alveolos?',
            answer: undefined
          },
          phytoSanitaryStatusOfSeedlings:{
            question: '5 ¿Estado fitosanitario de las plántulas al momento de recepción?',
            answer: undefined
          },
          howManyDaysYouHardenTheSeedlings:{
            question: '6 ¿Cuanto tiempo aclimató sus plántulas?',
            answer: undefined
          },
          neccessaryToCarryOutPreviousDisinfectionBeforeTranspalnting: {
            question: '3 ¿Es necesario realizar desinfección previa antes del trasplante.',
            answer: undefined
          },
          areYouReadyForPlanting:{
            question: '7 ¿Está listo para transplantar?',
            answer: undefined
          }

        },
        sowing:{
          dateOfTransplanting:{
            question: '1 ¿En que fecha sembró?',
            answer: undefined
          },
          // neccessaryToCarryOutPreviousDisinfectionBeforeTranspalnting: {
          //   question: '3 ¿Es necesario realizar desinfección previa antes del trasplante.',
          //   answer: undefined
          // },
          didEstablishWellAfterTransplanting:{
            question: '4 ¿Se establecieron bien las plantas después del transplante?',
            answer: undefined
          },
          mortalityRate:{
            question: '5 NO, ¿cual es la tasa de mortalidad?',
            answer: undefined
          },
          causeOfIt: {
            question:'6 ¿Cuando reemplazó las plantas perdidas?',
            answer: undefined
          },
          whenDidYouReplaceThePlants:{
            question: '7 ¿Cuando reemplazó las plantas perdidas?',
            answer: undefined
          }
        }
      }
    }
  },
  methods: {
    validate() {
      this.$refs.seedlingsAndTransplant.validate();
    },
    areFormFieldsFilled(formQuestions) {
      const formFieldsWithQuestions = filterFormFieldsWithQuestions(Object.assign([], formQuestions))
      return formFieldsWithQuestions.filter(item => !(item.answer === null || item.answer === undefined || item.answer === '')).length === formFieldsWithQuestions.length
    }

  },
  computed: {
    isPhaseFormValid() {
      return this.isSectionOneValid && this.isSectionTwoValid
    },
    isSectionOneValid() {
      return this.areFormFieldsFilled(Object.values(this.formFields.register))
    },
    isSectionTwoValid() {
      return this.areFormFieldsFilled(Object.values(this.formFields.sowing))
    }
  }
}

</script>

<style scoped>

</style>
