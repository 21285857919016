<template>

  <v-row>

    <v-col cols="12" sm="6">
      <v-card class="text-left" style="width: 100%;">
        <v-card-text>
          <p class="text-h4 text--primary">
            Quote Parameters
          </p>
          <v-container fluid class="text-h5">
            <v-row>
              <v-col>Cash</v-col>
              <v-col><CurrencyInput v-model="cash" /></v-col>
            </v-row>
            <v-row>
              <v-col>EFT</v-col>
              <v-col><CurrencyInput v-model="eft"  :disabled="cashMaxed" /></v-col>
            </v-row>
            <v-row>
              <v-col>Mobile Money</v-col>
              <v-col><CurrencyInput v-model="mobileMoney" :disabled="cashMaxed" /></v-col>
            </v-row>
            <v-row>
              <v-col>Maximum</v-col>
              <v-col>
                <ServerSideResult :test="maxPrincipal">
                  <Currency v-model="minPrincipal" />
                  to
                  <Currency v-model="maxPrincipal" />
                  over
                  {{maxTerm}}
                  months
                </ServerSideResult>
              </v-col>
            </v-row>
            <v-row>
              <v-col></v-col>
              <v-col v-if="maxForSelectedTerm">
                  <Currency v-model="maxForSelectedTerm" />
                  over
                  {{installments}}
                  months
                </ServerSideResult>
              </v-col>
            </v-row>
            <v-row>
              <v-col>Installments</v-col>
              <v-col class="d-flex justify-space-between">
                <SelectInput :items="installment_items" single-line v-model="installments" />
              </v-col>
            </v-row>
            <v-row>
              <v-col>Installments</v-col>
              <v-col>
                Repay on day {{ installmentDay }} of the month.
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" sm="6">
      <v-card class="text-left" style="width: 100%;">
        <v-card-text>
          <p class="text-h4 text--primary">
            Summary
          </p>
          <v-container fluid class="text-h5">
            <v-row :class="{'red--text': (principal>maxPrincipal)}">
              <v-col>New Principal</v-col>
              <v-col><Currency v-model="principal" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Nett Disbursement</v-col>
              <v-col><Currency v-model="pricing.netDisbursement" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Interest</v-col>
              <v-col><Currency v-model="pricing.interest" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Total Repayable</v-col>
              <v-col><Currency v-model="pricing.total_repayable" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Installment</v-col>
              <v-col><Currency v-model="pricing.installment" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Repay On</v-col>
              <v-col>On day {{ this.customer.employer.payload.payrollDay }} of the month</v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>First Repayment</v-col>
              <v-col><Time :time="pricing.repay_at" format="d MMM yyyy" /></v-col>
            </v-row>
            <v-row v-if="pricing">
              <v-col>Monthly Interest</v-col>
              <v-col>{{(pricing.interest_rate*100.0).toFixed(1)}} %</v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-btn color="success" style="width: 100%;" :disabled="!valid" @click="$emit('agreed')">
        Submit
      </v-btn>
    </v-col>

  </v-row>



</template>

<script>
import lookups from '@/mixins/lookups';

import Time from '@/components/Time';
import CurrencyInput from "@/components/CurrencyInput";
import TextInput from "@/components/TextInput";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import SelectInput from "@/components/SelectInput";

export default {

  components: { Time, CurrencyInput, TextInput, Currency, ServerSideResult, SelectInput },

  props: ['value', 'customer', 'loanParameters'],

  data() {
    return {
      mobileMoney: 0,
      cash: 0,
      eft: 0,
      minPrincipal: 100000,
      pricing: undefined,
      installments: undefined,

      installment_items: [
        { text: 'One Installment',    value: 1  },
        { text: 'Two Installments',   value: 2  },
        { text: 'Three Installments', value: 3  },
        { text: 'Four Installments',  value: 4  },
        { text: 'Five Installments',  value: 5  },
        { text: 'Six Installments',  value: 6  },
        { text: 'Seven Installments',  value: 7  },
      ],
    }
  },

  computed: {
    maxPrincipal() { return ((this.loanParameters || {}).loan_amount || 0); },
    maxTerm() { return ((this.loanParameters || {}).duration_months || 0); },
    maxForSelectedTerm() { return (this.pricing ? this.pricing.limit : undefined) },

    principal() { return this.mobileMoney + this.cash + this.eft },
    cashMaxed() { return this.cash==this.maxForSelectedTerm; },
    netDisbursement() { return this.principal*(1-this.interest_rate) },
    interest() { return this.principal*this.interest_rate; },
    repayable() { return this.principal; },
    installmentDay() { return this.customer.employer.payload.payrollDay; },
    valid() {
      let r = true;
      if (r) { r = (this.principal >= this.minPrincipal); }
      if (r) { r = !!this.maxPrincipal; }
      if (r) { r = (this.principal <= (this.maxForSelectedTerm || this.maxPrincipal) ); }
      return r;
    },

    results() {
      let r = undefined;
      if (this.valid && this.pricing) {
        r = {
          uuid: this.customer.uuid,
          principal: this.pricing.principal,
          interest_rate: this.pricing.interest_rate,
          installments: this.installments,
          installment: this.pricing.installment,
          repay_at: this.pricing.repay_at,
          cash: this.cash,
          eft: this.eft,
          mobileMoney: this.mobileMoney,
        }
      }
      return r;
    },
  },

  watch: {
    principal(to) {
      this.setPricing();
    },
    installments(to) {
      this.setPricing();
    },
    maxForSelectedTerm(to) {
      if (to && (to<this.principal)) {
        this.mobileMoney = 0;
        this.eft = 0;
        this.cash = to;
      }
    },
    results: {
      deep: true,
      handler(to) {
        this.$emit('input', to);
      }
    },
  },

  methods: {
    setPricing() {
      if (this.valid) {
        this.remoteLookup('ultimateTzCheckMeBackPricing', { uuid: this.customer.uuid, principal: this.principal, installments: this.installments }).then( pricing => this.pricing = pricing ).catch(e => this.pricing = null);
      } else {
        this.pricing = undefined;
      }   
    },
  },

  mounted() {
    this.cash = this.maxPrincipal;
    this.installments = this.loanParameters.duration_months;
    this.setPricing();
  },

  mixins: [lookups],

}
</script>
