<template>
  <div>

    <v-icon v-if="syncAssessmentStatus" class="icon-sync" dense :title="title">mdi-cloud-sync-outline</v-icon>
    <v-icon v-else :color="colour" dense :title="title">mdi-cloud-sync-outline</v-icon>
  </div>
</template>

<script>

export default {

  computed: {
    unsyncedChanges() {
      return this.$store.getters.syncAssessmentStatus;
    },
    syncAssessmentStatus() {
      return this.$store.getters.syncAssessmentStatus;
    },
    colour() {
      let r = 'primary'
      if (this.unsyncedChanges>5) { r = 'error' }
      return r;
    },
    title() {
      let r = null;
      const changes = this.unsyncedChanges;
      if (changes==0) {
        r = 'Up to date.'
      } else {
        r = 'Changes not uploaded: ' + changes;
      }
      return r;
    }
  },

  methods: {
    forceSync() {
//      this.$store.dispatch('reinitialise');
    },
  }

};
</script>

<style scoped>
/* Standard syntax */
@keyframes backgroundColorPalette {
  0% {
    color: #ee6055;
  }
  25% {
    color: #60d394;
  }
  50% {
    color: #aaf683;
  }
  75% {
    color: #ffd97d;
  }
  100% {
    color: #ff9b85;
  }
}


.icon-sync {
  animation-name: backgroundColorPalette;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

</style>
