<template>
  
  <v-container fluid>

    <v-row>
      <v-col cols="4">
        <v-radio-group v-model="target" row >
          <v-radio label="Send to Loan Consultant" value="consultant" />
          <v-radio label="Send to Safe Box" value="safe" />
        </v-radio-group>
      </v-col>
      <v-col cols="4" class="text-left">
        <span v-if="target=='safe'" class="text-h4">Transfer to Safe Box</span>
        <FieldAgentInput v-else v-model="recipient" label="Loan Consultant" online />
      </v-col>
      <v-col cols="4">
        <CurrencyInput v-model="cash" label="Cash Receipted" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="success" style="width: 100%;" small dense @click="$emit('submit')" :disabled="!cash">Send</v-btn>
      </v-col>
    </v-row>


  </v-container>

</template>

<script>
import FieldAgentInput from "@/components/FieldAgentInput";
import CurrencyInput from "@/components/CurrencyInput";

export default {

  components: {  FieldAgentInput, CurrencyInput },

  data() {
    return {
      recipient: undefined,
      target: 'consultant',
      cash: 0,
    }
  },

  computed: {
    result() {
      let r = {
        recipient: this.recipient,
        target: this.target,
        cash: this.cash,
      }
      return r;
    }
  },

  watch: {
    result: {
      deep: true,
      handler(to) {
        this.$emit('input', to);
      }
    },
  },

}
</script>
