<template>
  <UltimateTz v-if="partnerCode==='ultimate_tz'" />
  <Default v-else />
</template>

<script>
  import Default from '@/views/businesses/partners/Create';
  import UltimateTz from "@/views/businesses/partners/ultimate_tz/Create";

  export default {
    components: { Default, UltimateTz },
    computed: { partnerCode() { return this.$store.getters.settings.partnerCode; }, },
  }
</script>
