<template>
  <v-form ref="fingerprintForm" v-model="valid" class="text-left" >

    <v-row>
      <v-col cols="12" md="2">
        WIP
      </v-col>
    </v-row>


  </v-form>

</template>

<script>
import MsisdnInput from "@/components/MsisdnInput";
import NameInput from "@/components/NameInput";
import TitleInput from "@/components/TitleInput";

export default {

  components: { NameInput, MsisdnInput, TitleInput },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: true,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.customer = to;
      }
    },
    customer(to) { this.$emit('input', to); },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
      this.$refs.fingerprintForm.validate();
    },
  },

}
</script>
