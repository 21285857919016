<template>

  <v-sheet :elevation="elevation" :color="sheetColor" :rounded="rounded" class="pa-2" @click="$emit('click')">
    <span class="mr-2">{{label}}</span>
    <slot></slot>
  </v-sheet>

</template>

<script>

export default {

  props: {
    label: String,
    highlight: [String, Boolean],
    cents: {
      type: Boolean,
      default: true,
    },
    width: String,
    color: String,
    elevation: {
      type: Number,
      default: 2,
    },
    rounded: {
      type: Boolean,
      default: true,
    }
  },

  computed: {
    sheetColor() {
      let r = this.color;
      let s = ((typeof(this.highlight)=='string') && (this.highlight==this.label));
      let b = ((typeof(this.highlight)=='boolean') && this.highlight);
      if (s || b) { r = 'success'; }
      return r;
    },
  },

}
</script>
