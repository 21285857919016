<template>
  <v-form ref="otherForm" v-model="valid" >

    <v-row>
      <v-col cols="12" md="4">
        <v-select :items="['Bank of Tanzania', 'Access Bank']" label="Bank" v-model="customer.bank_name" :rules="[(v) => !!v || 'Required']" />
      </v-col>
      <v-col cols="12" md="4">
        <v-select :items="['Arusha', 'Dar es Salaam']" label="Branch" v-model="customer.branch_name" :rules="[(v) => !!v || 'Required']" />
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field label="Account Number" v-model="customer.account_number" :rules="[(v) => !!v || 'Required']" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-select :items="sources" label="How did you hear about Ultimate Finance?" v-model="customer.lead_source" :rules="[(v) => !!v || 'Required']" />
            </v-col>
            <v-col cols="12" md="6" class="d-flex align-center" v-if="customer.lead_source=='Referred'">
              <CustomerSearchInput label="Referred By" v-model="customer.referred_by" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12" md="6" class="d-flex align-center">
        <MapInput ref="map" v-model="customer.home_location" label="Home Location" required style="width: 100%;" />
      </v-col>
    </v-row>

  </v-form>

</template>

<script>
import CustomerSearchInput from "@/components/CustomerSearchInput";
import MapInput from "@/components/MapInput";

export default {

  components: { CustomerSearchInput, MapInput },

  props: ['value'],

  data() {
    return {
      customer: undefined,
      valid: false,
      sources: [ 'Direct Marketing', 'Referred', 'Radio', 'Social Media', 'Website' ],
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(to) { this.customer = to; }
    },
    
    customer(to) {
      this.$emit('input', to);
    },
    valid(to) { this.$emit('valid', to); },
  },

  methods: {
    validate() {
      this.$refs.otherForm.validate();
    },
    invalidateMap() {
      this.$refs.map.invalidate();
    }
  },

}
</script>
