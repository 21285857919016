<template>
  <v-form v-model="valid">

    <v-row>
      <v-col cols="6">
        <v-btn color="primary" width="100%">
          Print Contract
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn color="primary" width="100%">
          Print Loan Application Form
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <DocumentInput v-model="contract" label="Contract" type="contract" :typeId="customer.uuid" accept=".pdf" xrequired caption="Drag the signed contract PDF into this box, or click to upload it." />
      </v-col>
      <v-col cols="6">
        <DocumentInput v-model="application" label="Application" type="contract" :typeId="customer.uuid" accept=".pdf" xrequired caption="Drag the signed application PDF into this box, or click to upload it." />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="success" style="width: 100%;" :disabled="!valid" @click="$emit('uploaded')">
          Continue
        </v-btn>
      </v-col>
    </v-row>

  </v-form>

</template>

<script>

import DocumentInput from "@/components/DocumentInput";

export default {

  components: { DocumentInput },

  props: ['customer'],

  data() {
    return {
      valid: false,
      contract: undefined,
      application: undefined,
    }
  },

  watch: {
    loanParameters(to) { this.$emit('input', to); }
  },

}
</script>
