<template>
  <v-input :rules="[requiredRule]" v-model="imageId">
    <div class="flex-column">
      <SignatureModal @saveImage="saveImage" >
        <template #dialogButton="{ on, attrs }" >
          <v-btn
              :color="!imageId ? 'primary' : 'success'"
              dark
              v-bind="attrs"
              v-on="on"
          >
            {{$t('signDocument')}}
          </v-btn>
        </template>
      </SignatureModal>

      <v-card outlined style="" v-if="showImage">
        <SingleImage v-if="!!imageObject" :image="imageObject" maxHeight="200px"  />
      </v-card>
      <slot v-else>

      </slot>
    </div>

  </v-input>
</template>

<script>
import TakePhoto from '@/components/TakePhoto';
import SignatureModal from "@/components/SignatureComponent/SignatureModal";
import SingleImage from "@/components/SingleImage";

export default {

  components: {
    TakePhoto,
    SignatureModal,
    SingleImage
  },

  props: {
    value: String,
    label: String,
    required: Boolean,
    showImage: Boolean,
    type: {
      type: String,
      required: true,
    },
    typeId: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    imageId: undefined,
    validationProp: false,
    imageObject: null,
  }),

  computed: {
    caption() {
      return this.label || this.$t(this.type.replace('_',' '));
    },
    color() {
      return ( this.imageId ? 'success' : 'primary' );
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(to) {
        if (!to) {
          this.imageId = to;
        }
      }
    },
    imageId(to) {
      this.$emit('input', to);
    },
  },

  methods: {
    saveImage(uuid) {
      console.log('saveImage', uuid)
      this.imageId = uuid;
      const imageObject = { type: this.type, type_id: this.typeId, uuid: uuid }
      this.imageObject = imageObject
      this.$store.dispatch('addImage', imageObject);
      this.$emit("saveImage", imageObject)
    },
    requiredRule(value) {
      let r = [
        v => !this.required || !!((v || '').trim()) || this.$t('required'),
      ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
      return ( typeof(r)==='string' ? r : true );
    },
  }
}
</script>

<i18n>
{
  "en": {
    "signDocument": "Sign Document"
  },
  "es": {
    "signDocument": "firmar documento"
  },
  "sw": {
    "signDocument": "saini Waraka"
  }
}
</i18n>
