<template>
  <v-card>
    <div no-gutters v-if="images.length>0">
      <Images :images="images" />
    </div>

    <v-card-title>
      {{$t('title')}}
      <v-spacer />
      <v-btn color="primary" small dense @click="edit">{{$t('edit')}}</v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <Row :title="$t('name')" :text="customer.name" bold="true" v-if="customer.name" data-cy="customerName"/>
            <Row :title="$t('telephone')" :msisdn="customer.msisdn"  data-cy="msisdn"/>
            <Row :title="$t('partnerIdentifier')" :code="customer.partnerIdentifier" v-if="customer.partnerIdentifier" />
            <Row :title="$t('group')" :text="customer.savingsGroup.name" v-if="customer.savingsGroup" />
            <Row :title="(customer.demographic.primaryIdentityType || customer.demographic.identityType) || id_number_title" :identityNumber="customer.demographic" v-if="identityNumber"/>
            <Row :title="$t('tags')" v-if="segmentList.length>0" >
              <v-chip v-for="segment in segmentList" :key="segment" small class="mr-1" >{{ segment }}</v-chip>
            </Row>
            <Row :title="$t('balance')" v-if="show('agri_balance')" >
              <ServerSideResult :test="balance">
                <Currency :value="balance" />
              </ServerSideResult>
            </Row>
            <Row :title="$t('urbanFarmerUnits')" v-if="['urban_zm','urban_ke'].includes(partnerCode)" >
              <ServerSideResult :test="urbanFarmerUnits">
                <NumberFormatter :value="urbanFarmerUnits" unit="units" />
              </ServerSideResult>
            </Row>
            <Row :title="$t('Identity')" :text="customer.demographic.identity.number"  data-cy="partnerIdentifier"/>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>


    <v-card-actions>

<!--      <TakePhoto @click="saveImage">-->
<!--        <v-btn-->
<!--            color="primary"-->
<!--            small-->
<!--            dense-->
<!--            class="ml-1">-->
<!--          <v-icon dense class="mr-1">mdi-camera-outline</v-icon>-->
<!--          <span class="hidden-sm-and-down">{{$t('photo')}}</span>-->
<!--        </v-btn>-->
<!--      </TakePhoto>-->
<!--      <TakePhoto :text="$t('idImageInfo')" @click="idImage">-->
<!--        <v-btn-->
<!--            color="primary"-->
<!--            small-->
<!--            dense-->
<!--            class="ml-1">-->
<!--          <v-icon dense class="mr-1">mdi-camera-enhance-outline</v-icon>-->
<!--          {{$t('id')}}-->
<!--          <span class="hidden-sm-and-down">{{$t('photo')}}</span>-->
<!--        </v-btn>-->
<!--      </TakePhoto>-->

    </v-card-actions>
  </v-card>
</template>


<script>
  import lookups from '@/mixins/lookups';
  import displaySwitches from '@/mixins/displaySwitches';

  import Row from '@/components/Row';
  import Currency from '@/components/Currency';
  import NumberFormatter from '@/components/NumberFormatter';
  import ServerSideResult from '@/components/ServerSideResult';
  import TakePhoto from "@/components/TakePhoto";
  import Images from "@/components/Images";

  export default {

    components: { Row, Currency, NumberFormatter, ServerSideResult, TakePhoto, Images },

    props: ['customer', 'firstVisit', 'images'],
    
    data: () => ({
      balance: undefined,
      urbanFarmerUnits: undefined,
    }),

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      identityNumber() { return (this.customer.demographic ? this.customer.demographic.identityNumber : null); },
      primaryLocation() { return (this.customer.demographic && this.customer.demographic.keyLocations ? this.customer.demographic.keyLocations['region'] || this.customer.demographic.keyLocations['work'] || this.customer.demographic.keyLocations['home'] : null); },
      segmentList() { return this.customer.segmentList || []; },
      fieldAgent() { return (this.customer.fieldAgent ? this.customer.fieldAgent.name : null) },
      assessments() {
        return this.$store.getters.assessments
      }
    },

    watch: { 
      customer: {
        immediate: true,
        handler(to) {
          if (this.show('agri_balance')) {
            this.remoteLookup('customerBalance', { uuid: this.customer.uuid }).then( balance => this.balance = balance ).catch(e => this.balance = null);
          }
          if (['urban_zm','urban_ke'].includes(this.partnerCode)) {
            this.remoteLookup('urbanFarmerPriorUnits', { uuid: this.customer.uuid }).then( priorUnits => this.urbanFarmerUnits = priorUnits ).catch(e => this.urbanFarmerUnits = null);
          }
        },
      },
    },

    methods: {
      edit() {
        if (this.partnerCode==='nucafe_ug') {
          this.$router.push({name: 'NuCafeUgRegisterContact', query: { uuid: this.customer.uuid }});
        } else {
          this.$router.push({name: 'EditCustomer', query: { uuid: this.customer.uuid }});
        }
      },
      deleteItem() {
        const foundItem = this.assessments.filter(assessment => assessment.assessmentUuid !== this.firstVisit.assessmentUuid)
        this.$store.commit('setAssessments', foundItem);
      },

      saveImage(uuid) {
        this.$store.dispatch('addImage', { type: 'customer',    type_id: this.customer.uuid, uuid: uuid });
      },

      idImage(uuid) {
        this.$store.dispatch('addImage', { type: 'demographic', type_id: this.customer.uuid, uuid: uuid });
      },
    },

    mixins: [lookups, displaySwitches],

  }
</script>


<i18n>
{
  "en": {
    "title": "Details",
    "name": "Name",
    "telephone": "Telephone Number",
    "partnerIdentifier": "Your Identifier",
    "group": "Group",
    "tags": "Tags / Segments",
    "balance": "Balance",
    "urbanFarmerUnits": "Units Purchased",
    "firstVisit": {
      "title": "First Visit",
      "viewFirstVisit": "View First Visit",
      "startFirstVisit": "start First Visit"
    }
  },
  "es": {
    "title": "Informacion Personal",
    "name": "Nombre",
    "telephone": "Número de Teléfono",
    "partnerIdentifier": "Tu identificador",
    "group": "Grupo",
    "tags": "Etiquetas / Segmentos",
    "balance": "Saldo",
    "urbanFarmerUnits": "Unidades compradas",
    "firstVisit": {
      "title": "Primera Visita",
      "viewFirstVisit": "ver primera visita",
      "startFirstVisit": "iniciar la primera visita"
    }
  },
  "sw": {
    "title": "Details",
    "name": "Jina",
    "telephone": "Namba ya Simu",
    "partnerIdentifier": "Kitambulisho",
    "group": "Kikundi",
    "tags": "Lebo / Sehemu",
    "balance": "Usawa",
    "urbanFarmerUnits": "Vitengo Vimenunuliwa",
    "firstVisit": {
      "title": "ziara ya kwanza",
      "viewFirstVisit": "angalia ziara ya kwanza",
      "startFirstVisit": "Anza ziara ya kwanza"
    }
  }
}
</i18n>
