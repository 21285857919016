<template>

  <div class="ml-auto align-self-end">
    <div class="text-right hidden-xs-only">
      <v-btn
      data-type="save-button"
      color="warning"
      small
      dense
      class="ma-1"
      @click="() => $emit('validate')">
        {{$t('validate')}}
      </v-btn>
      <v-btn
      :disabled="!isValid"
      data-type="save-button"
      color="warning"
      small
      dense class="ma-1"
      @click="() =>$emit('submit')"
      >
        {{$t('save')}}
      </v-btn>
    </div>

  </div>

</template>

<script>
export default {
  props: ['isValid'],

}
</script>

<style scoped>

</style>
