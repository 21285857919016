<template>
  <PageHeader
      title="Por Aprobar"
      :subtitle="customer.name"
      icon="mdi-flower-outline"
      @back="toCustomer"
      @save="submit"
      @validate="validate"
      back
      save
      validate
      :valid='formValid' >

    <v-form
        ref="firstVisitRef"
        v-model="selectedFormValid">

      <FormContainer color="blue lighten-5 text-left pa-2">
          <div class="row">
            <div cols="12" class="col-sm-4">
              <v-card  tile>
                <v-card-title>{{ customer.name }}</v-card-title>
              </v-card>
            </div>
          </div>

        <v-row class="">
          <v-col cols="12" md="4">
            <DateInput
              class="w-100"
              persistent-hint
              v-model="formFields.visitDate.answer"
              :label="formFields.visitDate.question"
              :min="
                new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
              :max="
                new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
              :rules="[sharedValidations.requiredValidation]"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="3">
            <DateInput
              v-model="formFields.demographic.birthdate.answer"
              :label="formFields.demographic.birthdate.question"
              min="1910-01-01"
              :max="new Date().toISOString().substr(0, 10)"
              :year="true"
              :rules="[sharedValidations.requiredValidation]"
            />
          </v-col>

          <v-col cols="12" sm="2">
            <v-select
              v-model="formFields.demographic.dependents.answer"
              :label="formFields.demographic.dependents.question"
              :items="[0,1,2,3,4,5,6,7,8]"
              :rules="[sharedValidations.requiredValidation]"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="4">
            <GenderInput
                :rules="[sharedValidations.requiredValidation]"
                v-model="formFields.demographic.gender.answer" />
          </v-col>

          <v-col cols="12" sm="3" >
            <v-select
              v-model="formFields.demographic.schooling.answer"
              :label="formFields.demographic.schooling.question"
              :items="[
                  'Primaria',
                  'Secundaria',
                  'Universitaria',
                  'Postgrado'
              ]"
              :rules="[sharedValidations.requiredValidation]"
            ></v-select>
          </v-col>

        </v-row>


        <v-row>
          <v-col cols="12" sm="3" >
<!--            mdiCheck-->
            <div class="d-flex">
              <!-- <v-icon v-if="profileImagesUploaded" dense small>mdi-check</v-icon> -->
              <v-subheader>Foto de perfil
              </v-subheader>
            </div>
            <div v-if="formFields.demographic.attachedProfileImages && formFields.demographic.attachedProfileImages.length>0">
              <SingleImage v-for="image in formFields.demographic.attachedProfileImages" :image="image" :maxHeight="150" />
            </div>
            <TakePhoto @click="saveProfileImage">
              <v-btn
                  color="primary"
                  small
                  dense
                  class="ml-1">
                <v-icon dense class="mr-1">mdi-camera-outline</v-icon>
                <span class="hidden-sm-and-down">{{$t('photo')}}</span>
              </v-btn>
            </TakePhoto>

            <TakePhoto :text="$t('idImageInfo')" @click="idImage">
              <v-btn
                  color="primary"
                  small
                  dense
                  class="ml-1">
                <v-icon dense class="mr-1">mdi-camera-enhance-outline</v-icon>
                {{$t('id')}}
                <span class="hidden-sm-and-down">{{$t('photo')}}</span>
              </v-btn>
            </TakePhoto>
          </v-col>

        </v-row>
        <hr class="mb-4 mt-2" />
          <div class="row">

            <div class="col-sm-4">
              <v-card-subtitle>1. {{ $t("registerForm.form.fieldPhoto") }}</v-card-subtitle>
              <v-card-text>
                <SingleImage :image="formFields.farmImage" :maxHeight="150" />
                <TakePhoto @click="saveFarmImage">
                  <v-btn color="primary" small dense class="ml-1"
                    ><v-icon dense class="mr-1">mdi-camera-outline</v-icon>
                    <span class="hidden-sm-and-down">{{
                      $t("photo")
                    }}</span></v-btn
                  >
                </TakePhoto>
              </v-card-text>
            </div>

            <div cols="12" class="col-sm-4">
              <v-card-subtitle>2. {{$t("registerForm.form.fieldGps") }}</v-card-subtitle>
              <v-card-text>
                <LocationInput v-model="farmGPS" />
              </v-card-text>
            </div>

            <div cols="12" class="col-sm-4">
              <v-card-subtitle>3. {{$t("registerForm.form.areaOfFarm") }}</v-card-subtitle>
              <v-card-text>

                <LandSizeInput v-model="landSize.answer" />
              </v-card-text>
            </div>
          </div>

        <v-col cols="12" md="4">
          <v-combobox
            persistent-hint
            :items="[
                'Platano',
                'Yuca',
                'Cítricos',
                'Aguacate',
                'Pastos y Praderas',
                'Caña',
                'Papaya',
                'Melón',
                'Sandia',
                'Maíz',
                'Soya',
                'Maracay',
                'Uva',
                'Arróz',
                'Otro'
            ]"
            :label="formFields.previousCrop.question"
            single-line
            v-model="formFields.previousCrop.answer"
            clearable
            :rules="[sharedValidations.requiredValidation]"
          ></v-combobox>
          <v-text-field
              :label="$t('form.label.extraObservation')"
              v-model="formFields.previousCrop.extraObservationAnswer"
              :rules="[sharedValidations.requiredValidation]"
          ></v-text-field>

        </v-col>

          <hr class="mb-4" />

        <v-row>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.fieldCharacteristics.answer"
                :title="ratingFormFields.fieldCharacteristics.label"
                :description="ratingFormFields.fieldCharacteristics.description"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.altitudeAboveSeaLevel.answer"
                :title="ratingFormFields.altitudeAboveSeaLevel.label"
                :description="ratingFormFields.altitudeAboveSeaLevel.description"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.zonaSeca.answer"
                :title="ratingFormFields.zonaSeca.label"
                :description="ratingFormFields.zonaSeca.description"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.disponibilidadDeAguas.answer"
                :title="ratingFormFields.disponibilidadDeAguas.label"
                :description="ratingFormFields.disponibilidadDeAguas.description"
            />

            <v-combobox
              v-model="ratingFormFields.disponibilidadDeAguas.extraObservationAnswer"
              :items="[
                  'Pozo',
                  'Aljibe',
                  'Río',
                  'Quebrada',
                  'Reservorio',
                  'Goteo',
              ]"
              label="Seleccionar del menú o escribir nuevo"
              :rules="[sharedValidations.requiredValidation]"
            ></v-combobox>

          </v-col>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.analisisDeSuelos.answer"
                :title="ratingFormFields.analisisDeSuelos.label"
                :description="ratingFormFields.analisisDeSuelos.description"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.tierrasPropias.answer"
                :title="ratingFormFields.tierrasPropias.label"
                :description="ratingFormFields.tierrasPropias.description"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.riegosPorGoteo.answer"
                :title="ratingFormFields.riegosPorGoteo.label"
                :description="ratingFormFields.riegosPorGoteo.description"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.manoDeObraParaCosecha.answer"
                :title="ratingFormFields.manoDeObraParaCosecha.label"
                :description="ratingFormFields.manoDeObraParaCosecha.description"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.agricultorConExperiencia.answer"
                :title="ratingFormFields.agricultorConExperiencia.label"
                :description="ratingFormFields.agricultorConExperiencia.description"
                />
          </v-col>
          <v-col cols="12" sm="4">
            <StarRatingCard
                v-model="ratingFormFields.recursosEconomicos.answer"
                :title="ratingFormFields.recursosEconomicos.label"
                :description="ratingFormFields.recursosEconomicos.description"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              :label="formFields.observations.question"
              v-model="formFields.observations.answer"
              auto-grow
              :rules="[sharedValidations.requiredValidation]"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-col cols="12" md="4">
          <v-combobox
            persistent-hint
            :items="crops"
            :label="formFields.selectedChilliVariety.question"
            :hint="formFields.selectedChilliVariety.question"
            single-line
            v-model="formFields.selectedChilliVariety.answer"
            clearable
            :rules="[sharedValidations.requiredValidation]"
          ></v-combobox>
        </v-col>

        <v-col cols="12" sm="4">
          <v-radio-group
            :label="formFields.interestedInSigninContract.question"
            persistent-hint
            row
            v-model="formFields.interestedInSigninContract.answer"
            clearable
            :rules="[sharedValidations.requiredValidation]"
          >
            <v-radio label="Si" value="Sí"></v-radio>
            <v-radio label="No" value="No"></v-radio>
          </v-radio-group>
        </v-col>

        <v-text-field
            v-if="['no', 'No'].includes(formFields.interestedInSigninContract.answer)"
            :label="$t('form.label.extraObservation')"
            v-model="formFields.interestedInSigninContract.extraObservationAnswer"
            :rules="[sharedValidations.requiredValidation]"
        ></v-text-field>


      </FormContainer>

<!--      <v-card class="col-4 text-left mb-2" tile>-->
<!--        <v-subheader>{{$t('hr.validations')}}</v-subheader>-->
<!--        <v-list-item>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>{{$t('rating')}}</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--          <v-list-item-action>-->
<!--            <v-icon v-if="ratingFormValid" color="grey lighten-1">mdi-check</v-icon>-->
<!--            <v-icon v-else color="grey lighten-1">mdi-close</v-icon>-->
<!--          </v-list-item-action>-->
<!--        </v-list-item>-->

<!--        <v-list-item>-->
<!--          <v-list-item-content>-->
<!--            <v-list-item-title>{{$t('demographic')}}</v-list-item-title>-->
<!--          </v-list-item-content>-->
<!--          <v-list-item-action>-->
<!--            <v-icon v-if="demographicFormValid" color="grey lighten-1">mdi-check</v-icon>-->
<!--            <v-icon v-else color="grey lighten-1">mdi-close</v-icon>-->
<!--          </v-list-item-action>-->
<!--        </v-list-item>-->
<!--      </v-card>-->
    </v-form>
  </PageHeader>
</template>

<style scoped>

</style>
<script>
import { v4 as uuidv4 } from "uuid";

import PageHeader from "@/components/PageHeader";
import FormContainer from "@/components/FormContainer";
import StarRatingCard from "@/components/StarRatingCard";
import TakePhoto from "@/components/TakePhoto";
import SingleImage from "@/components/SingleImage";
import LocationInput from "@/components/LocationInput";
import LandSizeInput from "@/components/LandSizeInput";
import Pests from "@/views/partners/hr_co/components/Pests";
import Row from "@/components/Row";
import {areFormFieldsFilled, totalPrimeraVisitaRatingsSummary} from "@/views/partners/hr_co/utils";
import {assessmentNameEnum} from "@/views/partners/hr_co/constant";
import DateInput from "@/components/DateInput";
import {HugoValidations} from "@/views/partners/hr_co/hrCoMixins/basicValidations";
import GenderInput from "@/components/GenderInput";
import Images from "@/components/Images";
import ImageUploadTypeEnums from "@/mixins/ImageUploadTypeEnums";



export default {
  components: {
    LandSizeInput,
    PageHeader,
    FormContainer,
    StarRatingCard,
    TakePhoto,
    SingleImage,
    LocationInput,
    Pests,
    DateInput,
    Row,
    GenderInput,
    Images
  },
  data: () => ({
    farmGPS: undefined, // todo fix gps
    selectedFormValid: false,
    assessment: {
      assessmentUuid: uuidv4(),
      assessmentName: assessmentNameEnum.primeraVisita,
      createdAt: new Date(),
      farmImage: null
    },
    landSize: {
      question: '',
      answer: {
        landSize:"",
        unit:"Hectares"
      }
    },
    sharedValidations: new HugoValidations(),
    pestsAndDiseaseRecommendations: [],
    selectedFarmField: null,
    ratingFormFields: {
      fieldCharacteristics: {
        label: 'Ubicación terreno',
        description: '¿Es el campo susceptible a inundaciones o represamiento de agua? NO: calificación 5',
        weight: 13,
        weightAsc: false,
        answer: null
      },
      altitudeAboveSeaLevel: {
        label: 'Altura sobre nivel del mar',
        description: '¿La altitud sobre el nivel del mar es ideal para cultivar la variedad de chile recomendada? SI: Calificación 5',
        weight: 8,
        weightAsc: true,
        answer: null
      },
      zonaSeca: {
        label: 'Zona seca',
        description: '¿¿Hay un nivel bajo de precipitación de agua de lluvia? Sí: Calificación 5',
        weight: 10,
        weightAsc: true,
        answer: null
      },
      disponibilidadDeAguas: {
        label: 'Disponibilidad de aguas',
        description: '¿La finca tiene buena disponibilidad de agua (ríos, arroyos, embalses)? Sí: Calificación 5',
        weight: 12,
        weightAsc: true,
        answer: null,
        menuAnswer: '',
        extraObservationAnswer: ''
      },
      analisisDeSuelos: {
        label: 'Analisis de suelos',
        description: 'El suelo tiene - 40% de arcilla o buena capacidad de drenaje? Sí: Calificación 5',
        weight: 12,
        weightAsc: true,
        answer: null
      },
      tierrasPropias: {
        label: 'Tierras propias',
        description: '¿El agricultor es dueño de la tierra o tiene un contrato de renta regular? Sí: Calificación 5',
        weight: 5,
        weightAsc: true,
        answer: null
      },
      riegosPorGoteo: {
        label: 'Riegos por goteo',
        description: '¿El campo/finca ya tiene instalado un sistema de riego por goteo? Sí: Calificación 5',
        weight: 13,
        weightAsc: true,
        answer: null
      },
      manoDeObraParaCosecha: {
        label: 'Mano de obra para cosecha',
        description: '¿Hay suficiente mano de obra cerca o en la finca para los períodos de siembra y cosecha? Sí: Calificación 5',
        weight: 9,
        weightAsc: true,
        answer: null
      },
      agricultorConExperiencia: {
        label: 'Agricultor con experiencia',
        description: 'El agricultor tiene buena experiencia agrícola? Sí: Calificación 5',
        weight: 9,
        weightAsc: true,
        answer: null
      },
      recursosEconomicos: {
        label: 'Recursos economicos',
        description: '¿El agricultor tiene buenos recursos financieros? Sí: Calificación 5',
        weight: 9,
        weightAsc: true,
        answer: null
      },
    },
    formFields: {
      farmImage: null,
      demographic: {
        birthdate: {
          question: 'Fecha de Nacimiento',
          answer: null,
        },
        dependents: {
          question: 'Personas a Cargo en Familia',
          answer: null,
        },
        gender: {
          question: 'Sexo',
          answer: null,
        },
        schooling: {
          question: 'Nivel de Escolaridad',
          answer: null,
        },
        attachedProfileImages: []
      },
      visitDate: {
        question: 'Fecha Visita',
        answer: ''
      },
      previousCrop: {
        question: 'Cultivos anteriores en el lote (Dos años)',
        answer: '',
        extraObservationAnswer: ''
      },
      selectedChilliVariety: {
        question: 'Variedad de Ají definida',
        answer: ''
      },
      interestedInSigninContract: {
        question: 'Interesado en firmar Contrato?',
        answer: '',
        extraObservationAnswer: ''
      },
      observations: {
        question: 'Observaciones',
        answer: ''
      },
    },
    // attachedImage: null,
  }),
  mounted() {
    if (this.firstVisitToEdit) {
      this.formFields = {...this.formFields, ...this.firstVisitToEdit.formFields}
      this.ratingFormFields = this.firstVisitToEdit.ratingFormFields.ratings
      if (!this.formFields.demographic.attachedProfileImages) {
        this.formFields.demographic.attachedProfileImages = [];
      }
    }
  },
  computed: {
    customerUuid() { return this.$route.query.uuid; },
    fields() {
      return (this.customer.fields || []).filter(field => field.deleted===undefined ) ;
      },
    customer() { return this.$store.getters.customer(this.customerUuid); },
    farm() {
      return this.customer.farm;
    },
    firstVisitUuid() {
        return this.$route.query.firstVisitUuid;
      },
    firstVisitToEdit() {
      return this.$store.getters.assessments.find(assessment =>
          assessment.assessmentUuid === this.firstVisitUuid &&
          assessment.customerUuid === this.customerUuid
      )
    },
    ratingFormValid() {
      const emptyRatingAnswers = Object.values(this.ratingFormFields).filter(formField => !formField.answer)

      return emptyRatingAnswers.length === 0
    },
    formValid() {
      return this.ratingFormValid && this.selectedFormValid
    }, // we already have two elements in the set: Observations and uuid
    // firstVisitImage() {
    //   // todo client wants validation removed

    //   return this.assessment.farmImage
    // },
    crops() { return this.$store.getters.crops.map(crop => crop.text); },
    // profileImagesUploaded() {
    //   // todo client wants validation removed
    //   const profileImage = this.formFields.demographic.attachedProfileImages.find(profileImage => profileImage.type === 'customer');
    //   const idImage = this.formFields.demographic.attachedProfileImages.find(profileImage => profileImage.type === 'demographic');
    //   return !!profileImage && !!idImage
    // },
  },
  watch: {
    customer: {
      immediate: true,
      handler(to) {
        if (to) {
          // this.assessment.farmGPS = (((to.demographic || {}).keyLocations || {}).work || {}).lookupParameters;
          // todo autofill land size based on customer object
          // this.landSize = to.landSize;
        } else {
          // this.assessment.farmGPS = undefined;
          this.assessment.landSize = undefined;
        }
      },
    },
    "formFields.interestedInSigninContract.answer": function (newValue) {
      if (newValue.toLowerCase() === 'yes') {
        this.formFields.interestedInSigninContract.extraObservationAnswer = null
      }
    }
  },
  methods: {
    saveFarmImage(uuid) {
      // todo delete or replace image

      const savedImageObj = {
        type: this.imageUploadTypeEnums.firstVisit,
        type_id: this.farm.uuid,
        uuid: uuid,
      }
      this.formFields.farmImage = savedImageObj
      this.$store.dispatch('addImage', savedImageObj);
    },

    saveProfileImage(uuid) {
      const savePayload = { type: 'customer',    type_id: this.customer.uuid, uuid: uuid }
      this.formFields.demographic.attachedProfileImages.push(savePayload)
      this.$store.dispatch('addImage', savePayload);
    },
    idImage(uuid) {
      const savePayload = { type: 'demographic',    type_id: this.customer.uuid, uuid: uuid }
      this.formFields.demographic.attachedProfileImages.push(savePayload)
      this.$store.dispatch('addImage', savePayload);
    },
    submit() {
      // todo check if edit or new
      let tempAssessment
      if (this.firstVisitToEdit) {
        tempAssessment = Object.assign({}, this.firstVisitToEdit)
      } else {
        tempAssessment = Object.assign({}, this.assessment)
      }

      tempAssessment['customerUuid'] = this.customerUuid
      tempAssessment['ratingFormFields'] = {
        ratings: this.ratingFormFields,
        ratingScore: totalPrimeraVisitaRatingsSummary(Object.values(this.ratingFormFields))
      }
      const extraFormField = {
        landSize: this.landSize,
        ...this.formFields
      }

      tempAssessment['formFields'] = extraFormField

      const customer = this.customer
      // todo not required
      delete customer.assessments

      customer.demographic.gender = this.formFields.demographic.gender.answer
      customer.demographic.birthdate = this.formFields.demographic.birthdate.answer
      customer.demographic.dependents = this.formFields.demographic.dependents.answer
      customer.demographic.schooling = this.formFields.demographic.schooling.answer

      this.$store.dispatch('upsertCustomer', customer);
      this.$store.dispatch('upsertAssessment', tempAssessment);

      // todo edit part



      // for (const imagePayload of this.formFields.demographic.attachedProfileImages) {
      //   this.$store.dispatch('addImage', imagePayload);
      // }
      // if (this.assessment.farmImage) {
      // this.$store.dispatch("addImage", this.assessment.farmImage);
      // }

      this.toAssessmentDetail(tempAssessment.assessmentUuid)
    },

    validate() {
      this.$refs.firstVisitRef.validate()
    },
    toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
    toAssessmentDetail(assessmentUuid) {
      this.$router.push({ name: 'ViewFirstVisit', query: { firstVisitUuid: assessmentUuid } });
    },
  },
  mixins: [ImageUploadTypeEnums]
};
</script>

<i18n>
{
  "en": {
    "registerForm":  {
      "title": "Register Section",
      "form": {
        "visitDate": "Visit Date",
        "fieldPhoto": "Terrain Photo",
        "fieldGps": "Field Gps",
        "areaOfFarm": "Area of Farm"
      }
    }
  },
  "es": {
    "registerForm":  {
      "title": "Sección de registro",
      "form": {
        "visitDate": "Fecha de la visita",
        "fieldPhoto": "Foto Terreno",
        "fieldGps": "campo Gps",
        "areaOfFarm": "Area De Granja"
      }
    }
  },
  "sw": {
    "registerForm":  {
      "title": "Sehemu ya Usajili",
      "form": {
        "visitDate": "Tarehe ya kutembelea",
        "fieldPhoto": "Picha ya Terrain",
        "fieldGps": "Gps ya Shamba",
        "areaOfFarm": "Eneo la Shamba"
      }
    }
  }
}
</i18n>
