<template>
  
  <v-container fluid>

    <v-row>
      <v-col cols="12" class="text-h3 text--primary text-left">
        <span v-if="product">{{customer.name}}: {{product}}</span>
        <span v-else>{{customer.name}}</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-h5 text-left">
        <ServerSideResult :test="products">
          <Instrument v-if="balances" :instrumentType="instrumentType" highlight="Balance" @instrument="setInstrument" />
          <div v-else>
            {{customer.name}} has no open loans.
            <v-btn color="primary" :to="{ query: { uuid: uuid }, name: 'UltimateTzLoanRequest' }">
              <v-icon left small>mdi-cash-multiple</v-icon>
              Request A Loan
            </v-btn>
           </div>
        </ServerSideResult>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                  <Row title="Name"      :text="customer.name" bold="true" v-if="customer.name" />
                  <Row title="Telephone" :msisdn="customer.msisdn" />
                  <Row title="Identity"  :text="customer.demographic.identity.number"  />
                  <Row title="" >
                    <v-btn color="primary" dense small :to="{ query: { uuid: uuid }, name: 'CreateContact' }">
                      <v-icon small left>mdi-account-edit</v-icon>
                      Edit
                    </v-btn>
                  </Row>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
      
      <v-col>
        <div no-gutters v-if="images.length>0">
          <Images :images="images" />
        </div>
      </v-col>

    </v-row>

    <v-row v-if="instrument">
      <v-col cols="8">
        <CustomerFacingTransactions :instrument="instrument" />
      </v-col>
      <v-col cols="4">
        <History />
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import lookups from '@/mixins/lookups';
import instrumentNames from '@/mixins/instrumentNames';

import Row from "@/components/Row";
import Time from "@/components/Time";
import Currency from "@/components/Currency";
import ServerSideResult from "@/components/ServerSideResult";
import Instrument from "@/views/instruments/Instrument"
import CustomerFacingTransactions from "@/views/instruments/CustomerFacingTransactions"
import History from "@/views/instruments/History"


export default {

  components: {  Row, Time, Currency, ServerSideResult, Instrument, CustomerFacingTransactions, History },

  data() {
    return {
      products: undefined,
      instrument: undefined,
    }
  },

  computed: {
    uuid() { return this.$route.query.uuid; },
    customer() { return this.$store.getters.customer(this.uuid); },
    images() {
      let i = this.$store.getters.images.filter( e => ( e.type_id===this.customer.uuid ) && (e.type==='customer' || e.type==='demographic') );
      if (this.customer.demographic && this.customer.demographic.imageUrls) { i = i.concat(this.customer.demographic.imageUrls); }
      if (this.customer.demographic && this.customer.demographic.idScanUrls) { i = i.concat(this.customer.demographic.idScanUrls); }
      if (this.customer.imageUrls) { i = i.concat(this.customer.imageUrls) }
      return i;
    },
    balances() { return (this.products ? this.products[0] : {}) },
    instrumentType() { return (this.balances ? this.balances.type : undefined); },
    product() { return this.instrumentTypeToName(this.instrumentType); },
    installments() { return (this.balances ? this.balances.duration : undefined); },
  },

  watch: {

  },

  methods: {
    setInstrument(to) { this.instrument = to; },
  },

  mounted() {
    this.remoteLookup('ultimate_tz_customer_balances', { uuid: this.uuid }).then( balances => this.products = balances.balances ).catch(e => this.products = null);
  },

  mixins: [lookups, instrumentNames],

}
</script>
