<template>
  <v-container fluid class="pa-0" >
    <div v-if="customersInitialised && isOnline">

      {{$t('checkAssessment')}}
      <v-btn
          data-type="save-button"
          color="warning"
          small
          dense
          class="ma-1"
          @click="startSyncCycle"
      >
        {{$t('check sync status')}}

      </v-btn>

      <div>
        {{$t('Local customers')}}: {{Object.keys(customersUuidAndUpdatedAt).length }}
      </div>

      <div>
        {{$t('Online Only Assessments')}}: {{onlineOnlyAssessmentUuids.length}}
      </div>


      <div>
        {{$t('onlineAssessments')}}: {{allBackendAssessmentUuids ? allBackendAssessmentUuids.length : ''}}
      </div>


      <div>
        {{$t('localNumberAssessments')}}: {{localUniqueAssessmentUuids.length}}
      </div>

      <div>
        {{$t('number of assessments not locally')}}:

        <template v-if="allBackendAssessmentUuids">

          {{allBackendAssessmentUuids.length - localUniqueAssessmentUuids.length}}
        </template>
      </div>

      <div>
        {{$t('unsyncedAssessments')}}: {{unsyncedAssessments.length}}
      </div>

      <v-btn
          data-type="save-button"
          :disabled="unsyncedAssessments.length === 0"
          color="warning"
          small
          dense
          class="ma-1"
          @click="startSyncOffline"
      >
        {{$t('startSyncing')}}
      </v-btn>

      <v-btn
          data-type="save-button"
          :disabled="onlineOnlyAssessmentUuids.length === 0"
          color="warning"
          small
          dense
          class="ma-1"
          @click="downloadLatestAssessments(onlineOnlyAssessmentUuids)"
      >
        {{$t('fetch latest assessment data')}}
      </v-btn>
    </div>

  <!--    downloadLatestAssessments(this.onlineOnlyAssessmentUuids)-->
    <v-row align="center" justify="center" v-if="!customersInitialised">
      <v-col cols=3 />
      <v-col cols=6 align="center" justify="center" >
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col cols=3 />
    </v-row>

    <v-row align="center" justify="center" v-if="!isOnline">
      <v-col cols=3 />
      <v-col cols=6 align="center" justify="center" >
        <p>
          {{$t('Currently Offline')}}
        </p>

      </v-col>
      <v-col cols=3 />
    </v-row>


<!--    isOnline-->
  </v-container>
</template>


<script>

import lookups from "@/mixins/lookups";

export default {

  data() {
    return {
      unsyncedAssessments: [],
      unsyncedAssessmentUuids: [],
      allBackendAssessmentUuids: [],
      localAssessmentUuids: [],
      localUniqueAssessmentUuids: [],
      onlineOnlyAssessmentUuids: [],
    }
  },
  computed: {
    isOnline() { return this.$store.getters.networkOnline; },
    customersInitialised() { return this.$store.getters.customersInitialised; },
    customersUuidAndUpdatedAt() {
      return this.$store.getters.customersUuidAndUpdatedAt
    },
    activeAssessmentUuid() {
      return this.$store.getters.activeAssessmentUuid
    },
    assessments() {
      return this.$store.getters.assessments
    },
  },

  methods: {
    startSyncCycle() {
      this.$store.dispatch('checkOnlineDeletedCustomers').then(response => {
        this.calculateAssessmentUuids()
        this.fetchCustomerAssessments()
      })
    },
    startSyncOffline() {
      this.$store.dispatch('syncLocalAssessments', this.unsyncedAssessments).then(results => {
        // this.downloadLatestAssessments(this.onlineOnlyAssessmentUuids)
        this.startSyncCycle()
      })
    },
    downloadLatestAssessments(newAssessmentUuids) {
      this.$store.dispatch('downloadLatestAssessments', newAssessmentUuids).then(results => {
        this.startSyncCycle()
      })
    },
    findUnSynchedData(allBackendAssessmentUuids) {

      this.unsyncedAssessments = []
      this.unsyncedAssessmentUuids = []
      this.onlineOnlyAssessmentUuids = []

      for (const t of this.assessments) {
        if (!this.allBackendAssessmentUuids.includes(t.assessmentUuid)) {
          this.unsyncedAssessments.push(t)
          this.unsyncedAssessmentUuids.push(t.assessmentUuid)
        }
      }


      for (const t of this.allBackendAssessmentUuids) {
        if (!this.activeAssessmentUuid.includes(t)) {
          this.onlineOnlyAssessmentUuids.push(t)
        }
      }

    },
    fetchCustomerAssessments() {
      this.remoteLookup('retrieveAssessmentUuids', { uuids: Object.keys(this.customersUuidAndUpdatedAt) }).then( assessmentUuids => {
        // todo compare server assessmentUuids with local assessmentUuids to get offline only assessments and upsert them
        this.allBackendAssessmentUuids = assessmentUuids
        this.findUnSynchedData(assessmentUuids)
      }).catch(e => {
        console.log('customersUpdated.error', e)
      });

    },
    calculateAssessmentUuids() {
      const local = this.assessments.filter(assessment => {
        return Object.keys(this.customersUuidAndUpdatedAt).includes(assessment.customerUuid)
      })
      this.localAssessmentUuids = local
      const uniqueLocal = []
      const tempLocalUuids = []

      local.forEach(assessment => {
        if (!tempLocalUuids.includes(assessment.assessmentUuid)) {
          uniqueLocal.push(assessment)
          tempLocalUuids.push(assessment.assessmentUuid)
        }
      });
      this.localUniqueAssessmentUuids = uniqueLocal
    },
    checkOnlineDeletedCustomers() {
      this.$store.dispatch('checkOnlineDeletedCustomers')
    }
  },
  mixins: [lookups],
}

</script>

<style scoped>

</style>

<i18n>
{
  "en": {
    "checkAssessment": "Check Assessment",
    "onlineAssessments": "Online Assessments",
    "localNumberAssessments": "Local Number of Assessments",
    "unsyncedAssessments": "Unsynced Assessments",
    "startSyncing": "Start Syncing",
    "check sync status": "check sync status",
    "Local customers": "Local Customers",
    "Online Only Assessments": "Online Only Assessments",
    "Currently Offline": "Currently Offline"
  },
  "es": {
    "checkAssessment": "Comprobar evaluación",
    "onlineAssessments": "Evaluaciones en línea",
    "localNumberAssessments": "Número local de evaluaciones",
    "unsyncedAssessments": "Evaluaciones no sincronizadas",
    "startSyncing": "Empezar a sincronizar",
    "check sync status": "Comprobar el estado de sincronización",
    "Local customers": "Clientes locales",
    "Online Only Assessments": "Evaluaciones solo en línea",
    "Currently Offline": "Actualmente fuera de linea"
},
  "sw": {
    "checkAssessment": "Check Assessment",
    "onlineAssessments": "Online Assessments",
    "localNumberAssessments": "Local Number of Assessments",
    "unsyncedAssessments": "Unsynced Assessments",
    "startSyncing": "Start Syncing",
    "check sync status": "Empezar a sincronizar",
    "Local customers": "Empezar a sincronizar",
    "Online Only Assessments": "Empezar a sincronizar",
    "Currently Offline": "Currently Offline"
  }
}
</i18n>


